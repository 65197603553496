/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './components/Header';
import FeaturedPost from './components/FeaturePost';
import { postService } from 'services';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { Link, useParams } from 'react-router-dom';
import { publicRoute } from 'routes';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DEFAULT_ROWS_PERPAGE = 3;
const DEFAULT_POSTS_PER_TOPIC = 6;

const defaultTheme = createTheme();

interface ExpandedTopics {
  [key: number]: boolean;
}

interface Post {
  postContent?: string;
  createdAt?: ISODateString;
  shortDescription?: string;
  avatarUrl?: string;
  title?: string;
  author?: string;
  sharingTypeId?: number;
  topicId?: number;
  totalView?: number;
}

interface TopicPosts {
  list: Post[];
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

interface ListData {
  [key: string]: TopicPosts;
}

export default function Blog() {
  const { dataSearch } = useSearch();

  const [expandedTopics, setExpandedTopics] = useState<ExpandedTopics>({});
  const [listData, setListData] = useState<ListData>({});
  const { id } = useParams();
  useEffect(() => {}, [dataSearch]);
  const { data: dataTopic } = useQuery(['postService.fetchTopics'], () => postService.fetchTopics(dataSearch), {
    keepPreviousData: true,
  });

  const sharingTypeDataRequest = useQuery(
    ['postService.fetchSharingTypes', dataSearch],
    () => postService.fetchSharingTypes(dataSearch),
    { keepPreviousData: true },
  );

  const dataSharingType = sharingTypeDataRequest.data;

  useEffect(() => {
    if (dataTopic && dataTopic.list.length) fetchAllData();
  }, [dataTopic, id, dataSharingType]);

  const filterSharingType = () => {
    if (!dataSharingType || dataSharingType.list.length === 0) {
      return null;
    }
    if (id) {
      const filteredSharingTypes = dataSharingType?.list?.filter((sharingType) => sharingType.id === parseInt(id));
      return filteredSharingTypes.length ? parseInt(id) : null;
    } else {
      return null;
    }
  };

  const fetchAllData = async () => {
    if (!dataTopic || !dataTopic.list.length) return;
    const sharingTypeId = filterSharingType();
    const listPromise = dataTopic.list.map((topic) =>
      postService.fetchPostsPublic({ topicId: topic.id, sharingTypeId: sharingTypeId, size: DEFAULT_POSTS_PER_TOPIC }),
    );
    const response = await Promise.all(listPromise);
    const resData: any = {};
    for (const [index, postData] of response.entries()) {
      const topicId = dataTopic.list[index].id.toString();
      resData[topicId] = {
        list: postData.list,
        total: postData.total,
        rowsPerPage: DEFAULT_ROWS_PERPAGE,
        currrentPage: 0,
      };
    }
    setListData(resData);
  };

  const navigate = useNavigate();
  const loadMorePosts = (topicId: any) => {
    navigate(publicRoute.PostPublicListByTopic.url({ id: topicId }));
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth='lg'>
        <Header title='Danh sách bài viết' sections={[]} />
        <Grid item xs={12} md={9}>
          <main>
            {dataTopic &&
              dataTopic.list &&
              dataTopic.list.map((topic) => {
                const hasPosts =
                  listData && listData[topic.id] && listData[topic.id].list && listData[topic.id].list.length > 0;
                if (hasPosts) {
                  return (
                    <React.Fragment key={topic.id + 'topic'}>
                      <React.Fragment>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '2rem',
                            marginBottom: '1rem',
                            backgroundColor: '#f0f0f0',
                            padding: '10px',
                            borderRadius: '5px',
                          }}
                        >
                          <Typography
                            variant='h5'
                            component='h3'
                            style={{
                              fontWeight: 'bold',
                              color: 'black',
                            }}
                          >
                            {topic.title}
                          </Typography>
                          {hasPosts && !expandedTopics[topic.id] && (
                            <Button onClick={() => loadMorePosts(topic.id)}>Tất cả bài viết</Button>
                          )}
                        </div>

                        <Grid container spacing={5}>
                          {listData &&
                            listData[topic.id] &&
                            listData[topic.id].list.map((post: any) => (
                              <Grid item xs={12} sm={6} md={4} key={'post' + topic.id + '_' + post.id}>
                                {post.isPrivate ? (
                                  <FeaturedPost post={post} />
                                ) : (
                                  <Link
                                    to={publicRoute.postPublicView.url(post)}
                                    style={{ width: '100%' }}
                                    key={post.id + 'link'}
                                  >
                                    <FeaturedPost post={post} />
                                  </Link>
                                )}
                              </Grid>
                            ))}
                        </Grid>
                      </React.Fragment>
                    </React.Fragment>
                  );
                }
                return null;
              })}
          </main>
        </Grid>
      </Container>
      {/* <Footer title='Footer' description='Something here to give the footer a purpose!' /> */}
    </ThemeProvider>
  );
}
