import { client } from './axios';

const getBank = (): Promise<CatBank> => client.get(`/api/v1/categories/bank`);
const getAllJobs = (): Promise<PaginateResponse<CatJob>> => client.get(`/api/v1/categories/jobs`);
const getZaloGlobalGroupUrl = (): Promise<ZaloUrl> => client.get(`/api/v1/categories/global-zalo`);
const getAllEvaluationLevels = (): Promise<PaginateResponse<CatEvaluationLevel>> =>
  client.get(`/api/v1/categories/evaluation-levels`);
const getAllStudyDomains = (): Promise<PaginateResponse<CatStudyDomain>> =>
  client.get(`/api/v1/categories/study-domains`);
const getAllStudyProductions = (): Promise<PaginateResponse<CatStudyProduction>> =>
  client.get(`/api/v1/categories/study-productions`);
const sendEmails = (body: SendEmailsBody): Promise<SuccessResponse> => client.post(`/api/v1/emails`, body);
const getAllTestObjects = (): Promise<PaginateResponse<TestObject>> => client.get(`/api/v1/categories/test-objects`);
const getAllQuestionLevels = (): Promise<PaginateResponse<TestQuestionLevel>> =>
  client.get(`/api/v1/categories/test-question-levels`);
const getAllTestQuestionTopics = ({
  parentId,
}: {
  parentId?: FormType<number>;
}): Promise<PaginateResponse<TestQuestionTopic>> =>
  client.get(`/api/v1/categories/test-question-topics?parentId=${parentId}`);
const getAllTestAssessmentGroupTypes = (): Promise<PaginateResponse<TestAssessmentGroupType>> =>
  client.get(`/api/v1/categories/test-assessment-group-types`);
const getAllTestAssessmentTypes = ({
  groupId,
}: {
  groupId?: FormType<number>;
}): Promise<PaginateResponse<TestAssessmentType>> =>
  client.get(`/api/v1/categories/test-assessment-types?groupId=${groupId}`);

const categoryService = {
  getBank,
  getAllJobs,
  getZaloGlobalGroupUrl,
  getAllEvaluationLevels,

  getAllStudyDomains,
  getAllStudyProductions,

  sendEmails,

  getAllTestObjects,
  getAllQuestionLevels,
  getAllTestQuestionTopics,

  getAllTestAssessmentGroupTypes,
  getAllTestAssessmentTypes,
};

export default categoryService;
