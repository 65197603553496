import { Box, Chip, Container, Divider, FormControlLabel, Grid, RadioGroup, Tooltip, Typography } from '@mui/material';
import { Check, CheckCircle, Close, RadioButtonUnchecked } from '@mui/icons-material';
import { TestQuestionLevelTypes, TestQuestionTypeTypes, formatNumber } from 'utils/common';
import ViewEditorContent from './ViewEditorContent';

type Props = {
  question: TestQuestion;
};

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

const ContentQuestionView = ({ question }: Props) => {
  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <div>
              <span>Câu hỏi nhóm: </span>
              <FormControlLabel
                className='ml-0'
                key={1}
                label=''
                control={
                  (question.groupId ?? 0) > 0 ? (
                    <Tooltip title='Câu hỏi trong nhóm'>
                      <Chip label={<Check />} color='success' variant='filled' />
                    </Tooltip>
                  ) : (
                    <Tooltip title='Câu hỏi đơn lẻ'>
                      <Chip label={<Close />} />
                    </Tooltip>
                  )
                }
              />
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Chủ đề cha' value={question.parentTopic?.name} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Chủ đề con' value={question.topic?.name} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo
              label='Loại câu hỏi'
              value={TestQuestionTypeTypes.find((item) => item.value === question.questionType)?.label}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Điểm' value={formatNumber(question.point)} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo
              label='Mức độ'
              value={TestQuestionLevelTypes.find((item) => item.value === question.level)?.label}
            />
          </Grid>
        </Grid>

        {question.group && (
          <>
            <Divider light className='mt-6' />
            <Grid container spacing={3} className='mt-1'>
              <Grid item xs={12}>
                <Typography variant='h5'>Ngữ cảnh:</Typography>
                <ViewEditorContent data={question.group.name} />
              </Grid>
            </Grid>
            <Divider light className='mb-2 mt-2' />
          </>
        )}

        <Grid container spacing={3} className='mt-1'>
          <Grid item xs={12}>
            <Typography variant='h5'>Nội dung</Typography>
          </Grid>
          <Grid item xs={12}>
            <ViewEditorContent data={question.content} />
          </Grid>
        </Grid>

        {question.questionType === 'CHOICE' && (
          <Grid container spacing={3} className='mb-2 mt-1'>
            <Grid item xs={12}>
              <Typography variant='h5'>Đáp án</Typography>
            </Grid>
          </Grid>
        )}

        {question.questionType === 'CHOICE' && !question.isMultipleChoice && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RadioGroup>
                {question.answers.map((answer, index) => (
                  <Box border={0.5} borderColor='rgba(0, 0, 0, 0.1)' borderRadius={1} className='mt-2 p-2' key={index}>
                    <FormControlLabel
                      className='space-x-2 px-2 py-1'
                      label={<ViewEditorContent data={answer.content} />}
                      control={
                        answer.isCorrect === 1 ? (
                          <CheckCircle color='success' />
                        ) : (
                          <RadioButtonUnchecked color='inherit' />
                        )
                      }
                    />
                  </Box>
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
        )}
        {question.questionType === 'ESSAY' && question.essayAnswerType && question.essayAnswer && (
          <>
            <Divider light className='mt-2' />
            <Grid container spacing={3} className='mt-1'>
              <Grid item xs={12}>
                <Typography variant='h5'>Đáp án ({question.essayAnswerType})</Typography>
              </Grid>
              <Grid item xs={12} className='space-x-1'>
                <ViewEditorContent data={question.essayAnswer} />
              </Grid>
            </Grid>
          </>
        )}
        {question.helpContent && (
          <>
            <Divider light className='mt-2' />
            <Grid container spacing={3} className='mt-1'>
              <Grid item xs={12}>
                <Typography variant='h5'>Giải thích</Typography>
              </Grid>
              <Grid item xs={12}>
                <ViewEditorContent data={question.helpContent} />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};

export default ContentQuestionView;
