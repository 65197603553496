import { Edit, Email, BakeryDiningOutlined, InfoOutlined, Pause, PlayArrow, Stop } from '@mui/icons-material';
import { Button, Chip, Container, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { adminRoute } from 'routes';
import { classroomService } from 'services';
import { ClassroomStatusTypes, formatDate } from 'utils/common';
import {
  ClassroomSearch,
  PopupClassroomFinish,
  PopupClassroomStart,
  PopupClassroomStop,
  PopupClassroomNotify,
  PopupClassroomUpsert,
  PopupClassroomHomework,
} from './components';

const ClassroomList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    ['classroomService.fetchClassrooms', dataSearch],
    () => classroomService.fetchClassrooms(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const [openUpdateStart, setOpenUpdateStart] = useState(false);
  const [openUpdateStop, setOpenUpdateStop] = useState(false);
  const [openUpdateFinish, setOpenUpdateFinish] = useState(false);
  const [openNoti, setOpenNoti] = useState(false);
  const [openAddClassroom, setOpenAddClassroom] = useState(false);
  const [openUpdateClassroom, setOpenUpdateClassroom] = useState(false);
  const [openClassroomHomework, setOpenClassroomHomework] = useState(false);
  const [itemChoice, setItemChoice] = useState<Classroom>();

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách lớp học
      </Typography>

      <ClassroomSearch onChange={onSearchChange} />
      <div className='mt-6 flex justify-end space-x-3'>
        <Button color='success' onClick={() => setOpenAddClassroom(true)}>
          Thêm mới
        </Button>
      </div>
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 64}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'course.name',
            headerName: 'Khóa học',
            flex: 1,
            minWidth: 230,
            sortable: false,
            renderCell: ({ row }) => <div>{row.course?.name}</div>,
          },
          {
            field: 'code',
            headerName: 'Lớp học',
            flex: 1,
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => <div className='font-bold'>{row.code}</div>,
          },
          {
            field: 'startDate',
            headerName: 'Khai giảng',
            minWidth: 120,
            sortable: false,
            renderCell: ({ row }) => <div>{formatDate(row.startDate)}</div>,
          },
          {
            field: 'course.method',
            headerName: 'Hình thức',
            minWidth: 120,
            sortable: false,
            renderCell: ({ row }) => (
              <Chip
                label={row.course?.method}
                color={row.course?.method && row.course?.method.toUpperCase() === 'ONLINE' ? 'primary' : 'secondary'}
              />
            ),
          },
          {
            field: 'totalRegistered',
            headerName: 'Số lượng',
            minWidth: 120,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.totalRegistered}/{row.totalSlot}
              </div>
            ),
          },
          {
            field: 'status',
            headerName: 'Trạng thái',
            minWidth: 160,
            sortable: false,
            renderCell: ({ row }) => <div>{ClassroomStatusTypes.find((item) => item.value === row.status)?.label}</div>,
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 300,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Link to={adminRoute.classroomView.url(row)}>
                  <Button size='small' color='info' variant='outlined'>
                    <InfoOutlined />
                  </Button>
                </Link>
                <Tooltip title='Cập nhật thông tin lớp học'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpenUpdateClassroom(true);
                      setItemChoice(row);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
                {row.status === 'ACTIVE' && (
                  <Tooltip title='Bắt đầu lớp học'>
                    <Button
                      size='small'
                      color='success'
                      onClick={() => {
                        setOpenUpdateStart(true);
                        setItemChoice(row);
                      }}
                    >
                      <PlayArrow />
                    </Button>
                  </Tooltip>
                )}
                {row.status === 'ACTIVE' && (
                  <Tooltip title='Đóng lớp học'>
                    <Button
                      size='small'
                      color='error'
                      onClick={() => {
                        setOpenUpdateStop(true);
                        setItemChoice(row);
                      }}
                    >
                      <Pause />
                    </Button>
                  </Tooltip>
                )}
                {row.status === 'LEARNING' && (
                  <Tooltip title='Kết thúc lớp học'>
                    <Button
                      size='small'
                      color='error'
                      onClick={() => {
                        setOpenUpdateFinish(true);
                        setItemChoice(row);
                      }}
                    >
                      <Stop />
                    </Button>
                  </Tooltip>
                )}
                {row.allowEmailNotify && (
                  <Tooltip title='Gửi email thông báo lớp học'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenNoti(true);
                        setItemChoice(row);
                      }}
                    >
                      <Email />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title='Giao bài tập'>
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      setOpenClassroomHomework(true);
                      setItemChoice(row);
                    }}
                  >
                    <BakeryDiningOutlined />
                  </Button>
                </Tooltip>
              </div>
            ),
          },
        ]}
      />

      <Dialog open={openAddClassroom} maxWidth='md'>
        <PopupClassroomUpsert onClose={() => setOpenAddClassroom(false)} />
      </Dialog>
      <Dialog open={openUpdateClassroom} maxWidth='md'>
        <PopupClassroomUpsert classroom={itemChoice!} onClose={() => setOpenUpdateClassroom(false)} />
      </Dialog>
      <Dialog open={openUpdateStart}>
        <PopupClassroomStart item={itemChoice!} onClose={() => setOpenUpdateStart(false)} />
      </Dialog>
      <Dialog open={openUpdateStop}>
        <PopupClassroomStop item={itemChoice!} onClose={() => setOpenUpdateStop(false)} />
      </Dialog>
      <Dialog open={openUpdateFinish}>
        <PopupClassroomFinish item={itemChoice!} onClose={() => setOpenUpdateFinish(false)} />
      </Dialog>
      <Dialog maxWidth='xl' open={openClassroomHomework}>
        <PopupClassroomHomework classroom={itemChoice!} onClose={() => setOpenClassroomHomework(false)} />
      </Dialog>
      {itemChoice && (
        <Dialog maxWidth='sm' open={openNoti}>
          <PopupClassroomNotify classroomId={itemChoice!.id} onClose={() => setOpenNoti(false)} />
        </Dialog>
      )}
    </Container>
  );
};

export default ClassroomList;
