import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';

type Props = PopupController & {
  emails: string;
};

const PopupRegisterEmail = ({ emails, onClose }: Props) => {
  const [copied, setCopied] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(emails).then(() => {
      setCopied(true);
    });
  };

  return (
    <>
      <DialogTitle>Danh sách email</DialogTitle>

      <DialogContent>
        <TextField multiline InputProps={{ readOnly: true }} value={emails} fullWidth />
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' onClick={copy} startIcon={copied ? <Check /> : null}>
          Copy
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupRegisterEmail;
