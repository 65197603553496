export const regexValidateVietnameseName = () => {
  return /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\w]+$/u;
};

export const regexValidatePhoneNumber = () => {
  return /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
};

export const regexValidateEmail = () => {
  return /^[a-zA-Z0-9_.-]{1,}@gmail.com$/;
};

export const regexValidatePassword = () => {
  return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
};
