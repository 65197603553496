import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { authService, categoryService, queryClient } from 'services';
import { regexValidateVietnameseName, regexValidatePhoneNumber, regexValidateEmail } from 'utils/validator';

type Props = PopupController & {
  user: User;
};

const PopupUserUpdate = ({ user, onClose }: Props) => {
  const { control, handleSubmit } = useForm<UpdateProfileBody>();

  const { data: pageJobs } = useQuery(['categoryService.getAllJobs'], () => categoryService.getAllJobs(), {
    keepPreviousData: true,
  });
  const { list: jobs = [] } = pageJobs ?? {};

  const { mutate: mutateProfile, isLoading } = useMutation(authService.updateUser, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật thông tin học viên thành công');
      queryClient.invalidateQueries(['authService.fetchUsers']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      mutateProfile({ ...values, id: user.id });
    })();
  };

  return (
    <>
      <DialogTitle>Cập nhật thông tin học viên</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='fullName'
              defaultValue={user.fullName}
              control={control}
              rules={{
                required: 'Tên là bắt buộc',
                pattern: {
                  value: regexValidateVietnameseName(),
                  message: 'Vui lòng nhập họ tên từ 3 ký tự trở lên và không được chứa ký tự đặc biệt',
                },
                minLength: {
                  value: 3,
                  message: 'Vui lòng nhập họ tên từ 3 ký tự trở lên và không được chứa ký tự đặc biệt',
                },
                maxLength: {
                  value: 255,
                  message: 'Vui lòng nhập họ tên từ 3 ký tự trở lên và không được chứa ký tự đặc biệt',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth required label='Tên' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='email'
              defaultValue={user.email}
              control={control}
              rules={{
                required: 'Email là bắt buộc',
                pattern: {
                  value: regexValidateEmail(),
                  message: 'Email không hợp lệ',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth required label='Email' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='phone'
              defaultValue={user.phone}
              control={control}
              rules={{
                required: 'Điện thoại là bắt buộc',
                pattern: {
                  value: regexValidatePhoneNumber(),
                  message: 'Số điện thoại không đúng định dạng',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Điện thoại'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='catJobId'
              defaultValue={user.catJobId}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Nghề nghiệp' error={!!error} helperText={error?.message}>
                  {jobs.map((item: any, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupUserUpdate;
