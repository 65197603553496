import { Container, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { categoryService } from 'services';
import { QRCode } from 'components';

const GlobalQr = () => {
  const { data } = useQuery(['categoryService.getZaloGlobalGroupUrl'], () => categoryService.getZaloGlobalGroupUrl(), {
    keepPreviousData: true,
  });

  return (
    <Container maxWidth='md' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        QR Join nhóm học viên
      </Typography>
      <div className='flex flex-col items-center'>
        {data?.url && data?.allow && <QRCode value={data!.url} className='h-[240px] w-[240px]' />}
        {!data?.url && data?.allow && (
          <Typography variant='h4' className='hover:text-primary-main'>
            Chưa có thông tin
          </Typography>
        )}
        {!data?.allow && (
          <Typography variant='h4' className='hover:text-primary-main'>
            Vui lòng đăng ký khóa học để join nhóm Zalo
          </Typography>
        )}
      </div>
    </Container>
  );
};

export default GlobalQr;
