import { Button, Chip, Container, Dialog, Grid, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { registerService } from 'services';
import { ClassroomStatusTypes, RegisterStatusTypes, formatDate, formatDateTime, formatNumber } from 'utils/common';
import { PopupRegisterReserve } from './components';
import DocList from 'views/Classroom/components/DocList';
import { QRCode } from 'components';
import PopupSurvey from './components/PopupSurvey';
import { Check, Close } from '@mui/icons-material';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      {label && <span>{label}: </span>}
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

const RegisterView = () => {
  const { id } = useParams();
  const { dataSearch, onPaginationChange } = useSearch();
  const [openReseve, setOpenReseve] = useState(false);
  const [openSurvey, setOpenSurvey] = useState(false);

  const { data: register } = useQuery(['registerService.getRegisterInfo', id], () =>
    registerService.getMyRegisterInfo({ id: +id! }),
  );
  const { data: reseveData, isLoading: isFetching } = useQuery(['registerService.fetchMyReserves', dataSearch], () =>
    registerService.fetchMyReserves({ ...dataSearch, registerId: +id! }),
  );
  const { list: items = [], total = 0 } = reseveData ?? {};

  const rowsWithIndex = items.map((row, index) => ({ ...row, index: index + 1 }));

  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Typography variant='h4' color='primary' className='mb-6 space-x-3'>
          <span>Chi tiết khóa học đăng ký</span>
          <Chip label={RegisterStatusTypes.find((item) => item.value === register?.status)?.label} color='info' />
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <GridInfo label='Khóa học' value={register?.course?.name} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Hình thức học' value={register?.course?.method} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Học phí' value={formatNumber(register?.fee)} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Lớp học đăng ký' value={register?.classroom?.name} />
          </Grid>
          {register?.course?.method.toUpperCase() !== 'VIDEO' && (
            <Grid item md={4} xs={12}>
              <GridInfo
                label='Khai giảng'
                value={register?.classroom?.startDate ? formatDate(register?.classroom?.startDate) : ''}
              />
            </Grid>
          )}
          <Grid item md={register?.course?.method.toUpperCase() !== 'VIDEO' ? 4 : 8} xs={12}>
            <GridInfo
              label='Lịch học/Thời lượng'
              value={register?.classroom?.schedule + ' - ' + register?.classroom?.studyTime}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo
              label='Ngày xác nhận thanh toán/Hủy'
              value={register?.approvedAt ? formatDateTime(register?.approvedAt) : ''}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo
              label='Người xác nhận thanh toán/Hủy'
              value={register?.approveBy === -1 ? 'Hệ thống' : register?.approveUser?.fullName}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            {register?.status === 'CANCEL' && <GridInfo label='Lý do hủy' value={register?.approveNote} />}
          </Grid>
          {!['WAIT_PAYMENT', 'CANCEL'].includes(register?.status ?? 'CANCEL') && (
            <>
              <Grid item md={3} xs={12}>
                <GridInfo label='Link Zalo nhận thông báo' />
              </Grid>
              <Grid item md={3} xs={12}>
                {register?.classroom?.zaloLink && (
                  <QRCode value={register?.classroom?.zaloLink!} className='h-[160px] w-[160px]' />
                )}
                {register?.zaloAdded === 1 && register?.classroom && (
                  <div className='mt-2'>
                    <Link
                      to={register?.classroom?.zaloLink}
                      target='_blank'
                      className='text-primary-main hover:text-primary-dark'
                    >
                      {register?.classroom?.zaloLink}
                    </Link>
                  </div>
                )}
              </Grid>
              <Grid item md={3} xs={12}>
                <GridInfo label='Link Zalo buôn chuyện' />
              </Grid>
              <Grid item md={3} xs={12}>
                {register?.classroom?.zaloLinkFreeChat && (
                  <QRCode value={register?.classroom?.zaloLinkFreeChat!} className='h-[160px] w-[160px]' />
                )}
                {register?.zaloAdded === 1 && register?.classroom && (
                  <div className='mt-2'>
                    <Link
                      to={register?.classroom?.zaloLinkFreeChat}
                      target='_blank'
                      className='text-primary-main hover:text-primary-dark'
                    >
                      {register?.classroom?.zaloLinkFreeChat}
                    </Link>
                  </div>
                )}
              </Grid>
              <Grid item md={4} xs={12} className='space-x-3'>
                <span>Đã join Zalo</span>
                {register?.zaloAdded === 1 ? (
                  <Tooltip title='Đã Join Zalo'>
                    <Chip label={<Check />} color='success' variant='filled' />
                  </Tooltip>
                ) : (
                  <Tooltip title='Chưa Join Zalo'>
                    <Chip label={<Close />} />
                  </Tooltip>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      <Container maxWidth='xl' className='py-6'>
        <Typography variant='h4' color='primary' className='mb-6 space-x-3'>
          <span>Lớp học hiện tại</span>
          {register?.currentClassroom === null && <Chip label='Chưa có' color='error' />}
          {register?.currentClassroom !== null && (
            <>
              <Chip
                label={ClassroomStatusTypes.find((item) => item.value === register?.currentClassroom?.status)?.label}
                color='info'
              />
              {register?.allowSurvey && (
                <Tooltip title='Đánh giá khóa học'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpenSurvey(true);
                    }}
                  >
                    Đánh giá khóa học
                  </Button>
                </Tooltip>
              )}
            </>
          )}
        </Typography>
        {register?.currentClassroom !== null && (
          <>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <GridInfo label='Lớp học đăng ký' value={register?.currentClassroom?.name} />
              </Grid>
              <Grid item md={4} xs={12}>
                <GridInfo
                  label='Khai giảng'
                  value={register?.currentClassroom?.startDate ? formatDate(register?.currentClassroom?.startDate) : ''}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <GridInfo
                  label='Lịch học/Thời lượng'
                  value={register?.currentClassroom?.schedule + ' - ' + register?.currentClassroom?.studyTime}
                />
              </Grid>
              <>
                <Grid item md={3} xs={12}>
                  <GridInfo label='Link Zalo nhận thông báo' />
                </Grid>
                <Grid item md={3} xs={12}>
                  {register?.currentClassroom?.zaloLink && (
                    <QRCode value={register?.currentClassroom?.zaloLink!} className='h-[160px] w-[160px]' />
                  )}
                  {register?.student?.zaloAdded === 1 && (
                    <div className='mt-2'>
                      <Link
                        to={register?.currentClassroom?.zaloLink}
                        target='_blank'
                        className='text-primary-main hover:text-primary-dark'
                      >
                        {register?.currentClassroom?.zaloLink}
                      </Link>
                    </div>
                  )}
                </Grid>
                <Grid item md={3} xs={12}>
                  <GridInfo label='Link Zalo buôn chuyện' />
                </Grid>
                <Grid item md={3} xs={12}>
                  {register?.currentClassroom?.zaloLinkFreeChat && (
                    <QRCode value={register?.currentClassroom?.zaloLinkFreeChat!} className='h-[160px] w-[160px]' />
                  )}
                  {register?.student?.zaloAdded === 1 && (
                    <div className='mt-2'>
                      <Link
                        to={register?.currentClassroom?.zaloLinkFreeChat}
                        target='_blank'
                        className='text-primary-main hover:text-primary-dark'
                      >
                        {register?.currentClassroom?.zaloLinkFreeChat}
                      </Link>
                    </div>
                  )}
                </Grid>
                <Grid item md={4} xs={12} className='space-x-3'>
                  <span>Đã join Zalo</span>
                  {register?.student?.zaloAdded === 1 ? (
                    <Tooltip title='Đã Join Zalo'>
                      <Chip label={<Check />} color='success' variant='filled' />
                    </Tooltip>
                  ) : (
                    <Tooltip title='Chưa Join Zalo'>
                      <Chip label={<Close />} />
                    </Tooltip>
                  )}
                </Grid>
                <Grid item md={8} xs={12}>
                  <span>Link Google Meet: </span>
                  {!!register?.currentClassroom?.googleMeetLink && (
                    <Link
                      to={register?.currentClassroom?.googleMeetLink}
                      target='_blank'
                      className='text-primary-main hover:text-primary-dark'
                    >
                      {register?.currentClassroom?.googleMeetLink}
                    </Link>
                  )}
                </Grid>
              </>
            </Grid>
          </>
        )}
      </Container>

      {!!register?.currentClassroom?.id && register?.currentClassroom?.id === register.classroomId && (
        <Container maxWidth='xl' className='py-6'>
          <DocList classroom={register?.currentClassroom!} editable={false} />
        </Container>
      )}

      {!!register?.currentClassroom?.id &&
        !!register?.classroomId &&
        register?.currentClassroom?.id !== register.classroomId && (
          <>
            <Container maxWidth='xl' className='py-6'>
              <DocList
                classroom={register?.currentClassroom!}
                editable={false}
                note={
                  'Với video xem trước khóa học, vui lòng kiểm tra ở danh sách tài liệu của lớp ' +
                  register.classroom?.name
                }
              />
            </Container>
            <Container maxWidth='xl' className='py-6'>
              <DocList classroom={register?.classroom!} editable={false} />
            </Container>
          </>
        )}

      {['SUCCESS', 'LEARNING', 'RESERVE'].includes(register?.status ?? '') && (
        <Container maxWidth='xl' className='py-6'>
          <Typography variant='h4' color='primary' className='mb-2'>
            Lịch sử bảo lưu
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className='mt-1 flex justify-start space-x-3'>
                <Button
                  onClick={() => {
                    setOpenReseve(true);
                  }}
                >
                  Đăng ký bảo lưu
                </Button>
              </div>
            </Grid>
          </Grid>
          <DataGrid
            loading={isFetching}
            getRowId={(row) => row.id}
            getRowHeight={() => 64}
            rows={rowsWithIndex}
            rowCount={total}
            onPaginationModelChange={onPaginationChange}
            columns={[
              {
                field: 'STT',
                headerName: 'STT',
                minWidth: 80,
                sortable: false,
                align: 'center',
                renderCell: ({ row }) => <div>{row.index}</div>,
              },
              {
                field: 'createdAt',
                headerName: 'Ngày đăng ký báo lưu',
                minWidth: 240,
                flex: 1,
                align: 'center',
                sortable: false,
                renderCell: ({ row }) => <div>{formatDateTime(row.createdAt)}</div>,
              },
              {
                field: 'toClassroom.name',
                headerName: 'Lớp học muốn bảo lưu',
                minWidth: 300,
                flex: 1,
                sortable: false,
                renderCell: ({ row }) => <div className='font-bold'>{row.toClassroom?.name}</div>,
              },
            ]}
          />
        </Container>
      )}

      <Dialog open={openReseve} maxWidth='md'>
        <PopupRegisterReserve item={register!} onClose={() => setOpenReseve(false)} />
      </Dialog>
      {register?.allowSurvey && register?.student && (
        <Dialog open={openSurvey} maxWidth='sm'>
          <PopupSurvey item={register!} view={false} onClose={() => setOpenSurvey(false)} />
        </Dialog>
      )}
    </>
  );
};

export default RegisterView;
