import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { categoryService } from 'services';

type Props = {
  onChange: (...args: any) => void;
};

const PaperSearch = ({ onChange }: Props) => {
  const { control, handleSubmit, reset } = useForm<TestPaperSearchParams>();

  const { data: dataObject } = useQuery(['categoryService.getAllTestObjects'], () =>
    categoryService.getAllTestObjects(),
  );

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      objectId: '',
      name: '',
      createdAtFrom: null,
      createdAtTo: null,
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Controller
            name='objectId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect
                {...field}
                fullWidth
                label='Đối tượng phù hợp'
                error={!!error}
                helperText={error?.message}
              >
                {(dataObject?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='name'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Tên bài test' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='createdAtFrom'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Ngày tạo từ',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='createdAtTo'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Ngày tạo đến',
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <div className='mt-6 flex justify-end space-x-3'>
        <Button onClick={handleClickSearch}>Tìm kiếm</Button>
        <Button variant='outlined' color='inherit' onClick={handleClickClear}>
          <Clear />
        </Button>
      </div>
    </>
  );
};

export default PaperSearch;
