import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { categoryService, fileService, queryClient, studentService } from 'services';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      {label && <span>{label}: </span>}
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

type Props = PopupController & {
  student: Student;
};

const PopupStudentEvaluation = ({ student, onClose }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileHashId, setFileHashId] = useState(student?.certificateFileHash);
  const { control, handleSubmit } = useForm<StudentEvaluationUpsertBody>();

  const { data: pageLevels } = useQuery(
    ['categoryService.getAllEvaluationLevels'],
    () => categoryService.getAllEvaluationLevels(),
    {
      keepPreviousData: true,
    },
  );

  const { data: imageData } = useQuery(
    ['fileService.downloadFile', fileHashId],
    () => fileService.downloadFile(fileHashId),
    {
      keepPreviousData: true,
      enabled: fileHashId != null && fileHashId !== '',
    },
  );

  const { mutate: upsertStudentEvaluation, isLoading } = useMutation(studentService.upsertStudentEvaluation, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật đánh giá học viên thành công');
      queryClient.invalidateQueries(['studentService.fetchStudents']);
      onClose();
    },
  });

  const { mutate: uploadFile, isLoading: isLoadingUpload } = useMutation(fileService.uploadFile, {
    onSuccess: (data) => {
      setFileHashId(data.hashId);
    },
  });

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const formData = new FormData();
    formData.append('file', fileList[0]);
    uploadFile(formData);
  };

  const handleClickSubmit = () => {
    if (fileHashId === '') {
      enqueueSnackbar('Vui lòng chọn file chứng chỉ', { variant: 'error' });
      return;
    }
    handleSubmit((values) => {
      upsertStudentEvaluation({
        ...values,
        id: student.id,
        certificateFileHash: fileHashId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Đánh giá học viên</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <GridInfo label='Họ tên' value={student.user.fullName} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Số điện thoại' value={student.user.phone} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Email' value={student.user.email} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Lớp học tham gia' value='' />
          </Grid>
          <Grid item md={8} xs={12}>
            <GridInfo label='' value={student.course.name + ' - ' + student.classroom.name} />
          </Grid>
        </Grid>
        <Grid container spacing={3} className='mt-1'>
          <Grid item md={4} xs={12}>
            <Controller
              name='passLevelId'
              defaultValue={student?.passLevelId}
              control={control}
              rules={{
                required: 'Xếp loại là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect
                  {...field}
                  fullWidth
                  required
                  label='Xếp loại'
                  error={!!error}
                  helperText={error?.message}
                >
                  {(pageLevels?.list ?? []).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>

          <Grid item md={8} xs={12}>
            <Controller
              name='testLink'
              defaultValue={student?.testLink}
              control={control}
              rules={{
                required: 'Link bài test là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Link bài test'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='detail'
              defaultValue={student?.detail}
              control={control}
              rules={{
                required: 'Đánh giá chi tiết là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={3}
                  required
                  label='Đánh giá chi tiết'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <GridInfo label='Chứng chỉ' value='' />
          </Grid>
          <Grid item md={8} xs={12}>
            <div className='flex justify-between'>
              <input ref={inputRef} type='file' hidden onChange={handleChangeFile} />
              <div className='space-x-3'>
                <LoadingButton
                  variant='outlined'
                  loading={isLoadingUpload}
                  size='small'
                  onClick={() => inputRef.current?.click()}
                >
                  Chọn file
                </LoadingButton>
              </div>
            </div>
          </Grid>
          {fileHashId && (
            <Grid item xs={12}>
              <div>
                <img
                  height={300}
                  width={300}
                  src={`${window.URL.createObjectURL(new Blob([imageData!]))}`}
                  alt='Ảnh chứng chỉ'
                  loading='lazy'
                />
              </div>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' disabled={!fileHashId} loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupStudentEvaluation;
