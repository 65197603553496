import { CourseView } from 'views/Course';
import { Home } from 'views/Home';
import { StudentRanking } from 'views/Student';
import { PostPublicList, PostPublicListByTopic, PostPublicView } from 'views/Post';
import { QuestionPresent, StudentEventView } from 'views/Quiz';

const publicRoute = {
  home: {
    path: '/',
    name: '',
    component: Home,
  },
  postPublicList: {
    path: '/posts',
    name: 'Danh sách bài viết',
    component: PostPublicList,
  },
  PostPublicListBySharingType: {
    path: '/sharing-types/:id/posts',
    url: ({ id }: ByID) => `/sharing-types/${id}/posts`,
    component: PostPublicList,
  },
  PostPublicListByTopic: {
    path: '/topic/:id/posts',
    url: ({ id }: ByID) => `/topic/${id}/posts`,
    component: PostPublicListByTopic,
  },
  postPublicView: {
    path: '/posts/:uuid',
    url: ({ uuid }: ByUUID) => `/posts/${uuid}`,
    name: 'Chi tiết bài bài viết',
    component: PostPublicView,
  },
  courseList: {
    path: '/courses',
    name: 'Trang chủ',
    component: Home,
  },
  courseView: {
    path: '/courses/:id',
    url: ({ id }: ByID) => `/courses/${id}`,
    name: '',
    component: CourseView,
  },
  studentRanking: {
    path: '/student-ranking',
    name: 'Tra cứu đánh giá học viên',
    component: StudentRanking,
  },
  quiz: {
    path: '/quizzes/:hashId',
    url: ({ hashId }: ByHashID) => `/quizzes/${hashId}`,
    name: '',
    component: StudentEventView,
  },
  quizPresent: {
    path: '/present-quizzes/:hashId',
    url: ({ hashId }: ByHashID) => `/present-quizzes/${hashId}`,
    name: '',
    component: QuestionPresent,
  },
};

export default publicRoute;
