import { BakeryDiningOutlined, Edit } from '@mui/icons-material';
import { Button, Container, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { CaseStudySearch, PopupCaseStudyUpdate, PopupHomeworkList } from './components';
import { TruncatedTextView } from 'views/Survey/components';
import { homeworkService } from 'services';

const CaseStudyList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    ['homeworkService.fetchCaseStudies', dataSearch],
    () => homeworkService.fetchCaseStudies(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const [openUpdateCaseStudy, setOpenUpdateCaseStudy] = useState(false);
  const [openHomework, setOpenHomework] = useState(false);
  const [itemChoice, setItemChoice] = useState<CaseStudy>();

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách case study
      </Typography>

      <CaseStudySearch onChange={onSearchChange} />
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 96}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'classrom.code',
            headerName: 'Lớp học',
            flex: 1,
            minWidth: 230,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.classroom.code} <br />
                {row.course.name}
              </div>
            ),
          },
          {
            field: 'user.Email',
            headerName: 'Học viên',
            flex: 1,
            minWidth: 300,
            sortable: false,
            renderCell: ({ row }) => (
              <>
                <div>
                  {row.user.fullName}
                  <br />
                  {row.user.email}
                  <br />
                  {row.user.phone}
                </div>
              </>
            ),
          },
          {
            field: 'production',
            headerName: 'Loại SP',
            minWidth: 150,
            sortable: false,
            renderCell: ({ row }) => <div>{row.production?.name}</div>,
          },
          {
            field: 'domain',
            headerName: 'Domain',
            minWidth: 150,
            sortable: false,
            renderCell: ({ row }) => <div>{row.domain?.name}</div>,
          },
          {
            field: 'name',
            headerName: 'Case study',
            minWidth: 280,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.name}
                <br />
                <TruncatedTextView text={'Ngữ cảnh: ' + (row.description ?? '')} />
              </div>
            ),
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Tooltip title='Cập nhật case study'>
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      setItemChoice(row);
                      setOpenUpdateCaseStudy(true);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
                <Tooltip title='Danh sách bài tập'>
                  <Button
                    size='small'
                    color='secondary'
                    onClick={() => {
                      setItemChoice(row);
                      setOpenHomework(true);
                    }}
                  >
                    <BakeryDiningOutlined />
                  </Button>
                </Tooltip>
              </div>
            ),
          },
        ]}
      />

      <Dialog maxWidth='md' open={openUpdateCaseStudy}>
        <PopupCaseStudyUpdate caseStudy={itemChoice!} onClose={() => setOpenUpdateCaseStudy(false)} />
      </Dialog>

      <Dialog maxWidth='xl' open={openHomework}>
        <PopupHomeworkList caseStudy={itemChoice!} onClose={() => setOpenHomework(false)} />
      </Dialog>
    </Container>
  );
};

export default CaseStudyList;
