import { CardMedia, Container, Typography } from '@mui/material';
import { InfiniteSlider } from 'components';
import { CourseList } from './components';

const Home = () => {
  return (
    <div>
      <InfiniteSlider {...{ autoplaySpeed: 10000, autoplay: true, dots: true, arrows: false }}>
        {[
          { image: 'https://i.imgur.com/XKAtnGB.jpeg' },
          { image: 'https://i.imgur.com/vC7y0Lg.jpeg' },
          { image: 'https://i.imgur.com/GfVc40m.jpeg' },
        ].map((item, index) => (
          <CardMedia key={index} image={item.image} className='h-[360px] md:h-[480px] xl:h-[600px]'>
            <div></div>
          </CardMedia>
        ))}
      </InfiniteSlider>

      <div className='py-10'>
        <Container className='space-y-3'>
          <Typography variant='h5'>VỀ CHÚNG TÔI</Typography>
          <Typography variant='h3'>Trung Tâm Đào Tạo - M2MBA</Typography>
          <div className='flex gap-10 mobile:flex-col-reverse'>
            <Typography color='textSecondary' className='space-y-2 pl-3 text-justify'>
              <li>
                Sự phát triển mạnh mẽ của công nghệ thông tin đã tác động và làm thay đổi hầu hết các hình thức hoạt
                động và sản xuất truyền thống, vai trò cầu nối giữa nhu cầu kinh doanh và việc phát triển các hệ thống
                phần mềm của chuyên viên phân tích nghiệp vụ phần mềm (Business Analyst - BA) càng trở nên quan trọng.
              </li>
              <li>
                Hiện nay, việc các Business Analyst nắm vững các kiến thức nền tảng đồng thời nâng cấp kỹ năng nghiệp vụ
                là một trong những điều kiện tiên quyết để phát triển công việc và tăng cơ hội thăng tiến trong tương
                lai. Thấu hiểu được nhu cầu của các BA, trung tâm M2MBA hơn 3 năm qua đã và đang mang đến các khóa học
                “IT cơ bản cho BA” và “BA thực chiến” giúp các bạn trau dồi kỹ năng nghiệp vụ của mình một cách hiệu
                quả.
              </li>
              <li>
                Đặc biệt hơn, giảng viên Đậu Thị Ngọc Ánh với hơn 9+ năm kinh nghiệm thực chiến trong nghề sẽ trực tiếp
                truyền dạy cho học viên nhiều kỹ năng, kiến thức và kinh nghiệm chuyên môn về phân tích nghiệp vụ ngay
                trên dự án phần mềm trong quá trình học khóa Business Analyst tại M2MBA. Sự tâm huyết cũng như năng lực
                chuyên môn cao của giảng viên Ngọc Ánh hứa hẹn sẽ giúp học viên lĩnh hội được nhiều kiến thức thực tế
                cũng như kỹ năng tốt nhất để áp dụng cho vị trí BA.
              </li>
            </Typography>
            <img src='https://i.imgur.com/4axj750.jpeg' className='mx-auto w-[240px] rounded-xl' />
          </div>
        </Container>
      </div>

      <div className='bg-[#fcf4f3] py-10 pb-20'>
        <Typography variant='h3' align='center' className='mb-10'>
          DANH SÁCH KHOÁ HỌC
        </Typography>
        <Container>
          <CourseList />
        </Container>
      </div>
    </div>
  );
};

export default Home;
