import { DoneTwoTone } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

const PopupRegisterNotice = ({ onClose }: PopupController) => {
  return (
    <>
      <DialogTitle>Đăng ký tài khoản thành công</DialogTitle>

      <DialogContent>
        <div className='text-center'>
          <DoneTwoTone
            color='success'
            sx={{
              fontSize: 48,
              fontWeight: 'bold',
              mx: 0.5,
            }}
          ></DoneTwoTone>
        </div>
        <div className='text-center'>
          Đăng ký thành công, vui lòng kiểm tra Email và thực hiện kích hoạt tài khoản <br /> (Cần check thêm Spam)
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Đóng
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupRegisterNotice;
