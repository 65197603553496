import { IconButton } from '@mui/material';
import { Close, CheckCircle, HourglassBottom, ManageHistory, EditNote } from '@mui/icons-material';

type Props = {
  answer: TestPaperResultAnswer;
  isTeacher: boolean;
};

const ViewAnswerInfo = ({ answer, isTeacher = true }: Props) => {
  return (
    <>
      <div className='items-end'>
        {isTeacher && answer.isMarked && (
          <IconButton
            edge='end'
            aria-label='Câu hỏi đã chấm điểm tự luận'
            title='Câu hỏi đã chấm điểm tự luận'
            color='info'
          >
            <EditNote />
          </IconButton>
        )}
        {answer.questionType === 'CHOICE' ||
        (answer.questionType === 'ESSAY' && answer.questionEssayAnswerType && answer.questionEssayAnswer) ? (
          <>
            {answer.isCorrect !== 2 && (
              <IconButton edge='end' aria-label='Câu hỏi làm sai' title='Câu hỏi làm sai' color='error'>
                <Close />
              </IconButton>
            )}
            {answer.isCorrect === 2 && (
              <IconButton edge='end' aria-label='Câu hỏi làm đúng' title='Câu hỏi làm đúng' color='success'>
                <CheckCircle />
              </IconButton>
            )}
          </>
        ) : answer.answerContent ? (
          answer.isMarked ? (
            answer.point === answer.maxPoint ? (
              <IconButton edge='end' aria-label='Câu hỏi làm đúng' title='Câu hỏi làm đúng' color='success'>
                <CheckCircle />
              </IconButton>
            ) : answer.point === 0 ? (
              <IconButton edge='end' aria-label='Câu hỏi làm sai' title='Câu hỏi làm sai' color='error'>
                <Close />
              </IconButton>
            ) : (
              <IconButton
                edge='end'
                aria-label='Câu hỏi tự luận đúng một phần'
                title='Câu hỏi tự luận đúng một phần'
                color='primary'
              >
                <HourglassBottom />
              </IconButton>
            )
          ) : (
            <IconButton
              edge='end'
              aria-label='Câu hỏi tự luận chưa chấm điểm'
              title='Câu hỏi tự luận chưa chấm điểm'
              color='info'
            >
              <ManageHistory />
            </IconButton>
          )
        ) : (
          <IconButton edge='end' aria-label='Câu hỏi làm sai' title='Câu hỏi làm sai' color='error'>
            <Close />
          </IconButton>
        )}
      </div>
    </>
  );
};

export default ViewAnswerInfo;
