import { CheckCircle, People } from '@mui/icons-material';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { queryClient } from 'services';
import EventQr from './EventQr';

type Props = {
  question: QuizQuestionStatistic;
  eventHashId: string;
  present?: boolean;
  onShowAnswer?: () => void;
  onShowStatistic?: () => void;
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const QuestionStatisView = ({ question, eventHashId, present, onShowAnswer, onShowStatistic }: Props) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const [showStatistic, setShowStatistic] = useState(true);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h5' className='space-x-3' sx={{ whiteSpace: 'pre-wrap' }}>
            <span>{question.content}</span>
          </Typography>
        </Grid>
        {showStatistic && (
          <Grid item xs={12}>
            <Stack direction='row' alignItems='center' gap={1}>
              <People />
              <Typography variant='body1'>{question.totalAttendee}</Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
      <Divider light className='mb-2 mt-2' />
      {question.answers.map((answer, index) => (
        <div key={index}>
          <Grid container spacing={2} key={index} className='py-4'>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 18, whiteSpace: 'pre-wrap' }} className='space-x-3'>
                <span>{answer.content}</span>
              </Typography>
              {showAnswer && answer.isCorrect === 1 && <CheckCircle color='success' className='mt-1' />}
            </Grid>
            {showStatistic && (
              <>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Stack direction='row' alignItems='center' gap={1}>
                      <People />
                      <Typography variant='body1'>{answer.totalAttendee}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <BorderLinearProgress color='inherit' variant='determinate' value={answer.percent} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant='body2' color='text.secondary'>{`${answer.percent}%`}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <Divider light />
        </div>
      ))}
      <div className='mb-2 mt-2 flex justify-center p-2'>
        <div className='mr-2'>
          {showAnswer ? (
            <LoadingButton
              variant='contained'
              onClick={() => {
                setShowAnswer(false);
                if (onShowAnswer) {
                  onShowAnswer();
                }
              }}
            >
              Ẩn đáp án
            </LoadingButton>
          ) : (
            <LoadingButton
              variant='contained'
              onClick={() => {
                setShowAnswer(true);
                if (onShowAnswer) {
                  onShowAnswer();
                }
              }}
            >
              Hiện đáp án
            </LoadingButton>
          )}
        </div>
        <div className='ml-2'>
          {showStatistic ? (
            <LoadingButton
              variant='contained'
              color='info'
              onClick={() => {
                setShowStatistic(false);
                if (onShowStatistic) {
                  onShowStatistic();
                }
              }}
            >
              Ẩn thống kê
            </LoadingButton>
          ) : (
            <LoadingButton
              variant='contained'
              color='info'
              onClick={() => {
                queryClient.invalidateQueries([`quizService.getQuizEventQuestionPlayStatistic_${eventHashId}`]);
                setShowStatistic(true);
                if (onShowStatistic) {
                  onShowStatistic();
                }
              }}
            >
              Hiện thống kê
            </LoadingButton>
          )}
        </div>
      </div>
      {question && <EventQr link={question.link} hashId={eventHashId} present={present} />}
    </>
  );
};

export default QuestionStatisView;
