import { useQuery } from '@tanstack/react-query';
import { homeworkService } from 'services';
import CardStudentHomework from './CardStudentHomework';
import { Typography } from '@mui/material';

type Props = {
  classroomId: number;
};

const StudentHomework = ({ classroomId }: Props) => {
  const { data } = useQuery(
    ['homeworkService.fetchMyHomework'],
    () => homeworkService.fetchMyHomework({ id: classroomId }),
    { keepPreviousData: true },
  );
  const { currentHomeworks: currentItems = [] } = data ?? {};
  const { oldHomeworks: oldItems = [] } = data ?? {};
  return (
    <>
      <div className='mt-6 flex flex-col space-y-6'>
        {oldItems.length > 0 && (
          <Typography variant='h4' color='primary'>
            1. Bài tập lớp hiện tại
          </Typography>
        )}
        {currentItems?.map((homework) => (
          <CardStudentHomework key={homework.id} item={homework} />
        ))}
      </div>
      {oldItems.length > 0 && (
        <div className='mt-6 flex flex-col space-y-6'>
          <Typography variant='h4' color='primary'>
            2. Bài tập lớp đã học
          </Typography>
          {oldItems?.map((homework) => (
            <CardStudentHomework key={homework.id} item={homework} />
          ))}
        </div>
      )}
    </>
  );
};

export default StudentHomework;
