import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

type BreadcrumbItem = {
  name: string | null;
  path?: string;
};

interface CustomBreadcrumbsProps {
  breadcrumbs: BreadcrumbItem[];
}

export default function CustomBreadcrumbs({ breadcrumbs }: CustomBreadcrumbsProps) {
  let navigate = useNavigate();

  const handleNavigation = (event: React.MouseEvent<HTMLElement, MouseEvent>, path?: string) => {
    event.preventDefault();
    if (path) {
      navigate(path);
    }
  };

  return (
    <div style={{ margin: '16px 0' }}>
      <Breadcrumbs aria-label='breadcrumb' separator='›' style={{ color: 'black' }}>
        {breadcrumbs.map((breadcrumb, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return isLast ? (
            <Typography color='text.primary' key={breadcrumb.name}>
              {breadcrumb.name}
            </Typography>
          ) : (
            <Link
              color='inherit'
              href={breadcrumb.path || '#'}
              onClick={(e) => handleNavigation(e, breadcrumb.path)}
              key={breadcrumb.name}
            >
              {breadcrumb.name}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
