import { Container, Dialog, Grid, List, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { paperService } from 'services';
import { PaperQuestionStatisticSearch, PopupQuestionAnswerList, QuestionStatisticView } from './components';
import { LoadingButton } from '@mui/lab';
import { adminRoute } from 'routes';
import { useSearch } from 'hooks';
import { useState } from 'react';

const PaperStatisticView = () => {
  const [opentDetail, setOpenDetail] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<TestQuestionStatistic | null>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: paper } = useQuery(['paperService.getPaperInfo'], () => paperService.getPaperInfo({ id: +id! }), {
    keepPreviousData: true,
  });

  const { dataSearch, onSearchChange } = useSearch();

  const { data, isSuccess } = useQuery(
    ['paperService.fetchPaperQuestionStatistic', dataSearch],
    () => paperService.fetchPaperQuestionStatistic({ ...dataSearch, id: paper?.id ?? 0, size: -1 }),
    { keepPreviousData: true, enabled: !!paper },
  );
  const { list: items = [] } = data ?? {};

  const backList = () => {
    navigate(adminRoute.paperList.path);
  };

  const handleClickViewQuestion = (question: TestQuestionStatistic, index: number) => {
    setSelectedQuestion(question);
    setSelectedIndex(index);
    setOpenDetail(true);
  };
  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Typography variant='h4' color='primary' className='mb-10'>
          Báo cáo kết quả bài test {paper?.name}
        </Typography>
        {paper && <PaperQuestionStatisticSearch paper={paper} onChange={onSearchChange} />}
        <Grid container spacing={3} className='mt-6'>
          {isSuccess && (
            <Grid item xs={12}>
              <List>
                {paper &&
                  items.map((question, index) => (
                    <QuestionStatisticView
                      key={index}
                      questionIndex={index + 1}
                      question={question}
                      onClickDetail={handleClickViewQuestion}
                    />
                  ))}
              </List>
              {items.length === 0 && <div className='text-center font-bold'>BÀI TEST CHƯA CÓ CÂU HỎI NÀO</div>}
            </Grid>
          )}
        </Grid>
        <div className='mt-3 flex justify-center'>
          <LoadingButton variant='contained' onClick={backList}>
            Quay lại
          </LoadingButton>
        </div>
      </Container>
      {paper && selectedQuestion && (
        <Dialog maxWidth='xl' open={opentDetail}>
          <PopupQuestionAnswerList
            paperId={paper?.id!}
            param={dataSearch}
            question={selectedQuestion}
            questionIndex={selectedIndex}
            onClose={() => setOpenDetail(false)}
          />
        </Dialog>
      )}
    </>
  );
};

export default PaperStatisticView;
