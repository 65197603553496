import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { courseService } from 'services';
import { ClassroomStatusTypes } from 'utils/common';

type Props = {
  onChange: (...args: any) => void;
};

const ClassroomSearch = ({ onChange }: Props) => {
  const { control, handleSubmit, reset } = useForm<ClassroomSearchParams>();

  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());

  const handleClickSearch = () => {
    handleSubmit((values) => {
      if (!values.status) values.status = null;
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      courseId: '',
      status: '',
      fromStartDate: null,
      toStartDate: null,
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Controller
            name='courseId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Khóa học' error={!!error} helperText={error?.message}>
                {(dataCourse?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='status'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Trạng thái' error={!!error} helperText={error?.message}>
                {ClassroomStatusTypes.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='fromStartDate'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Khai giảng từ',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='toStartDate'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Khai giảng đến',
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <div className='mt-6 flex justify-end space-x-3'>
        <Button onClick={handleClickSearch}>Tìm kiếm</Button>
        <Button variant='outlined' color='inherit' onClick={handleClickClear}>
          <Clear />
        </Button>
      </div>
    </>
  );
};

export default ClassroomSearch;
