import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { registerService } from 'services';
import { CardClassroom, PopupRegisterReserveConfirm } from '.';
import { useState } from 'react';

type Props = PopupController & {
  item: Register;
};

const PopupRegisterReserve = ({ item, onClose }: Props) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [classroomId, setClassroomId] = useState<number | undefined>();
  const { data: dataClassroom } = useQuery(['classroomService.fetchReserveClassroom', item.id], () =>
    registerService.fetchReserveClassroom({ id: item.id }),
  );

  return (
    <>
      <DialogTitle>Vui lòng chọn lớp bạn muốn tham gia học trong thời gian tới</DialogTitle>

      <DialogContent>
        <div className='flex flex-col space-y-6'>
          {(dataClassroom?.list ?? []).map((classroom) => (
            <CardClassroom
              key={classroom.id}
              classroom={classroom}
              onClick={() => {
                setClassroomId(classroom.id);
                setOpenConfirm(true);
              }}
            />
          ))}
          <Paper
            variant='outlined'
            className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
          >
            <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
              <div className='space-y-2'>
                <Typography variant='h4' className='hover:text-primary-main'>
                  Tôi chưa có thời gian dự kiến học
                </Typography>
              </div>
            </div>
            <div className='flex flex-col items-end justify-between'>
              <div>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setClassroomId(undefined);
                    setOpenConfirm(true);
                  }}
                  className='px-10'
                >
                  Bảo lưu
                </LoadingButton>
              </div>
            </div>
          </Paper>
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>

      <Dialog open={openConfirm}>
        <PopupRegisterReserveConfirm
          register={item}
          classroomId={classroomId}
          onClose={() => {
            setOpenConfirm(false);
          }}
          onSuccess={() => {
            setOpenConfirm(false);
            onClose();
          }}
        />
      </Dialog>
    </>
  );
};

export default PopupRegisterReserve;
