import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { paperService, queryClient } from 'services';

type Props = PopupController & {
  paperIds: number[];
};

const PopupAssignEmail = ({ paperIds, onClose }: Props) => {
  const { control, handleSubmit } = useForm<TestPaperAssignAddBody>();
  const { mutate: assignEmail, isLoading } = useMutation(paperService.addPaperAssign, {
    onSuccess: () => {
      queryClient.invalidateQueries(['paperService.fetchPaperAssignsByStudent']);
      enqueueSnackbar('Gán bài test cho danh sách email thành công');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      assignEmail({
        ...values,
        paperIds: paperIds,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Gán bài test cho danh sách email</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name='emails'
              defaultValue=''
              control={control}
              rules={{
                required: 'Danh sách email là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  multiline
                  minRows={4}
                  fullWidth
                  required
                  label='Danh sách email cần gán, cách nhau dấu phẩy'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupAssignEmail;
