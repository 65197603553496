import { Edit, InfoOutlined, Stop, Quiz, Assessment, Start, AllInclusive, Report } from '@mui/icons-material';
import { Button, Chip, Container, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { adminRoute } from 'routes';
import { paperService } from 'services';
import { formatDateTime } from 'utils/common';
import {
  PaperSearch,
  PopupAssignEmail,
  PopupPaperAssessment,
  PopupPaperAssign,
  PopupPaperStart,
  PopupPaperStop,
  PopupPaperUpsert,
} from './components';

const PaperList = () => {
  const [paperIds, setPaperIds] = useState<number[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    ['paperService.fetchPapers', dataSearch],
    () => paperService.fetchPapers(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const [openUpdateStart, setOpenUpdateStart] = useState(false);
  const [openUpdateStop, setOpenUpdateStop] = useState(false);
  const [openAddPaper, setOpenAddPaper] = useState(false);
  const [openUpdatePaper, setOpenUpdatePaper] = useState(false);
  const [openAssessmeent, setOpenAssessmeent] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [openOneAssign, setOneOpenAssign] = useState(false);
  const [openAssignEmail, setOpenAssignEmail] = useState(false);
  const [itemChoice, setItemChoice] = useState<TestPaper>();

  const { data: dataAssessment, isSuccess: isSuccessAssessment } = useQuery(
    ['paperService.getPaperAssesment', { id: itemChoice?.id }],
    () => paperService.getPaperAssesment({ id: itemChoice!.id }),
    {
      enabled: !!itemChoice,
    },
  );

  useEffect(() => {
    const selectedRowData = new Set(rowSelectionModel);
    const selectedIds = items.filter((e) => selectedRowData.has(e.id)).map((e) => e.id);
    setPaperIds(selectedIds);
  }, [items, rowSelectionModel]);

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách bài test
      </Typography>
      <PaperSearch onChange={onSearchChange} />
      <div className='mt-6 flex justify-end space-x-3'>
        <Button color='success' onClick={() => setOpenAddPaper(true)}>
          Thêm mới
        </Button>
        <Button
          color='primary'
          title='Giao bài test cho khóa học, lớp học, học viên'
          onClick={() => {
            setOpenAssign(true);
          }}
          disabled={paperIds.length === 0}
        >
          Gán bài test
        </Button>
        <Button
          color='secondary'
          title='Giao bài test cho danh sách email của học viên'
          onClick={() => setOpenAssignEmail(true)}
          disabled={paperIds.length === 0}
        >
          Gán bài test theo email
        </Button>
      </div>
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 96}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        disableRowSelectionOnClick
        rowSelectionModel={rowSelectionModel}
        columns={[
          {
            field: 'name',
            headerName: 'Tên bài test',
            flex: 1,
            minWidth: 360,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.name} <br />
                Đối tượng phù hợp: {row.testObject?.name}
                {row.isPublic && (
                  <>
                    <br />
                    <Tooltip title='Bài test public'>
                      <Chip label={<AllInclusive />} color='success' variant='filled' />
                    </Tooltip>
                  </>
                )}
              </div>
            ),
          },
          {
            field: 'totalQuestion',
            headerName: 'Câu hỏi/điểm',
            minWidth: 160,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                Số câu hỏi: {row.totalQuestion} câu <br />
                Điểm: {row.maxPoint} điểm
              </div>
            ),
          },
          {
            field: 'maxTime',
            headerName: 'Thông tin thi',
            minWidth: 240,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                Tối đa: {row.maxTime} lần <br />
                Thời gian làm bài: {row.minute} phút <br />
                Thời hạn làm bài: {row.expiredDay} ngày
              </div>
            ),
          },
          {
            field: 'totalStudent',
            headerName: 'Tổng học viên',
            minWidth: 140,
            sortable: false,
            renderCell: ({ row }) => <div>{row.totalStudent}</div>,
          },
          {
            field: 'createdAt',
            headerName: 'Ngày tạo/Người tạo',
            minWidth: 190,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {formatDateTime(row.createdAt)}
                <br />
                {row.creator?.fullName}
              </div>
            ),
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 315,
            renderCell: ({ row }) => (
              <div className='flex space-x-1'>
                <Link to={adminRoute.paperView.url(row)} title='Danh sách câu hỏi'>
                  <Button size='small' color='info'>
                    <InfoOutlined />
                  </Button>
                </Link>
                <Link to={adminRoute.paperStatisticView.url(row)} title='Thống kê câu hỏi'>
                  <Button size='small' color='success'>
                    <Report />
                  </Button>
                </Link>
                <Tooltip title='Cập nhật thông tin bài test'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setItemChoice(row);
                      setOpenUpdatePaper(true);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
                {row.isActive !== 1 && (
                  <Tooltip title='Kích hoạt bài test'>
                    <Button
                      size='small'
                      color='success'
                      onClick={() => {
                        setItemChoice(row);
                        setOpenUpdateStart(true);
                      }}
                    >
                      <Start />
                    </Button>
                  </Tooltip>
                )}
                {row.isActive === 1 && (
                  <Tooltip title='Tạm dừng bài test'>
                    <Button
                      size='small'
                      color='error'
                      onClick={() => {
                        setItemChoice(row);
                        setOpenUpdateStop(true);
                      }}
                    >
                      <Stop />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title='Giao bài test cho khóa học, lớp học, học viên'>
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      setItemChoice(row);
                      setOneOpenAssign(true);
                    }}
                  >
                    <Quiz />
                  </Button>
                </Tooltip>
                <Tooltip title='Cấu hình đánh giá bài test'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setItemChoice(row);
                      setOpenAssessmeent(true);
                    }}
                  >
                    <Assessment />
                  </Button>
                </Tooltip>
              </div>
            ),
          },
        ]}
      />

      <Dialog open={openAddPaper} maxWidth='lg'>
        <PopupPaperUpsert onClose={() => setOpenAddPaper(false)} />
      </Dialog>
      <Dialog open={openUpdatePaper} maxWidth='lg'>
        <PopupPaperUpsert paper={itemChoice!} onClose={() => setOpenUpdatePaper(false)} />
      </Dialog>
      <Dialog open={openUpdateStart}>
        <PopupPaperStart paper={itemChoice!} onClose={() => setOpenUpdateStart(false)} />
      </Dialog>
      <Dialog open={openUpdateStop}>
        <PopupPaperStop paper={itemChoice!} onClose={() => setOpenUpdateStop(false)} />
      </Dialog>
      {isSuccessAssessment && (
        <Dialog maxWidth='md' open={openAssessmeent}>
          <PopupPaperAssessment
            paper={itemChoice!}
            assessments={dataAssessment?.assessments ?? []}
            onClose={() => setOpenAssessmeent(false)}
          />
        </Dialog>
      )}
      <Dialog maxWidth='lg' open={openAssign && !!paperIds}>
        <PopupPaperAssign paperIds={paperIds} onlyAssign={true} onClose={() => setOpenAssign(false)} />
      </Dialog>
      <Dialog maxWidth='lg' open={openOneAssign}>
        <PopupPaperAssign paperIds={[itemChoice?.id ?? 0]} onlyAssign={false} onClose={() => setOneOpenAssign(false)} />
      </Dialog>
      <Dialog maxWidth='md' open={openAssignEmail}>
        <PopupAssignEmail paperIds={paperIds} onClose={() => setOpenAssignEmail(false)} />
      </Dialog>
    </Container>
  );
};

export default PaperList;
