import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { queryClient, quizService } from 'services';

type Props = PopupController & {
  question: QuizQuestionDetail;
};

const PopupQuestionUpdate = ({ question, onClose }: Props) => {
  const { control, handleSubmit } = useForm<QuizQuestionUpsertBody>({
    defaultValues: {
      answers: question.answers.map(({ id, content, image, isCorrect }) => ({
        id,
        content,
        image,
        isCorrect: isCorrect === 1,
      })),
    },
  });
  const {
    fields: answers,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'answers',
  });

  const { mutate: addQuestion, isLoading } = useMutation(quizService.putQuizEventQuestion, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật câu hỏi thành công');
      queryClient.invalidateQueries(['quizService.getQuizEventDetail']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      addQuestion({
        ...values,
        eventId: question.quizEventId,
        questionId: question.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Cập nhật câu hỏi</DialogTitle>

      <DialogContent>
        <Container maxWidth='xl' className='mt-1 py-1'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name='content'
                defaultValue={question.content}
                control={control}
                rules={{
                  required: 'Nội dung câu hỏi là bắt buộc',
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    multiline
                    label='Nội dung câu hỏi'
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='image'
                defaultValue={question.image}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='Link ảnh câu hỏi'
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Container>
        {answers.map((item, index) => {
          return (
            <Container key={index} maxWidth='xl' className='mt-4 py-1'>
              <Typography variant='h6' color='info' className='space-x-3'>
                <span>Đáp án {index + 1}</span>
                <Controller
                  name={`answers.${index}.isCorrect`}
                  defaultValue={item.isCorrect}
                  control={control}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <FormControlLabel
                      key={1}
                      label='Đáp án đúng'
                      control={<Checkbox {...field} checked={field.value} color='primary' />}
                    />
                  )}
                />
                <LoadingButton
                  variant='outlined'
                  color='error'
                  onClick={() => {
                    remove(index);
                  }}
                >
                  Xóa
                </LoadingButton>
              </Typography>
              <Grid container spacing={3} key={index} className='mt-1'>
                <Grid item xs={12}>
                  <Controller
                    name={`answers.${index}.content`}
                    defaultValue={item.content}
                    rules={{
                      required: 'Nội dung đáp án là bắt buộc',
                    }}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        multiline
                        label='Nội dung đáp án'
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={`answers.${index}.image`}
                    defaultValue={item.image}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label='Link ảnh đáp án'
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Container>
          );
        })}
        <Container maxWidth='xl' className='mt-1 py-1'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LoadingButton
                variant='outlined'
                onClick={() => {
                  append({ content: '', image: '' });
                }}
              >
                Thêm đáp án
              </LoadingButton>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupQuestionUpdate;
