import { Clear, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Container, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldNumber, TextFieldSelect } from 'components/common';
import { useSearch } from 'hooks';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { categoryService, paperService, queryClient, testQuestionService } from 'services';
import { TestQuestionLevelTypes } from 'utils/common';

type Props = PopupController & {
  paper: TestPaper;
};

const PopupAutoAddPaperQuestion = ({ paper, onClose }: Props) => {
  const [values, setValues] = useState<TestQuestionGroupCount[]>([]);
  const { dataSearch, onSearchChange } = useSearch();
  const { control, handleSubmit, reset, watch } = useForm<TestQuestionGroupCountParams>();
  const parentTopicId = watch('parentTopicId');
  const level = watch('level');
  const totalQuestion = watch('total');
  const { data: dataParentTopic } = useQuery(['categoryService.getAllTestQuestionParentTopics'], () =>
    categoryService.getAllTestQuestionTopics({ parentId: '' }),
  );

  const { data: dataTopic } = useQuery(
    ['categoryService.getAllTestQuestionTopics', { parentId: parentTopicId }],
    () => categoryService.getAllTestQuestionTopics({ parentId: parentTopicId }),
    {
      enabled: !!parentTopicId,
    },
  );

  const { data: dataLevel } = useQuery(['categoryService.getAllQuestionLevels'], () =>
    categoryService.getAllQuestionLevels(),
  );

  const { data, isFetching } = useQuery(
    ['testQuestionService.CountQuestionGroup', dataSearch],
    () => testQuestionService.CountQuestionGroup(dataSearch),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const { mutate: addPaperQuestions, isLoading } = useMutation(paperService.addPaperQuestionsByGroup, {
    onSuccess: () => {
      enqueueSnackbar('Gán câu hỏi vào bài test thành công');
      queryClient.invalidateQueries(['paperService.fetchPaperQuestions']);
      handleClickClear();
    },
  });

  useEffect(() => {
    if (
      !data ||
      values.find(
        (e) =>
          e.parentTopicId === data.parentTopicId && (e.topicId ?? 0 === data.topicId ?? 0) && e.level === data.level,
      )
    ) {
      return;
    }

    const newValues = values.slice();
    newValues.push(data);
    setValues(newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onSearchChange(values);
    })();
  };

  const remove = (data: TestQuestionGroupCount) => {
    const index = values.findIndex(
      (e) => e.parentTopicId === data.parentTopicId && e.topicId === data.topicId && e.level === data.level,
    );
    if (index >= 0) {
      const newValues = values.slice();
      newValues.splice(index, 1);
      setValues(newValues);
    }
  };

  const handleClickClear = () => {
    reset({
      parentTopicId: '',
      topicId: '',
      level: '',
      total: '',
    });
  };

  const handleClickAdd = () => {
    addPaperQuestions({
      id: paper.id,
      groups: values.map((e) => ({
        parentTopicId: e.parentTopicId,
        topicId: e.topicId,
        level: e.level,
        totalQuestion: +totalQuestion!,
      })),
    });
  };

  return (
    <>
      <DialogTitle>Cập nhật câu hỏi cho bài test {paper.name}</DialogTitle>

      <DialogContent>
        <Container maxWidth='xl' className='mt-1 py-1'>
          <Grid container spacing={4}>
            <Grid item md={3} xs={12}>
              <Controller
                name='parentTopicId'
                defaultValue=''
                rules={{
                  required: 'Chủ đề cha là bắt buộc',
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect
                    {...field}
                    fullWidth
                    required
                    label='Chủ đề cha'
                    error={!!error}
                    helperText={error?.message}
                  >
                    {(dataParentTopic?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Controller
                name='topicId'
                defaultValue=''
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect {...field} fullWidth label='Chủ đề con' error={!!error} helperText={error?.message}>
                    {(dataTopic?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Controller
                name='level'
                defaultValue=''
                rules={{
                  required: 'Mức độ câu hỏi là bắt buộc',
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect
                    {...field}
                    fullWidth
                    required
                    label='Mức độ'
                    error={!!error}
                    helperText={error?.message}
                  >
                    {(dataLevel?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.code}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Controller
                name='total'
                defaultValue={1}
                rules={{
                  required: 'Số lượng là bắt buộc',
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldNumber
                    {...field}
                    fullWidth
                    required
                    label='Số lượng'
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className='mt-2 text-right'>
              <LoadingButton
                disabled={!parentTopicId || !level || !totalQuestion}
                variant='outlined'
                color='primary'
                onClick={handleClickSearch}
              >
                Thêm cấu hình
              </LoadingButton>
              <Button variant='outlined' className='ml-2' color='inherit' onClick={handleClickClear}>
                <Clear />
              </Button>
            </Grid>
          </Grid>
          <DataGrid
            loading={isFetching}
            getRowId={(row) => row.parentTopicId + '_' + row.topicId + '_' + row.level}
            rows={values}
            paginationMode='client'
            columns={[
              {
                field: 'parentTopicName',
                headerName: 'Chủ đề cha',
                flex: 1,
                minWidth: 280,
                sortable: false,
                renderCell: ({ row }) => <div>{row.parentTopicName}</div>,
              },
              {
                field: 'topicName',
                headerName: 'Chủ đề con',
                flex: 1,
                minWidth: 280,
                sortable: false,
                renderCell: ({ row }) => <div>{row.topicName}</div>,
              },
              {
                field: 'level',
                headerName: 'Mức độ',
                flex: 1,
                minWidth: 160,
                sortable: false,
                renderCell: ({ row }) => (
                  <div>{TestQuestionLevelTypes.find((item) => item.value === row.level)?.label}</div>
                ),
              },
              {
                field: 'total',
                headerName: 'Số lượng thỏa mãn',
                flex: 1,
                minWidth: 140,
                sortable: false,
                renderCell: ({ row }) => <div>{row.total}</div>,
              },
              {
                field: 'totalRequired',
                headerName: 'Số lượng yêu cầu',
                flex: 1,
                minWidth: 140,
                sortable: false,
                renderCell: ({ row }) => <div>{totalQuestion}</div>,
              },
              {
                field: 'actions',
                headerName: 'Hành động',
                sortable: false,
                minWidth: 130,
                renderCell: ({ row }) => (
                  <div className='flex space-x-1'>
                    <Tooltip title='Xóa danh sách câu hỏi được lựa chọn'>
                      <Button
                        size='small'
                        color='error'
                        onClick={() => {
                          remove(row);
                        }}
                      >
                        <Delete />
                      </Button>
                    </Tooltip>
                  </div>
                ),
              },
            ]}
          />
          <Grid item xs={12} className='mt-2 text-right'>
            <LoadingButton
              loading={isLoading}
              variant='contained'
              color='success'
              onClick={handleClickAdd}
              disabled={!values || values.length === 0 || !totalQuestion}
            >
              Thêm câu hỏi
            </LoadingButton>
          </Grid>
        </Container>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupAutoAddPaperQuestion;
