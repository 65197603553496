import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Pagination,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { paperService, queryClient } from 'services';
import { PaperQuestionSearch, PopupGroupQuestionView, PopupSingleQuestionView, ViewEditorContent } from '.';
import { useSearch, useWindowSize } from 'hooks';
import { Check, Close } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { formatNumber } from 'utils/common';

type Props = PopupController & {
  paper: TestPaper;
};

const PopupQuestionUpdate = ({ paper, onClose }: Props) => {
  const { isMobile } = useWindowSize();
  const [questions, setQuestions] = useState<TestQuestion[]>([]);
  const [openViewSingleQuestion, setOpenViewSingleQuestion] = useState(false);
  const [openViewGroupQuestion, setOpenViewGroupQuestion] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [itemChoice, setItemChoice] = useState<TestQuestion>();
  const { dataSearch, onSearchChange } = useSearch({ page: page, size: size });

  const { data } = useQuery(
    ['paperService.searchPaperQuestions', dataSearch],
    () => paperService.searchPaperQuestions({ ...dataSearch, paperId: paper.id }),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  useEffect(() => {
    setQuestions(items);
  }, [items]);

  useEffect(() => {
    onSearchChange({ ...dataSearch, paperId: paper.id, page: page, size: size });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  const { mutate: addQuestion, isLoading } = useMutation(paperService.addPaperQuestions, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật câu hỏi thành công');
      queryClient.invalidateQueries(['paperService.searchPaperQuestions']);
      queryClient.invalidateQueries(['paperService.fetchPaperQuestions']);
    },
  });

  const addPaperQuestion = (question: TestQuestion) => {
    addQuestion({
      id: paper.id,
      questions: [
        {
          questionId: question.id,
          groupId: question.groupId,
        },
      ],
    });
  };

  const addPaperQuestions = () => {
    const selectedQuestions = questions
      .filter((x) => x.selected === true)
      .map((x) => ({
        questionId: x.id,
        groupId: x.groupId,
      }));
    addQuestion({
      id: paper.id,
      questions: selectedQuestions,
    });
  };

  return (
    <>
      <DialogTitle>Cập nhật câu hỏi cho bài test {paper.name}</DialogTitle>

      <DialogContent>
        <Container maxWidth='xl' className='mt-1 py-1'>
          <PaperQuestionSearch paperId={paper.id} onChange={onSearchChange} />
          <div className='mt-2'>
            <LoadingButton
              disabled={questions.filter((x) => x.selected === true).length === 0}
              variant='contained'
              color='success'
              loading={isLoading}
              onClick={() => addPaperQuestions()}
            >
              Thêm
            </LoadingButton>
          </div>
        </Container>
        <>
          {items.map((item, index) => {
            return (
              <Container maxWidth='xl' className='mt-4 py-1' key={index}>
                <Grid container spacing={3}>
                  <Grid item md={10} xs={12} className='mt-1'>
                    <Grid container spacing={3}>
                      <Grid item md={1} xs={6} className='pt-4'>
                        {!item.added && (
                          <Checkbox
                            onChange={(e) => {
                              const updatedData = questions.map((x) => {
                                if (x.id === item.id) {
                                  return {
                                    ...x,
                                    selected: e.target.checked,
                                  };
                                }
                                return x;
                              });
                              setQuestions(updatedData);
                            }}
                            color='primary'
                          />
                        )}
                      </Grid>
                      <Grid item md={3} xs={6}>
                        <div>
                          <span>Câu hỏi nhóm: </span>
                          <FormControlLabel
                            className='ml-0'
                            key={1}
                            label=''
                            control={
                              (item.groupId ?? 0) > 0 ? (
                                <Tooltip title='Câu hỏi trong nhóm'>
                                  <Chip label={<Check />} color='success' variant='filled' />
                                </Tooltip>
                              ) : (
                                <Tooltip title='Câu hỏi đơn lẻ'>
                                  <Chip label={<Close />} />
                                </Tooltip>
                              )
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item md={3} xs={6}>
                        {item.parentTopic?.name}
                      </Grid>
                      <Grid item md={3} xs={6}>
                        {item.topic?.name}
                      </Grid>
                      <Grid item md={1} xs={12}>
                        {formatNumber(item.point)} điểm
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={2} xs={12} className='items-end space-x-2'>
                    <Button
                      variant='outlined'
                      color='info'
                      onClick={() => {
                        setItemChoice(item);
                        if (item.group == null) {
                          setOpenViewSingleQuestion(true);
                        } else {
                          setOpenViewGroupQuestion(true);
                        }
                      }}
                    >
                      Chi tiết
                    </Button>
                    {item.added ? (
                      <Tooltip title='Câu hỏi đã được thêm'>
                        <Chip label={<Check />} color='success' variant='filled' />
                      </Tooltip>
                    ) : (
                      <Button variant='outlined' color='primary' onClick={() => addPaperQuestion(item)}>
                        Thêm
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <div className='ml-3'>
                      <ViewEditorContent data={item.content} />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Container>
            );
          })}
          {items.length > 0 && (size ?? 0) > 0 && (page ?? 0) > 0 && (
            <Container maxWidth='xl' className='mt-1'>
              <Grid container spacing={3}>
                <Grid item md={isMobile ? 12 : 6} xs={12}>
                  <Pagination
                    className='mt-3'
                    variant='outlined'
                    shape='rounded'
                    count={(total ?? 0) % size === 0 ? (total ?? 0) / size : Math.floor((total ?? 0) / size) + 1}
                    page={page}
                    onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
                      setPage(value);
                    }}
                  />
                </Grid>
                {!isMobile && (
                  <Grid item md={6} xs={12}>
                    <TablePagination
                      component='div'
                      count={total ?? 0}
                      page={page - 1}
                      onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
                        setPage(newPage);
                      }}
                      rowsPerPage={size}
                      onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        setSize(parseInt(event.target.value, 10));
                        setPage(1);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Container>
          )}
        </>
        {items.length === 0 && <div className='text-center font-bold'>KHÔNG TÌM THẤY CÂU HỎI NÀO</div>}
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>

      {itemChoice && (
        <>
          <Dialog open={openViewSingleQuestion} maxWidth='xl'>
            <PopupSingleQuestionView question={itemChoice!} onClose={() => setOpenViewSingleQuestion(false)} />
          </Dialog>
          <Dialog open={openViewGroupQuestion} maxWidth='xl'>
            <PopupGroupQuestionView question={itemChoice!} onClose={() => setOpenViewGroupQuestion(false)} />
          </Dialog>
        </>
      )}
    </>
  );
};

export default PopupQuestionUpdate;
