import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { classroomService, courseService } from 'services';
import { RegisterShareStatusTypes, RegisterStatusTypes, ZaloAddedStatusTypes } from 'utils/common';

type Props = {
  onChange: (...args: any) => void;
};

const RegisterSearch = ({ onChange }: Props) => {
  const { control, handleSubmit, reset, watch } = useForm<RegisterSearchParams>();
  const courseId = watch('courseId');
  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { data: dataClassroom } = useQuery(['classroomService.fetchClassrooms', { courseId: courseId }], () =>
    classroomService.fetchClassrooms({ courseId: courseId, size: -1 }),
  );
  const handleClickSearch = () => {
    handleSubmit((values) => {
      if (!values.status) values.status = null;
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      courseId: '',
      classroomId: '',
      status: '',
      fromRegisterAt: null,
      toRegisterAt: null,
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Controller
            name='courseId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Khóa học' error={!!error} helperText={error?.message}>
                {(dataCourse?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='classroomId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Lớp học' error={!!error} helperText={error?.message}>
                {(dataClassroom?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='fromRegisterAt'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Thời gian từ',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='toRegisterAt'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Đến ngày',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='code'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Mã đăng ký' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='fullName'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Họ tên' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='email'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Email' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='phone'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Số điện thoại' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='status'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect
                {...field}
                fullWidth
                label='Trạng thái đăng ký'
                error={!!error}
                helperText={error?.message}
              >
                {RegisterStatusTypes.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='sharedVideo'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect
                {...field}
                fullWidth
                label='Trạng thái share video'
                error={!!error}
                helperText={error?.message}
              >
                {RegisterShareStatusTypes.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='zaloAdded'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect
                {...field}
                fullWidth
                label='Trạng thái Add Zalo'
                error={!!error}
                helperText={error?.message}
              >
                {ZaloAddedStatusTypes.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <div className='mt-4 flex space-x-3'>
            <Button onClick={handleClickSearch}>Tìm kiếm</Button>
            <Button variant='outlined' color='inherit' onClick={handleClickClear}>
              <Clear />
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterSearch;
