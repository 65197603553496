import { Box, Button, Chip, Divider, FormControlLabel, Grid, RadioGroup, Tooltip, Typography } from '@mui/material';
import { Check, CheckBoxOutlineBlank, CheckCircle, Close, RadioButtonUnchecked } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ViewEditorContent from './ViewEditorContent';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

type Props = {
  question: TestQuestionStatistic;
  questionIndex: number;
  onClickDetail?: (question: TestQuestionStatistic, index: number) => void;
};

const InfoLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#0288D1',
  },
}));

const SuccessLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#2E7D32',
  },
}));

const ErrorLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#D32F2F',
  },
}));

const DefaultLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#616161',
  },
}));

const QuestionStatisticView = ({ question, questionIndex, onClickDetail }: Props) => {
  return (
    <>
      <Grid container spacing={3} className='mt-1'>
        {question.isFirstQuestionGroup && question.group && (
          <>
            <Grid item xs={12} className='mt-1'>
              <Grid container spacing={3}>
                <Grid item md={2} xs={6}>
                  <Typography variant='h5'>
                    Câu {question.group.fromQuestion} - {question.group.toQuestion}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={6}>
                  <div>
                    <span>Câu hỏi nhóm: </span>
                    <FormControlLabel
                      className='ml-0'
                      key={1}
                      label=''
                      control={
                        <Tooltip title='Câu hỏi trong nhóm'>
                          <Chip label={<Check />} color='success' variant='filled' />
                        </Tooltip>
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <ViewEditorContent data={question.group.name} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider light />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={10} className='mt-1'>
          <Grid container spacing={3}>
            <Grid item md={2} xs={3}>
              <Typography variant='h6'>Câu {questionIndex}</Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <div>
                <span>Câu hỏi nhóm: </span>
                <FormControlLabel
                  className='ml-0'
                  key={1}
                  label=''
                  control={
                    question.group ? (
                      <Tooltip title='Câu hỏi trong nhóm'>
                        <Chip label={<Check />} color='success' variant='filled' />
                      </Tooltip>
                    ) : (
                      <Tooltip title='Câu hỏi đơn lẻ'>
                        <Chip label={<Close />} />
                      </Tooltip>
                    )
                  }
                />
              </div>
            </Grid>
            {onClickDetail && (
              <Grid item md={2} xs={3}>
                <div className='flex justify-end'>
                  <Tooltip title='Chi tiết thống kê câu hỏi'>
                    <Button
                      size='small'
                      color='success'
                      onClick={() => {
                        onClickDetail(question, questionIndex);
                      }}
                    >
                      Chi tiết
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ViewEditorContent data={question.content} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          {question.questionType === 'CHOICE' && !question.isMultipleChoice && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <RadioGroup>
                  {question.answers.map((answer, index) => (
                    <Box
                      border={0.5}
                      borderColor='rgba(0, 0, 0, 0.1)'
                      borderRadius={1}
                      className='mt-2 p-2'
                      key={index}
                    >
                      <FormControlLabel
                        className='mt-2 space-x-2 px-2'
                        label={
                          <>
                            <ViewEditorContent data={answer.content} />
                          </>
                        }
                        control={
                          answer.isCorrect === 1 ? (
                            <CheckCircle color='success' />
                          ) : (
                            <RadioButtonUnchecked color='inherit' />
                          )
                        }
                      />
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                          {answer.isCorrect === 1 ? (
                            <SuccessLinearProgress
                              color='inherit'
                              variant='determinate'
                              value={question.totalAnswer > 0 ? (100 * answer.totalAnswer) / question.totalAnswer : 0}
                            />
                          ) : (
                            <ErrorLinearProgress
                              color='inherit'
                              variant='determinate'
                              value={question.totalAnswer > 0 ? (100 * answer.totalAnswer) / question.totalAnswer : 0}
                            />
                          )}
                        </Box>
                        <Box sx={{ minWidth: 50 }}>
                          <Typography variant='body2' color='text.secondary'>{`${answer.totalAnswer}`}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
          )}
          {question.questionType === 'CHOICE' && question.isMultipleChoice && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {question.answers.map((answer, index) => (
                  <Box border={0.5} borderColor='rgba(0, 0, 0, 0.1)' borderRadius={1} className='mt-2 p-2' key={index}>
                    <FormControlLabel
                      className='mt-2 space-x-2 px-2'
                      label={
                        <>
                          <ViewEditorContent data={answer.content} />
                        </>
                      }
                      control={
                        answer.isCorrect === 1 ? (
                          <CheckBoxIcon color='success' />
                        ) : (
                          <CheckBoxOutlineBlank color='inherit' />
                        )
                      }
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        {answer.isCorrect === 1 ? (
                          <SuccessLinearProgress
                            color='inherit'
                            variant='determinate'
                            value={question.totalAnswer > 0 ? (100 * answer.totalAnswer) / question.totalAnswer : 0}
                          />
                        ) : (
                          <ErrorLinearProgress
                            color='inherit'
                            variant='determinate'
                            value={question.totalAnswer > 0 ? (100 * answer.totalAnswer) / question.totalAnswer : 0}
                          />
                        )}
                      </Box>
                      <Box sx={{ minWidth: 50 }}>
                        <Typography variant='body2' color='text.secondary'>{`${answer.totalAnswer}`}</Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <div className='mt-2'>
            <Typography variant='h6'>Đúng</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <SuccessLinearProgress
                  color='inherit'
                  variant='determinate'
                  value={question.totalAnswer > 0 ? (100 * question.totalCorrect) / question.totalAnswer : 0}
                />
              </Box>
              <Box sx={{ minWidth: 100 }}>
                <Typography variant='body2' color='text.secondary'>{`${question.totalCorrect} (${Math.round(
                  question.totalAnswer > 0 ? (100 * question.totalCorrect) / question.totalAnswer : 0,
                )}%)`}</Typography>
              </Box>
            </Box>
          </div>
          {question.isMultipleChoice && (
            <div className='mt-2'>
              <Typography variant='h6'>Đúng một phần</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <InfoLinearProgress
                    color='inherit'
                    variant='determinate'
                    value={question.totalAnswer > 0 ? (100 * question.totalApartCorrect) / question.totalAnswer : 0}
                  />
                </Box>
                <Box sx={{ minWidth: 100 }}>
                  <Typography variant='body2' color='text.secondary'>{`${question.totalApartCorrect} (${Math.round(
                    question.totalAnswer > 0 ? (100 * question.totalApartCorrect) / question.totalAnswer : 0,
                  )}%)`}</Typography>
                </Box>
              </Box>
            </div>
          )}
          <div className='mt-2'>
            <Typography variant='h6'>Sai</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <ErrorLinearProgress
                  color='inherit'
                  variant='determinate'
                  value={question.totalAnswer > 0 ? (100 * question.totalWrong) / question.totalAnswer : 0}
                />
              </Box>
              <Box sx={{ minWidth: 100 }}>
                <Typography variant='body2' color='text.secondary'>{`${question.totalWrong} (${Math.round(
                  question.totalAnswer > 0 ? (100 * question.totalWrong) / question.totalAnswer : 0,
                )}%)`}</Typography>
              </Box>
            </Box>
          </div>
          <div className='mt-2'>
            <Typography variant='h6'>Chưa làm</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <DefaultLinearProgress
                  color='inherit'
                  variant='determinate'
                  value={question.totalAnswer > 0 ? (100 * question.totalNoAnswer) / question.totalAnswer : 0}
                />
              </Box>
              <Box sx={{ minWidth: 100 }}>
                <Typography variant='body2' color='text.secondary'>{`${question.totalNoAnswer} (${Math.round(
                  question.totalAnswer > 0 ? (100 * question.totalNoAnswer) / question.totalAnswer : 0,
                )}%)`}</Typography>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionStatisticView;
