import { LoadingButton } from '@mui/lab';
import { CardMedia, Chip, Paper, Typography } from '@mui/material';
import { formatDate, formatFullDateTime } from 'utils/common';

type Props = {
  classroom: Classroom;
  onClick: () => void;
};

const CardClassroom = ({ classroom, onClick }: Props) => {
  return (
    <Paper
      variant='outlined'
      className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
    >
      <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
        <CardMedia
          image={classroom.course?.imageUrl}
          className='h-[180px] w-[240px] rounded-lg border bg-contain hover:shadow-md mobile:w-full'
        >
          <div></div>
        </CardMedia>
        <div className='space-y-2'>
          <Typography variant='h4' className='hover:text-primary-main'>
            {classroom.name}
          </Typography>
          <div>
            <span className='text-black/60'>Khai giảng:</span> {formatDate(classroom.startDate)}
          </div>
          <div>
            <span className='text-black/60'>Ngày học:</span> {classroom.studyTime}
          </div>
          <div>
            <span className='text-black/60'>Thời gian học:</span> {classroom.schedule}
          </div>
          <div>
            <span className='text-black/60'>Thời gian bắt đầu tuyển sinh:</span>{' '}
            {formatFullDateTime(classroom.startEnrollmentDate)}
          </div>
        </div>
      </div>
      <div className='flex flex-col items-end justify-between'>
        <div className='space-y-2'>
          <div className='flex justify-end'>
            <Chip
              label={classroom.course?.method}
              color={(classroom.course?.method ?? '').toUpperCase() === 'ONLINE' ? 'primary' : 'secondary'}
            />
          </div>
        </div>
        <div className='mt-6'>
          <LoadingButton variant='contained' color='primary' onClick={onClick} className='px-10'>
            Đăng ký lớp
          </LoadingButton>
        </div>
      </div>
    </Paper>
  );
};

export default CardClassroom;
