import { LoadingButton } from '@mui/lab';
import { Chip, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { queryClient, testQuestionService } from 'services';
import file from 'services/file';
import { download } from 'utils/common';

const PopupImportQuestion = ({ onClose }: PopupController) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileSelected, setFileSelected] = useState<File | null>();
  const [ouput, setOutput] = useState<ImportQuestionsResponse | null>();
  const [isDisplayResponse, setIsDisplayResponse] = useState(false);

  const { mutate: importQuestion, isLoading: isLoadingImport } = useMutation(testQuestionService.importQuestions, {
    onSuccess: (data) => {
      enqueueSnackbar('Import câu hỏi thành công');
      setOutput(data);
      setIsDisplayResponse(true);
      queryClient.invalidateQueries(['testQuestionService.fetchTestQuestions']);
    },
  });

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    setFileSelected(fileList[0]);
  };

  const handleClickSubmit = () => {
    if (fileSelected === null) {
      enqueueSnackbar('Vui lòng chọn file', { variant: 'error' });
      return;
    }

    const formData = new FormData();
    formData.append('file', fileSelected!);
    importQuestion(formData);
  };

  return (
    <>
      <DialogTitle>Import câu hỏi</DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} className='my-6'>
          <Grid item xs={12}>
            <div className='flex justify-between'>
              <input ref={inputRef} type='file' hidden onChange={handleChangeFile} />
              <div className='space-x-3'>
                <LoadingButton variant='outlined' size='small' onClick={() => inputRef.current?.click()}>
                  Chọn file
                </LoadingButton>
                {fileSelected && (
                  <Chip
                    key={fileSelected?.name}
                    variant='outlined'
                    label={fileSelected?.name}
                    onDelete={() => {
                      setFileSelected(null);
                      if (inputRef.current?.value) {
                        inputRef.current.value = '';
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} className='my-6'>
          <Grid item xs={12}>
            <div className='flex items-center justify-items-center space-x-3'>
              <Link
                to='#'
                onClick={() => {
                  file.downloadTemplateFile('questions.xlsx').then((res) => {
                    download(res, 'questions.xlsx', 'application/octet-stream');
                  });
                }}
                className='text-primary-main hover:text-primary-dark'
              >
                Download File mẫu
              </Link>
            </div>
          </Grid>
        </Grid>
        {isDisplayResponse && (
          <Grid container columnSpacing={2} className='my-6'>
            <Grid item md={6}>
              <div className='flex items-center justify-items-center space-x-3'>
                <span>Thành công: </span>
                <span className='font-bold'>{ouput?.totalSuccess}</span>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className='flex items-center justify-items-center space-x-3'>
                <span>Thất bại: </span>
                <span className='font-bold'>{ouput?.totalFailed}</span>
              </div>
            </Grid>
            <Grid item md={12} className='my-6'>
              <div className='flex items-center justify-items-center space-x-3'>
                <Link
                  to='#'
                  onClick={() => {
                    file.downloadFile(ouput?.hashId!).then((res) => {
                      download(res, ouput?.fileName!, 'application/octet-stream');
                    });
                  }}
                  className='text-primary-main hover:text-primary-dark'
                >
                  File kết quả {ouput?.fileName}
                </Link>
              </div>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton
          variant='contained'
          disabled={fileSelected === null}
          loading={isLoadingImport}
          onClick={handleClickSubmit}
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupImportQuestion;
