import { Box } from '@mui/material';
import { AppHeader } from 'containers';
import { useWindowSize } from 'hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { adminRoute, privateRoute, publicRoute } from 'routes';

const PublicLayout = () => {
  const location = useLocation();
  const { isMobile, isDesktop } = useWindowSize();

  const { isLoggedIn, isAdmin } = useSelector(profileSelector);

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setOpenDrawer(false);
    }
  }, [isMobile, location.pathname]);

  useEffect(() => {
    if (isDesktop) {
      const isProfile = Object.values(privateRoute).some((route) => location.pathname.startsWith(route.path));
      if (isProfile) setOpenDrawer(true);
    }
  }, [isDesktop, location.pathname]);

  const canOpenDrawer = isLoggedIn || isMobile;

  return (
    <main>
      <Box
        sx={{
          minHeight: { xs: `calc(100vh - 120px)` },
          marginLeft: canOpenDrawer && openDrawer && isDesktop ? '320px' : '0px',
        }}
      >
        <AppHeader openDrawer={canOpenDrawer ? openDrawer : false} onDrawerChange={setOpenDrawer} />
        <Routes>
          {Object.values(publicRoute).map(({ path, component: Element }) => (
            <Route key={path} path={path} element={<Element />} />
          ))}
          {isLoggedIn &&
            Object.values(privateRoute).map(({ path, component: Element }) => (
              <Route key={path} path={path} element={<Element />} />
            ))}
          {isAdmin &&
            Object.values(adminRoute).map(({ path, component: Element }) => (
              <Route key={path} path={path} element={<Element />} />
            ))}
          <Route path='*' element={<Navigate to={publicRoute.home.path} />} />
        </Routes>
      </Box>
    </main>
  );
};

export default PublicLayout;
