import { DateTime } from 'luxon';

export const formatNumber = (number?: number) => {
  return Number(number ?? 0).toLocaleString('VN-vn');
};

export const formatDate = (isoDate: ISODateString) => {
  return DateTime.fromISO(isoDate).toFormat('dd/MM/yyyy');
};

export const formatDateTime = (isoDate: ISODateString) => {
  return DateTime.fromISO(isoDate).toFormat('HH:mm dd/MM/yyyy');
};

export const formatFullDateTime = (isoDate: ISODateString) => {
  return DateTime.fromISO(isoDate).toFormat('HH:mm:ss dd/MM/yyyy');
};

export const download = (data: BlobPart, fileName: string, contentType: string) => {
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(new Blob([data], { type: contentType }));
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const RegisterStatusTypes = [
  { value: 'WAIT_PAYMENT', label: 'Chờ thanh toán' },
  { value: 'SUCCESS', label: 'Đăng ký thành công' },
  { value: 'LEARNING', label: 'Đang học' },
  { value: 'FINISH', label: 'Hoàn thành' },
  { value: 'RESERVE', label: 'Đang bảo lưu' },
  { value: 'CANCEL', label: 'Huỷ đăng ký' },
];

export const RegisterShareStatusTypes = [
  { value: '1', label: 'Đã share' },
  { value: '0', label: 'Chưa share' },
];

export const StudentStatusTypes = [
  { value: 'WAIT_JOIN', label: 'Chờ tham gia học' },
  { value: 'LEARNING', label: 'Đang học' },
  { value: 'FINISH', label: 'Kết thúc' },
  { value: 'EVALUATED', label: 'Hoàn thành' },
];

export const ClassroomStatusTypes = [
  { value: 'ACTIVE', label: 'Chờ khai giảng' },
  { value: 'LEARNING', label: 'Đang học' },
  { value: 'FINISH', label: 'Kết thúc' },
  { value: 'STOP', label: 'Dừng hoạt động' },
];

export const SurveyStatusTypes = [
  { value: 'NEW', label: 'Chờ duyệt' },
  { value: 'APPROVED', label: 'Đã phê duyệt' },
  { value: 'REJECTED', label: 'Từ chối' },
];

export const PromotionApplyTypes = [
  { value: 'ALL', label: 'Khuyến mãi đăng ký khóa học mới' },
  { value: 'OLD_STUDENT', label: 'Học viên cũ' },
  { value: 'SURVEY', label: 'Tham gia đánh giá khóa học' },
  { value: 'SHARE', label: 'Tham gia chia sẻ kiến thức' },
];

export const ZaloAddedStatusTypes = [
  { value: '1', label: 'Đã Join' },
  { value: '0', label: 'Chưa Join' },
];

export const PostStatusTypes = [
  { value: 'ACTIVE', label: 'Đang hoạt động' },
  { value: 'INACTIVE', label: 'Dừng hoạt động' },
];

export const HomeworkAnswerStatusTypes = [
  { value: 'REVIEWING', label: 'Chờ review lần 1' },
  { value: 'EDITING', label: 'Chờ sửa bài' },
  { value: 'REVIEWING_AGAIN', label: 'Chờ review lại' },
  { value: 'FINISHED', label: 'Hoàn thành' },
];

export const TestQuestionTypeTypes = [
  { value: 'CHOICE', label: 'Trắc nghiệm' },
  { value: 'ESSAY', label: 'Tự luận' },
];

export const TestQuestionLevelTypes = [
  { value: 'EASY', label: 'Dễ' },
  { value: 'MEDIUM', label: 'Trung bình' },
  { value: 'DIFFICULT', label: 'Khó' },
];

export const TestQuestionEssayAnswerTypes = [
  { value: 'CONTAIN', label: 'Contain' },
  { value: 'EQUAL', label: 'Equal' },
  { value: 'IN', label: 'In' },
];

export const TestPaperViewAnswerTypes = [
  { value: 'SUBMIT', label: 'Sau khi nộp bài' },
  { value: 'TOTAL_TIME', label: 'Khi đạt số lượng lượt làm' },
  { value: 'PASS_PERCENT', label: 'Khi học viên pass tỷ lệ (%)' },
];

export const TestPaperResultTrackActionTypes = [
  { value: 'START', label: 'Làm bài' },
  { value: 'RESTART', label: 'Làm bài' },
  { value: 'PAUSE', label: 'Tạm dừng' },
  { value: 'DELETE', label: 'Xóa bài' },
];
