import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useState } from 'react';
import PaperClassroomUpdate from './PaperClassroomUpdate';
import PaperCourseUpdate from './PaperCourseUpdate';
import PaperStudentUpdate from './PaperStudentUpdate';
import PopupAssignEmail from './PopupAssignEmail';

type Props = PopupController & {
  paperIds: number[];
  onlyAssign: boolean;
};

const PopupPaperAssign = ({ paperIds, onlyAssign, onClose }: Props) => {
  const [openAssignEmail, setOpenAssignEmail] = useState(false);
  const [value, setValue] = useState('COURSE');

  return (
    <>
      <DialogTitle>Gán lớp học, lớp học, học viên cho bài test</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item md={9} xs={12}>
            <FormControl>
              <RadioGroup row value={value} onChange={(event) => setValue(event.target.value)}>
                <FormControlLabel value='COURSE' control={<Radio />} label='Theo khóa học' />
                <FormControlLabel value='CLASSROOM' control={<Radio />} label='Theo lớp học' />
                <FormControlLabel value='STUDENT' control={<Radio />} label='Theo học viên' />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <div className='flex justify-end'>
              <Button color='success' onClick={() => setOpenAssignEmail(true)}>
                Gán theo email
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            {value === 'COURSE' ? (
              <PaperCourseUpdate paperIds={paperIds} onlyAssign={onlyAssign} />
            ) : value === 'CLASSROOM' ? (
              <PaperClassroomUpdate paperIds={paperIds} onlyAssign={onlyAssign} />
            ) : (
              <PaperStudentUpdate paperIds={paperIds} onlyAssign={onlyAssign} />
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
      <Dialog maxWidth='md' open={openAssignEmail}>
        <PopupAssignEmail paperIds={paperIds} onClose={() => setOpenAssignEmail(false)} />
      </Dialog>
    </>
  );
};

export default PopupPaperAssign;
