import { Container, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { FormPassword, FormProfile } from './components';

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Container maxWidth='md' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Thông tin tài khoản
      </Typography>

      <Tabs value={activeTab} onChange={(event, value) => setActiveTab(value)}>
        <Tab label='Cập nhật thông tin' />
        <Tab label='Đổi mật khẩu' />
      </Tabs>
      <div className='py-6'>
        {activeTab === 0 && <FormProfile />}
        {activeTab === 1 && <FormPassword />}
      </div>
    </Container>
  );
};

export default Profile;
