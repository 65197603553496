import { Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { formatDateTime, TestPaperResultTrackActionTypes } from 'utils/common';
import { paperService } from 'services';
import { useSearch } from 'hooks';

type Props = {
  paperResult: TestPaperResult;
};

const PaperResultHisFullList = ({ paperResult }: Props) => {
  const { dataSearch, onPaginationChange } = useSearch();
  const { data, isFetching } = useQuery(
    [`paperService.fetchPaperResultFullTracks${paperResult.id}`, dataSearch],
    () =>
      paperService.fetchPaperResultTracks({
        ...dataSearch,
        actionTypes: [],
        id: paperResult.id,
      }),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  return (
    <DataGrid
      loading={isFetching}
      getRowId={(row) => row.id}
      getRowHeight={() => 96}
      rows={items}
      rowCount={total}
      onPaginationModelChange={onPaginationChange}
      columns={[
        {
          field: 'createdAt',
          headerName: 'Thời gian',
          flex: 1,
          minWidth: 160,
          sortable: false,
          renderCell: ({ row }) => (
            <>
              <div>{formatDateTime(row.createdAt)}</div>
            </>
          ),
        },
        {
          field: 'actionType',
          headerName: 'Hành động',
          flex: 1,
          minWidth: 120,
          sortable: false,
          renderCell: ({ row }) => (
            <>
              <div>
                {TestPaperResultTrackActionTypes.find((item) => item.value === row.actionType)?.label ?? 'Làm bài'}
              </div>
            </>
          ),
        },
        {
          field: 'ip',
          headerName: 'IP',
          flex: 1,
          minWidth: 120,
          sortable: false,
          renderCell: ({ row }) => (
            <>
              <div>{row.ip}</div>
            </>
          ),
        },
        {
          field: 'userAgent',
          headerName: 'Thông tin thiết bị',
          flex: 1,
          minWidth: 400,
          sortable: false,
          renderCell: ({ row }) => (
            <Typography sx={{ whiteSpace: 'pre-wrap' }} variant='inherit'>
              {row.userAgent}
            </Typography>
          ),
        },
      ]}
    />
  );
};

export default PaperResultHisFullList;
