import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { quizService, queryClient } from 'services';

type Props = PopupController & {
  question: QuizQuestion;
};

const PopupQuestionLock = ({ question, onClose }: Props) => {
  const { mutate: lockQuestion, isLoading } = useMutation(quizService.putQuizEventQuestionLock, {
    onSuccess: () => {
      enqueueSnackbar('Khóa câu hỏi thành công');
      queryClient.invalidateQueries(['quizService.getQuizEventDetail']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    lockQuestion({
      eventId: question.quizEventId,
      questionId: question.id,
      lock: 1,
    });
  };
  return (
    <>
      <DialogTitle>Xác nhận khóa câu hỏi</DialogTitle>

      <DialogContent>
        <div className='text-center'>Bạn có chắc chắn muốn khóa câu hỏi này không?</div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupQuestionLock;
