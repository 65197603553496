import { Button, CardMedia, Chip, Paper, Typography } from '@mui/material';
import { formatNumber } from 'utils/common';

type Props = {
  item: Course;
  onClick: () => void;
};

const CardCourse = ({ item: course, onClick }: Props) => {
  return (
    <Paper className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'>
      <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
        <CardMedia
          image={course.imageUrl}
          className='h-[180px] w-[240px] rounded-lg border bg-contain hover:shadow-md mobile:w-full'
        >
          <div></div>
        </CardMedia>
        <div className='space-y-2'>
          <Typography variant='h4' className='hover:text-primary-main'>
            {course.name}
          </Typography>
          <div>
            <span className='text-black/60'>Thời lượng:</span> {course.timeInHour} giờ / {course.timeInLesson} buổi
          </div>
          <div>
            <span className='text-black/60'>Học phí:</span> {formatNumber(course.fee)}đ
          </div>
          {course.totalRegisteredStudent > 0 ? (
            <div>
              <span className='text-black/60'>Đã học:</span> {formatNumber(course.totalRegisteredStudent)} học viên
            </div>
          ) : (
            <div></div>
          )}
          {course.totalOpenClassroom > 0 ? (
            <div>
              <span className='text-black/60'>Đang tuyển sinh:</span> {course.totalOpenClassroom} lớp
            </div>
          ) : (
            <div>Chưa mở tuyển sinh</div>
          )}
        </div>
      </div>
      <div className='flex flex-col items-end justify-between'>
        <div className='space-y-2'>
          <div className='flex justify-end'>
            <Chip label={course.method} color={course.method.toUpperCase() === 'ONLINE' ? 'primary' : 'secondary'} />
          </div>
        </div>
        <div className='mt-6'>
          <Button color='success' onClick={onClick} className='px-12'>
            Quan tâm
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default CardCourse;
