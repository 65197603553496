import { ApprovalOutlined, History, InfoOutlined, LowPriority } from '@mui/icons-material';
import { Button, Chip, Container, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { adminRoute } from 'routes';
import { paperService } from 'services';
import { formatDateTime, formatNumber } from 'utils/common';
import { PaperResultSearch, PopupHisPaperResultList, PopupPaperResultApprove, PopupPaperResultHis } from './components';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='text-right font-bold'>{value ?? ''}</span>
    </div>
  );
};

const PaperResultList = () => {
  const [itemChoice, setItemChoice] = useState<TestPaperResult>();
  const [openApprove, setOpenApprove] = useState(false);
  const [openTrack, setOpenTrack] = useState(false);
  const [openHis, setOpenHis] = useState(false);
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    ['paperService.fetchPaperResults', dataSearch],
    () => paperService.fetchPaperResults({ ...dataSearch, isLastTested: 1 }),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách kết quả bài làm học viên
      </Typography>

      <PaperResultSearch onChange={onSearchChange} />
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 192}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'user.Email',
            headerName: 'Học viên',
            flex: 1,
            minWidth: 280,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.user?.fullName}
                <br />
                {row.user?.email}
                <br />
                {row.user?.phone}
                <br />
                {row.classroom?.code}
                <br />
                {row.course?.name}
              </div>
            ),
          },
          {
            field: 'paper.name',
            headerName: 'Bài test',
            minWidth: 300,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                <Typography variant='inherit' sx={{ whiteSpace: 'pre-wrap' }}>
                  <span>{row.paper?.name}</span>
                </Typography>
                <br />
                <div>
                  {row.testStatus === 1 ? (
                    row.approvedStatus === 1 ? (
                      <Chip label='Đã duyệt điểm' color='success' />
                    ) : (
                      <Chip label='Chưa duyệt điểm' color='error' />
                    )
                  ) : (
                    <Chip label='Đang thi' color='warning' />
                  )}
                </div>
              </div>
            ),
          },
          {
            field: 'point',
            headerName: 'Điểm',
            minWidth: 180,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                <GridInfo label='Điểm' value={formatNumber(row.point) + '/' + formatNumber(row.maxPoint)} />
                <br />
                <GridInfo label='Đúng' value={`${formatNumber(row.percentCorrect)} %`} />
                <br />
                <GridInfo label='Xếp loại' value={row.assessmentType?.name} />
              </div>
            ),
          },
          {
            field: 'statistic',
            headerName: 'Thống kê',
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) =>
              row.testStatus === 1 && (
                <div>
                  <GridInfo label='Đúng' value={formatNumber(row.totalCorrect)} />
                  <br />
                  <GridInfo label='Đúng 1 phần' value={formatNumber(row.totalApartCorrect)} />
                  <br />
                  <GridInfo label='Sai' value={formatNumber(row.totalWrong)} />
                  <br />
                  <GridInfo label='Chưa làm' value={formatNumber(row.totalNoAnswer)} />
                </div>
              ),
          },
          {
            field: 'createdAt',
            headerName: 'Thời gian làm/duyệt điểm',
            minWidth: 260,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                <GridInfo label='Làm bài' value={formatDateTime(row.createdAt)} />
                <br />
                <GridInfo label='Duyệt điểm' value={row.approvedAt ? formatDateTime(row.approvedAt) : ''} />
              </div>
            ),
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 200,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Link to={adminRoute.paperResultView.url({ id: row.id })}>
                  <Button size='small' color='info' variant='outlined'>
                    <InfoOutlined />
                  </Button>
                </Link>
                <Tooltip title='Danh sách các lượt làm bài'>
                  <Button
                    size='small'
                    color='secondary'
                    onClick={() => {
                      setOpenHis(true);
                      setItemChoice(row);
                    }}
                  >
                    <LowPriority />
                  </Button>
                </Tooltip>
                <Tooltip title='Thông tin làm bài'>
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      setOpenTrack(true);
                      setItemChoice(row);
                    }}
                  >
                    <History />
                  </Button>
                </Tooltip>
                {row.testStatus === 1 && row?.paper?.needApproveResult === 1 && row.approvedStatus !== 1 && (
                  <Tooltip title='Duyệt điểm'>
                    <Button
                      size='small'
                      color='success'
                      onClick={() => {
                        setOpenApprove(true);
                        setItemChoice(row);
                      }}
                    >
                      <ApprovalOutlined />
                    </Button>
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]}
      />

      <Dialog open={openApprove}>
        <PopupPaperResultApprove paperResult={itemChoice!} onClose={() => setOpenApprove(false)} />
      </Dialog>
      <Dialog maxWidth='md' open={openTrack}>
        <PopupPaperResultHis paperResult={itemChoice!} onClose={() => setOpenTrack(false)} />
      </Dialog>
      <Dialog maxWidth='md' open={openHis}>
        <PopupHisPaperResultList paperResult={itemChoice!} onClose={() => setOpenHis(false)} />
      </Dialog>
    </Container>
  );
};

export default PaperResultList;
