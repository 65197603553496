import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { QRCode } from 'components';
import { registerService } from 'services';
import { formatDateTime, formatNumber } from 'utils/common';

type Props = PopupController & {
  item: Register;
};

const PopupRegisterQR = ({ item, onClose }: Props) => {
  const { data, isSuccess } = useQuery(['registerService.getRegisterQr', item.id], () =>
    registerService.getRegisterQr({ id: item.id }),
  );

  if (!isSuccess) return <></>;
  return (
    <>
      <DialogTitle>Thông tin thanh toán</DialogTitle>

      <DialogContent className='flex flex-col items-center space-y-3'>
        <div>Vui lòng thanh toán học phí trước {formatDateTime(data?.deadline)}</div>
        <QRCode value={data.qrCode} className='h-[160px] w-[160px]' />
        <div className='text-4xl font-bold'>{formatNumber(data.fee)}đ</div>
        <div className='min-w-[50%]'>
          <div>
            Chủ tài khoản: <b>{data.bank.accountHolder}</b>
          </div>
          <div>
            STK: <b>{data.bank.accountNumber}</b>
          </div>
          <div>Ngân hàng: {data.bank.bankCode}</div>
          <div>Nội dung: {data.content}</div>
        </div>
        <div className='italic'>
          Chúng tôi sẽ kiểm tra và xác nhận thanh toán trong vòng 24h kể từ khi bạn thanh toán
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Đóng
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupRegisterQR;
