import { Check, Delete } from '@mui/icons-material';
import { Button, Chip, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { enqueueSnackbar } from 'notistack';
import { paperService, queryClient } from 'services';
import { PaperStudentSearch } from '.';

type Props = {
  paperIds: number[];
  onlyAssign: boolean;
};

const PaperStudentUpdate = ({ paperIds, onlyAssign }: Props) => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch({ paperIds: paperIds, type: 'STUDENT' });
  const { data, isFetching } = useQuery(
    ['paperService.fetchPaperAssignsByStudent', dataSearch],
    () => paperService.fetchPaperAssigns(dataSearch),
    {
      keepPreviousData: true,
    },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const { mutate: addPaperAssign } = useMutation(paperService.addPaperAssign, {
    onSuccess: () => {
      enqueueSnackbar('Gán bài test cho học viên thành công');
      queryClient.invalidateQueries(['paperService.fetchPaperAssignsByStudent']);
    },
  });

  const { mutate: removePaperAssign } = useMutation(paperService.removePaperAssign, {
    onSuccess: () => {
      enqueueSnackbar('Hủy gán bài test cho học viên thành công');
      queryClient.invalidateQueries(['paperService.fetchPaperAssignsByStudent']);
    },
  });

  const remove = (item: TestPaperAssign) => {
    removePaperAssign({
      id: item.id,
      paperId: item.paperId,
    });
  };

  const add = (userId: number) => {
    addPaperAssign({
      userIds: [userId],
      paperIds: paperIds,
    });
  };

  return (
    <>
      <PaperStudentSearch paperIds={paperIds} onChange={onSearchChange} />
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.studentId}
        getRowHeight={() => 96}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'assigned',
            headerName: 'Đã giao',
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.assigned && (
                  <Tooltip title='Đã gán bài test cho học viên'>
                    <Chip label={<Check />} color='success' variant='filled' />
                  </Tooltip>
                )}
              </div>
            ),
          },
          {
            field: 'name',
            headerName: 'Tên lớp học',
            flex: 1,
            minWidth: 250,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.classroom?.code} <br />
                {row.course?.name}
              </div>
            ),
          },
          {
            field: 'user',
            headerName: 'Học viên',
            flex: 1,
            minWidth: 300,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.user?.fullName}
                <br />
                {row.user?.email}
                <br />
                {row.user?.phone}
              </div>
            ),
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 130,
            renderCell: ({ row }) => (
              <div className='flex items-center space-x-3'>
                {!onlyAssign && row.assigned && (
                  <Tooltip title='Hủy giao bài test'>
                    <Button
                      size='small'
                      color='error'
                      onClick={() => {
                        remove(row);
                      }}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                )}
                {(onlyAssign || !row.assigned) && (
                  <Tooltip title='Giao bài test'>
                    <Button
                      size='small'
                      color='success'
                      onClick={() => {
                        add(row.userId!);
                      }}
                    >
                      <Check />
                    </Button>
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

export default PaperStudentUpdate;
