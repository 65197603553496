import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { classroomService, courseService } from 'services';
import { SurveyStatusTypes } from 'utils/common';

type Props = {
  onChange: (...args: any) => void;
};

const SurveySearch = ({ onChange }: Props) => {
  const { control, handleSubmit, reset } = useForm<SurveySearchParams>();

  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { data: dataClassroom } = useQuery(['classroomService.fetchClassrooms'], () =>
    classroomService.fetchClassrooms({}),
  );

  const handleClickSearch = () => {
    handleSubmit((values) => {
      if (!values.status) values.status = null;
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      courseId: '',
      classroomId: '',
      status: '',
      fromCreatedAt: null,
      toCreatedAt: null,
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Controller
            name='courseId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Khóa học' error={!!error} helperText={error?.message}>
                {(dataCourse?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='classroomId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Lớp học' error={!!error} helperText={error?.message}>
                {(dataClassroom?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='email'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Email' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='phone'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Số điện thoại' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <Controller
            name='fromCreatedAt'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Đăng ký từ ngày',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='toCreatedAt'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Đến ngày',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='status'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Trạng thái' error={!!error} helperText={error?.message}>
                {SurveyStatusTypes.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
      </Grid>

      <div className='mt-6 flex justify-end space-x-3'>
        <Button onClick={handleClickSearch}>Tìm kiếm</Button>
        <Button variant='outlined' color='inherit' onClick={handleClickClear}>
          <Clear />
        </Button>
      </div>
    </>
  );
};

export default SurveySearch;
