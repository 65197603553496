import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { homeworkService, queryClient } from 'services';

type Props = PopupController & {
  homework: MyHomework;
};

const PopupHomeworkSubmit = ({ homework, onClose }: Props) => {
  const { control, handleSubmit } = useForm<HomeworkSubmitBody>();

  const { mutate: submitHomework, isLoading } = useMutation(homeworkService.postHomeworkSubmit, {
    onSuccess: () => {
      enqueueSnackbar('Nộp bài tập thành công');
      queryClient.invalidateQueries(['homeworkService.fetchMyHomework']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      submitHomework({
        ...values,
        homeworkId: homework.id,
        classroomId: homework.classroomId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Nộp bài tập</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField fullWidth label='BTVN' inputProps={{ readOnly: true }} defaultValue={homework.name} />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='link'
              defaultValue=''
              control={control}
              rules={{
                required: 'Link bài tập là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={2}
                  required
                  label='Link bài tập'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='note'
              defaultValue=''
              control={control}
              render={({ field }) => (
                <TextField {...field} multiline minRows={2} fullWidth label='Ghi chú' placeholder='Nội dung' />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Nộp bài
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupHomeworkSubmit;
