import { Clear } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { categoryService } from 'services';

const Mail = () => {
  const { control, handleSubmit, reset } = useForm<SendEmailsBody>();
  const { mutate: sendEmail, isLoading } = useMutation(categoryService.sendEmails, {
    onSuccess: () => {
      enqueueSnackbar('Gửi Email thành công');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      sendEmail(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      title: '',
      emails: '',
      content: '',
    });
  };

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Gửi Email
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name='emails'
            defaultValue=''
            control={control}
            rules={{
              required: 'Danh sách email là bắt buộc',
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                multiline
                minRows={4}
                fullWidth
                required
                label='Danh sách email cần gửi'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='title'
            defaultValue=''
            control={control}
            rules={{
              required: 'Tiêu đề là bắt buộc',
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth required label='Tiêu đề' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='content'
            defaultValue=''
            control={control}
            rules={{
              required: 'Nội dung email là bắt buộc',
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                multiline
                minRows={4}
                fullWidth
                required
                label='Nội dung email'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
      <div className='mt-6 flex justify-end space-x-3'>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Gửi email
        </LoadingButton>
        <Button variant='outlined' color='inherit' onClick={handleClickClear}>
          <Clear />
        </Button>
      </div>
    </Container>
  );
};

export default Mail;
