import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { queryClient, authService } from 'services';

type Props = PopupController & {
  id: number;
};

const PopupResetPassword = ({ id, onClose }: Props) => {
  const [newPassword, setNewPassword] = useState('');
  const [copied, setCopied] = useState(false);
  const { mutate: resetPassword, isLoading } = useMutation(authService.resetRandomPassword, {
    onSuccess: ({ data }) => {
      enqueueSnackbar('Reset mật khẩu thành công thành công');
      queryClient.invalidateQueries(['authService.fetchUsers']);
      setNewPassword(data);
    },
  });

  const handleClickSubmit = () => {
    resetPassword({
      id: id,
    });
  };
  return (
    <>
      {!newPassword ? <DialogTitle>Xác nhận reset mật khẩu</DialogTitle> : <DialogTitle>Mật khẩu mới</DialogTitle>}

      <DialogContent>
        {!newPassword ? (
          <div className='text-center'>Bạn có chắc chắn muốn reset mật khẩu cho tài khoản này không?</div>
        ) : (
          <TextField
            type='text'
            value={newPassword}
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <Button
                    color='success'
                    onClick={() => {
                      navigator.clipboard.writeText(newPassword).then(() => {
                        setCopied(true);
                      });
                    }}
                    startIcon={copied ? <Check /> : null}
                  >
                    Copy
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupResetPassword;
