import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { nanoid } from 'nanoid';

export const quizSlice = createSlice({
  name: 'quizzes',
  initialState: {
    quizSessionId: '',
  } as QuizSessionType,
  reducers: {
    initSession: (state) => {
      const quiz = { ...state };
      if (!quiz.quizSessionId) {
        return {
          quizSessionId: nanoid(30),
        };
      }
      return quiz;
    },
  },
});

export const { initSession } = quizSlice.actions;

export const quizSelector = ({ quizzes }: RootState) => quizzes;
