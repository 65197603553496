import { Check, Close } from '@mui/icons-material';
import { Button, Chip, Container, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { surveyService } from 'services';
import { ClassroomStatusTypes, SurveyStatusTypes, formatDateTime } from 'utils/common';
import { PopupSurveyApprove, PopupSurveyCancel, SurveySearch, TruncatedTextView } from './components';

const SurveyList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    ['surveyService.fetchSurveys', dataSearch],
    () => surveyService.fetchSurveys(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const [openUpdateApprove, setOpenUpdateApprove] = useState(false);
  const [openUpdateCancel, setOpenUpdateCancel] = useState(false);
  const [itemChoice, setItemChoice] = useState<Survey>();

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách đánh giá
      </Typography>

      <SurveySearch onChange={onSearchChange} />
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 96}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'user.fullName',
            headerName: 'Học viên',
            flex: 1,
            minWidth: 310,
            sortable: false,
            renderCell: ({ row }) => (
              <>
                <div>
                  {row.user.fullName}
                  <br />
                  {row.user.email}
                  <br />
                  {row.user.phone}
                </div>
              </>
            ),
          },
          {
            field: 'classroom.code',
            headerName: 'Lớp học',
            flex: 1,
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => (
              <>
                <div>
                  <span className='font-bold'>{row.classroom?.code}</span>
                  <br />
                  {row.course?.name}
                  <br />
                  <Chip
                    label={ClassroomStatusTypes.find((item) => item.value === row.classroom?.status)?.label}
                    color='secondary'
                  />
                </div>
              </>
            ),
          },
          {
            field: 'status',
            headerName: 'Trạng thái',
            minWidth: 135,
            sortable: false,
            renderCell: ({ row }) => <div>{SurveyStatusTypes.find((item) => item.value === row.status)?.label}</div>,
          },
          {
            field: 'rate',
            headerName: 'Rate',
            minWidth: 60,
            sortable: false,
            renderCell: ({ row }) => <div className='font-bold'>{row.rate}</div>,
          },
          {
            field: 'note',
            headerName: 'Đánh giá',
            minWidth: 230,
            sortable: false,
            renderCell: ({ row }) => <TruncatedTextView text={row.note} />,
          },
          {
            field: 'createdAt',
            headerName: 'Ngày đánh giá',
            flex: 1,
            minWidth: 150,
            sortable: false,
            renderCell: ({ row }) => <div>{formatDateTime(row.createdAt)}</div>,
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 125,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                {row.status === 'NEW' && (
                  <Tooltip title='Phê duyệt đánh giá'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenUpdateApprove(true);
                        setItemChoice(row);
                      }}
                    >
                      <Check />
                    </Button>
                  </Tooltip>
                )}
                {row.status === 'NEW' && (
                  <Tooltip title='Từ chối đánh giá'>
                    <Button
                      size='small'
                      color='error'
                      onClick={() => {
                        setOpenUpdateCancel(true);
                        setItemChoice(row);
                      }}
                    >
                      <Close />
                    </Button>
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]}
      />
      <Dialog open={openUpdateApprove}>
        <PopupSurveyApprove item={itemChoice!} onClose={() => setOpenUpdateApprove(false)} />
      </Dialog>
      <Dialog open={openUpdateCancel}>
        <PopupSurveyCancel item={itemChoice!} onClose={() => setOpenUpdateCancel(false)} />
      </Dialog>
    </Container>
  );
};

export default SurveyList;
