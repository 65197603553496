import { client } from './axios';

const fetchComments = (body: CommentSearchParams): Promise<PaginateResponse<PostComment>> =>
  client.get(`/api/v1/comments?${new URLSearchParams(JSON.parse(JSON.stringify(body))).toString()}`);
const fetchCommentsByPostUUID = ({ uuid }: ByUUID): Promise<PostComment> => client.get(`/api/v1/comments/${uuid}`);
const fetchListComments = (body: ListCommentSearchParams): Promise<PaginateResponse<PostComment>> =>
  client.post(`/api/v1/comments/list`, body);
const addComment = (body: CommentCreateBody): Promise<ByID> => client.post(`/api/v1/comments`, body);
const deleteComment = ({ id }: ByID): Promise<SuccessResponse> => client.delete(`/api/v1/comments/${id}`);

const commentService = {
  fetchComments,
  fetchCommentsByPostUUID,
  fetchListComments,
  addComment,
  deleteComment,
};

export default commentService;
