import { Button, Dialog, FormControlLabel, Grid, Paper, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import ViewEditorContent from './ViewEditorContent';
import { useState } from 'react';
import PopupPaperResultMark from './PopupPaperResultMark';

type Props = {
  paperResult: TestPaperResult;
  questionIndex: number;
};

const CardUserQuestionAnswer = ({ paperResult, questionIndex }: Props) => {
  const [openMark, setOpenMark] = useState(false);
  return (
    <>
      <Paper
        variant='outlined'
        className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
      >
        <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
          <div className='space-y-2'>
            <Typography variant='h5' className='hover:text-primary-main'>
              {paperResult?.user?.fullName}
            </Typography>

            <div>
              {(paperResult?.answers ?? []).map((answer, index) => (
                <div key={`question${index}`}>
                  <Grid container spacing={3}>
                    {answer.questionType === 'CHOICE' ? (
                      <Grid item xs={12} className='mt-1'>
                        {answer.questionAnswers!.map(
                          (a, index) =>
                            a.isSelected && (
                              <div key={`answer${index}`}>
                                <FormControlLabel
                                  className='mb-2 space-x-2 px-2 py-1'
                                  label={<ViewEditorContent data={a.content} />}
                                  control={
                                    <>
                                      {answer.isCorrect === 2 ? (
                                        <Check color='success' />
                                      ) : answer.isCorrect === 1 ? (
                                        <Check color='warning' />
                                      ) : (
                                        <Close color='error' />
                                      )}
                                    </>
                                  }
                                />
                              </div>
                            ),
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} className='mt-1'>
                        <Grid container spacing={3} className='mt-1'>
                          <Grid item xs={12}>
                            <Typography variant='h5'>Nội dung trả lời</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <ViewEditorContent data={answer.answerContent} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {answer.questionType === 'ESSAY' && (
                    <>
                      <Button
                        className='whitespace-nowrap'
                        size='medium'
                        color='success'
                        title='Chấm điểm cho câu hỏi tự luận'
                        onClick={() => setOpenMark(true)}
                      >
                        Chấm bài
                      </Button>
                      <Dialog maxWidth='md' open={openMark} key={index}>
                        <PopupPaperResultMark
                          paperResult={paperResult}
                          questionIndex={questionIndex}
                          answer={answer}
                          onClose={() => setOpenMark(false)}
                        />
                      </Dialog>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default CardUserQuestionAnswer;
