import { LoadingButton } from '@mui/lab';
import { Checkbox, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { homeworkService, queryClient } from 'services';

type Props = PopupController & {
  classroom: Classroom;
};

const PopupClassroomHomework = ({ classroom, onClose }: Props) => {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [homeworks, setHomeworks] = useState<Homework[]>([]);

  const { data, isFetching } = useQuery(
    ['homeworkService.fetchHomeworks'],
    () => homeworkService.fetchHomeworks({ courseId: classroom.courseId, size: -1 }),
    {
      keepPreviousData: true,
    },
  );
  const { list: items = [] } = data ?? {};

  useEffect(() => {
    homeworkService.fetchClassroomHomeworks({ classroomId: classroom.id }).then((res) => {
      setRowSelectionModel(res.list.filter((r) => r.selected).map((r) => r.id));
      setHomeworks(res.list);
    });
  }, [classroom.id]);

  const { mutate: postHomeworkClassroomAssign, isLoading } = useMutation(homeworkService.postHomeworkClassroomAssign, {
    onSuccess: () => {
      enqueueSnackbar('Gán bài tập cho lớp thành công');
      queryClient.invalidateQueries(['classroomService.fetchClassrooms']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    const selectedRowData = new Set(rowSelectionModel);
    const selecteds = items
      .filter((e) => selectedRowData.has(e.id))
      .map((e) => ({
        homeworkId: e.id,
        showAnswer: homeworks.find((h) => h.id === e.id)?.showAnswer ?? false,
      }));
    postHomeworkClassroomAssign({
      classroomId: classroom.id,
      homeworks: selecteds,
    });
  };

  return (
    <>
      <DialogTitle>Gán bài tập cho lớp {classroom.name}</DialogTitle>

      <DialogContent>
        <DataGrid
          loading={isFetching}
          getRowId={(row) => row.id}
          getRowHeight={() => 64}
          rows={items}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          disableRowSelectionOnClick
          rowSelectionModel={rowSelectionModel}
          paginationMode='client'
          columns={[
            {
              field: 'showAnswer',
              headerName: 'Show đáp án',
              flex: 1,
              align: 'center',
              maxWidth: 150,
              sortable: false,
              renderCell: ({ row }) => (
                <div>
                  <Checkbox
                    checked={homeworks.find((e) => e.id === row.id)?.showAnswer ?? false}
                    onChange={(e) => {
                      const updatedData = homeworks.map((x) => {
                        if (x.id === row.id) {
                          return {
                            ...x,
                            showAnswer: e.target.checked,
                          };
                        }
                        return x;
                      });
                      setHomeworks(updatedData);
                    }}
                    color='primary'
                  />
                </div>
              ),
            },
            {
              field: 'name',
              headerName: 'Tên bài tập',
              flex: 1,
              minWidth: 280,
              sortable: false,
              renderCell: ({ row }) => <div>{row.name}</div>,
            },
            {
              field: 'content',
              headerName: 'Nội dung bài tập',
              flex: 1,
              minWidth: 400,
              sortable: false,
              renderCell: ({ row }) => <div>{row.content}</div>,
            },
          ]}
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupClassroomHomework;
