import { Paper } from '@mui/material';

type Props = {
  course: Course;
};

const TabCourseTarget = ({ course }: Props) => {
  return (
    <Paper className='mx-auto max-w-3xl bg-[orange]/10 p-4'>
      <div className='flex space-x-10'>
        <span className='text-black/60'>Đối tượng phù hợp:</span>
        {course.suitableObjectList.length === 0 && (
          <div className='space-y-2'>
            <li>Có tư duy logic tốt</li>
            <li>Có thời gian học và nghiền ngẫm video, tài liệu học</li>
            <li>Nếu đã đi làm thì ở công việc hiện tại cũng được đánh giá tốt</li>
          </div>
        )}
        {course.suitableObjectList.length > 0 && (
          <div className='space-y-2'>
            {course.suitableObjectList.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </div>
        )}
      </div>
    </Paper>
  );
};

export default TabCourseTarget;
