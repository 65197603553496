import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient, testQuestionService } from 'services';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import ContentQuestionView from './ContentQuestionView';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';

type Props = PopupController & {
  question: TestQuestion;
  onAdd?: (group: TestQuestionGroup, parentTopicId?: number, topicId?: number) => void;
};

const PopupGroupQuestionView = ({ question, onAdd, onClose }: Props) => {
  const [curQuestion, setCurQuestion] = useState<TestQuestion | undefined>();
  const [questionId, setQuestionId] = useState(0);
  const { data } = useQuery(
    ['testQuestionService.fetchQuestionsByGroup'],
    () => testQuestionService.fetchTestQuestions({ groupId: question.groupId, size: -1 }),
    { keepPreviousData: true },
  );
  const { list: questions = [] } = data ?? {};

  useEffect(() => {
    if (questionId) {
      setCurQuestion(questions.find((e) => e.id === questionId));
    } else {
      setCurQuestion(questions[0]);
    }
  }, [questions, questionId]);

  const { mutate: moveUp } = useMutation(testQuestionService.postTestQuestionMoveUp, {
    onSuccess: () => {
      enqueueSnackbar('Di chuyển câu hỏi thành công');
      queryClient.invalidateQueries(['testQuestionService.fetchQuestionsByGroup']);
    },
  });

  const { mutate: moveDown } = useMutation(testQuestionService.postTestQuestionMoveDown, {
    onSuccess: () => {
      enqueueSnackbar('Di chuyển câu hỏi thành công');
      queryClient.invalidateQueries(['testQuestionService.fetchQuestionsByGroup']);
    },
  });

  return (
    <>
      <DialogTitle>Danh sách câu hỏi trong nhóm</DialogTitle>
      <DialogContent>
        <Container maxWidth='xl' className='py-6'>
          <Grid container spacing={3}>
            <Grid item xs={12} md={2} className='mt-1'>
              {onAdd && (
                <Button
                  size='small'
                  color='primary'
                  onClick={() => {
                    onClose();
                    onAdd!(question.group!, question.parentTopicId, question.topicId);
                  }}
                >
                  Thêm câu hỏi
                </Button>
              )}

              <List>
                {questions.map((question, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      sx={{
                        height: '2rem',
                        border: 0,
                        '&.Mui-selected': {
                          backgroundColor: '#2e8b57',
                          color: 'white',
                        },
                        '&.Mui-focusVisible': {
                          backgroundColor: '#eceff1',
                          color: 'white',
                        },
                        ':hover': {
                          backgroundColor: '#90caf9',
                          color: 'white',
                        },
                      }}
                      onClick={() => setQuestionId(question.id)}
                      selected={question.id === curQuestion?.id}
                    >
                      <ListItemText primary={'Câu ' + (index + 1)} />
                    </ListItemButton>
                    <div className='items-end'>
                      {index > 0 && (
                        <IconButton
                          edge='end'
                          aria-label='Chuyển câu hỏi lên'
                          title='Chuyển câu hỏi lên'
                          color='info'
                          onClick={() => {
                            moveUp({ id: question.id });
                          }}
                        >
                          <ArrowUpward />
                        </IconButton>
                      )}
                      {index < questions.length - 1 && (
                        <IconButton
                          edge='end'
                          aria-label='Chuyển câu hỏi lên'
                          title='Chuyển câu hỏi xuống'
                          color='primary'
                          onClick={() => {
                            moveDown({ id: question.id });
                          }}
                        >
                          <ArrowDownward />
                        </IconButton>
                      )}
                    </div>
                  </ListItem>
                ))}
              </List>
            </Grid>
            {curQuestion && (
              <Grid item xs={12} md={10} className='mt-1'>
                <ContentQuestionView question={curQuestion!} />
              </Grid>
            )}
          </Grid>
        </Container>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupGroupQuestionView;
