import { Checkbox, Container, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { quizService } from 'services';
import { LoadingButton } from '@mui/lab';

type Props = {
  question: QuizQuestionPlay;
  sessionId: string;
};

const QuestionPlayView = ({ question, sessionId }: Props) => {
  const { control, handleSubmit } = useForm<QuizQuestionResultUpsertBody>({
    defaultValues: {
      answers: question.answers.map(({ id, content, image, isSelected }) => ({
        id,
        content,
        image,
        isSelected,
      })),
    },
  });
  useFieldArray({
    control,
    name: 'answers',
  });

  const { mutate: addQuestionResult, isLoading } = useMutation(quizService.postQuizEventQuestionResult, {
    onSuccess: () => {
      enqueueSnackbar('Gửi đáp án thành công');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      addQuestionResult({
        ...values,
        quizQuestionPlayId: question.quizQuestionPlayId,
        eventId: question.quizEventId,
        questionId: question.id,
        sessionId: sessionId,
      });
    })();
  };

  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h4' className='space-x-3' sx={{ whiteSpace: 'pre-wrap' }}>
              <span>{question.content}</span>
            </Typography>
          </Grid>
          {question.image && (
            <Grid item xs={12}>
              <img src={`${question.image}?w=164&h=164&fit=crop&auto=format`} loading='lazy' />
            </Grid>
          )}
        </Grid>
        <Divider light className='mb-2 mt-2' />
        {!question.isMultipleChoice && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name='quizQuestionAnswerId'
                defaultValue={question.quizQuestionAnswerId}
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <RadioGroup {...field}>
                    {question.answers.map((answer, index) => (
                      <div key={index}>
                        <FormControlLabel
                          className='mb-2'
                          value={answer.id}
                          label={
                            <Typography sx={{ fontSize: 18, whiteSpace: 'pre-wrap' }}>{answer.content}</Typography>
                          }
                          control={<Radio color='primary' />}
                        />
                        {answer.image && (
                          <Grid item xs={12} className='mb-2'>
                            <img src={`${answer.image}?w=164&h=164&fit=crop&auto=format`} loading='lazy' />
                          </Grid>
                        )}
                      </div>
                    ))}
                  </RadioGroup>
                )}
              />
            </Grid>
          </Grid>
        )}
        {question.isMultipleChoice &&
          question.answers.map((answer, index) => (
            <Grid container spacing={3}>
              <Grid item xs={12} className='mt-1'>
                <Controller
                  name={`answers.${index}.isSelected`}
                  defaultValue={answer.isSelected}
                  control={control}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <FormControlLabel
                      label={<Typography sx={{ fontSize: 18 }}>{answer.content}</Typography>}
                      control={<Checkbox {...field} color='primary' checked={field.value} />}
                    />
                  )}
                />
              </Grid>
              {answer.image && (
                <Grid item xs={12}>
                  <img src={`${answer.image}?w=164&h=164&fit=crop&auto=format`} loading='lazy' />
                </Grid>
              )}
            </Grid>
          ))}
        {question.isLocked !== 1 && (
          <div className='mt-2 flex justify-center'>
            <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
              Gửi
            </LoadingButton>
          </div>
        )}
      </Container>
    </>
  );
};

export default QuestionPlayView;
