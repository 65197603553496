import { client } from './axios';

const fetchClassroomsPublic = (params: ClassroomSearchParams): Promise<PaginateResponse<Classroom>> =>
  client.get(`/api/v1/classrooms`, { params });
const fetchClassrooms = (body: ClassroomSearchParams): Promise<PaginateResponse<Classroom>> =>
  client.post(`/api/v1/classrooms/list`, body);
const getClassroomInfo = ({ id }: ByID): Promise<Classroom> => client.get(`/api/v1/classrooms/${id}`);
const addClassroom = (body: ClassroomCreateBody): Promise<ByID> => client.post(`/api/v1/classrooms`, body);
const updateClassroom = ({ id, ...body }: ClassroomUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/classrooms/${id}`, body);

const putClassroomStart = ({ id }: ByID) => client.put(`/api/v1/classrooms/${id}/start`);
const putClassroomStop = ({ id }: ByID) => client.put(`/api/v1/classrooms/${id}/stop`);
const putClassroomFinish = ({ id }: ByID) => client.put(`/api/v1/classrooms/${id}/finish`);
const postClassroomEmailNotify = ({ id }: ByID) => client.post(`/api/v1/classrooms/${id}/notify`);

const fetchClassroomDocs = ({ id }: ByID): Promise<PaginateResponse<ClassroomDoc>> =>
  client.get(`/api/v1/classrooms/${id}/docs`);

const addClassroomDoc = ({ id, ...body }: ByID & DocumentCreateBody): Promise<ByID> =>
  client.post(`/api/v1/classrooms/${id}/docs`, body);
const updateClassroomDoc = ({ id, ...body }: ByID & DocumentCreateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/classrooms/docs/${id}`, body);
const deleteClassroomDoc = ({ id }: ByID): Promise<SuccessResponse> => client.delete(`/api/v1/classrooms/docs/${id}`);

const updateClassroomGoogleMeet = ({ id, ...body }: ByID & LinkUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/classrooms/${id}/google-meet`, body);
const updateClassroomZalo = ({ id, ...body }: ByID & LinkUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/classrooms/${id}/zalo`, body);
const updateClassroomZaloFreeChat = ({ id, ...body }: ByID & LinkUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/classrooms/${id}/zalo-free-chat`, body);

const classroomService = {
  fetchClassroomsPublic,
  fetchClassrooms,
  getClassroomInfo,
  addClassroom,
  updateClassroom,

  putClassroomStart,
  putClassroomStop,
  putClassroomFinish,
  postClassroomEmailNotify,

  fetchClassroomDocs,
  addClassroomDoc,
  updateClassroomDoc,
  deleteClassroomDoc,

  updateClassroomGoogleMeet,
  updateClassroomZalo,
  updateClassroomZaloFreeChat,
};

export default classroomService;
