import { client } from './axios';

const fetchPosts = (body: PostSearchParams): Promise<PaginateResponse<Post>> =>
  client.get(`/api/v1/posts-admin?${new URLSearchParams(JSON.parse(JSON.stringify(body))).toString()}`);
const fetchPostsPublic = (body: PostSearchParams): Promise<PaginateResponse<Post>> =>
  client.get(`/api/v1/posts?${new URLSearchParams(JSON.parse(JSON.stringify(body))).toString()}`);
const getListPostsPublic = (body: PostSearchParams): Promise<PaginateResponse<Post>> =>
  client.post(`/api/v1/posts/list`, body);
const fetchPostsByUUID = ({ uuid }: ByUUID): Promise<Post> => client.get(`/api/v1/posts/${uuid}`);
const fetchAdminPostsByUUID = ({ uuid }: ByUUID): Promise<Post> => client.get(`/api/v1/posts-admin/${uuid}`);
const fetchTopics = (body: PostSearchParams): Promise<PaginateResponse<Topic>> => client.get(`/api/v1/topics`);
const fetchSharingTypes = (body: PostSearchParams): Promise<PaginateResponse<SharingType>> =>
  client.get(`/api/v1/sharingtypes`);
const addPost = (body: PostCreateBody): Promise<ByID> => client.post(`/api/v1/posts-admin`, body);
const updatePost = ({ id, ...body }: PostUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/posts-admin/${id}`, body);
const postService = {
  fetchPosts,
  fetchTopics,
  fetchSharingTypes,
  fetchPostsPublic,
  getListPostsPublic,
  fetchAdminPostsByUUID,
  fetchPostsByUUID,
  addPost,
  updatePost,
};

export default postService;
