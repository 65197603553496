import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateProfile } from 'reducers/profileSlice';
import { authService, categoryService } from 'services';
import { regexValidatePhoneNumber, regexValidateVietnameseName } from 'utils/validator';

const FormProfile = () => {
  const dispatch = useDispatch();

  const { control, handleSubmit, reset } = useForm<UpdateProfileBody>();

  const { data: pageJobs } = useQuery(['categoryService.getAllJobs'], () => categoryService.getAllJobs(), {
    keepPreviousData: true,
  });
  const { list: jobs = [] } = pageJobs ?? {};

  const { refetch } = useQuery(['authService.getProfile'], () => authService.getProfile(), {
    onSuccess: (profile) => {
      dispatch(updateProfile(profile));
      reset({
        fullName: profile.fullName,
        email: profile.email,
        phone: profile.phone,
        catJobId: profile.catJobId,
      });
    },
  });

  const { mutate: mutateProfile, isLoading } = useMutation(authService.updateProfile, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật thông tin tài khoản thành công');
      refetch();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      mutateProfile(values);
    })();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Controller
          name='fullName'
          defaultValue=''
          control={control}
          rules={{
            required: 'Tên là bắt buộc',
            pattern: {
              value: regexValidateVietnameseName(),
              message: 'Vui lòng nhập họ tên từ 3 ký tự trở lên và không được chứa ký tự đặc biệt',
            },
            minLength: {
              value: 3,
              message: 'Vui lòng nhập họ tên từ 3 ký tự trở lên và không được chứa ký tự đặc biệt',
            },
            maxLength: {
              value: 255,
              message: 'Vui lòng nhập họ tên từ 3 ký tự trở lên và không được chứa ký tự đặc biệt',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} fullWidth required label='Tên' error={!!error} helperText={error?.message} />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='email'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} disabled fullWidth label='Email' error={!!error} helperText={error?.message} />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='phone'
          defaultValue=''
          control={control}
          rules={{
            required: 'Điện thoại là bắt buộc',
            pattern: {
              value: regexValidatePhoneNumber(),
              message: 'Số điện thoại không đúng định dạng',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field} fullWidth required label='Điện thoại' error={!!error} helperText={error?.message} />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='catJobId'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextFieldSelect {...field} fullWidth label='Nghề nghiệp' error={!!error} helperText={error?.message}>
              {jobs.map((item: any, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextFieldSelect>
          )}
        />
      </Grid>

      <Grid item xs={12} className='flex justify-end'>
        <LoadingButton variant='contained' className='px-12' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default FormProfile;
