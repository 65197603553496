import { Container } from '@mui/material';
import { StudentHomework, StudentCaseStudy } from './components';
import { useParams } from 'react-router-dom';

const StudentClassroomView = () => {
  const { id } = useParams();

  return (
    <Container maxWidth='xl' className='py-6'>
      <StudentCaseStudy classroomId={+id!} />
      <StudentHomework classroomId={+id!} />
    </Container>
  );
};

export default StudentClassroomView;
