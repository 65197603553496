import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { homeworkService, queryClient } from 'services';
import PopupHomeworkInfo from './PopupHomeworkInfo';
import { useState } from 'react';
import { InfoTwoTone } from '@mui/icons-material';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

type Props = PopupController & {
  answer: HomeworkAnswer;
};

const PopupHomeworkReview = ({ answer, onClose }: Props) => {
  const [openViewInfo, setOpenViewInfo] = useState(false);
  const { control, handleSubmit } = useForm<HomeworkReviewBody>();
  const { mutate: postHomeworkReview, isLoading } = useMutation(homeworkService.postHomeworkReview, {
    onSuccess: () => {
      enqueueSnackbar('Review bài tập thành công');
      queryClient.invalidateQueries(['homeworkService.fetchHomeworkAnswers']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      postHomeworkReview({
        ...values,
        id: answer.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Nhận xét bài tập về nhà</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <GridInfo label='Họ tên' value={answer.user?.fullName} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Số điện thoại' value={answer.user?.phone} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Email' value={answer.user?.email} />
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' alignItems='center'>
              <GridInfo label='Tên BTVN' value={answer.homework?.name} />
              <IconButton
                color='info'
                title='Xem thông tin bài tập về nhà'
                className='mb-1'
                onClick={() => {
                  setOpenViewInfo(true);
                }}
              >
                <InfoTwoTone />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='comment'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={2}
                  label='Chi tiết nhận xét'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <Controller
              name='done'
              defaultValue={0}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextFieldSelect
                  {...field}
                  fullWidth
                  label='Trạng thái bài làm'
                  error={!!error}
                  helperText={error?.message}
                >
                  <MenuItem value={1}>Hoàn thành</MenuItem>
                  <MenuItem value={0}>Chưa hoàn thành</MenuItem>
                </TextFieldSelect>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
      <Dialog maxWidth='lg' open={openViewInfo}>
        <PopupHomeworkInfo homework={answer.homework} onClose={() => setOpenViewInfo(false)} />
      </Dialog>
    </>
  );
};

export default PopupHomeworkReview;
