import { client } from './axios';

const fetchQuizEvents = (body: QuizEventSearchParams): Promise<PaginateResponse<QuizEvent>> =>
  client.post(`/api/v1/quizzes/list`, body);
const getQuizEventInfo = ({ id }: ByID): Promise<QuizEvent> => client.get(`/api/v1/quizzes/${id}`);
const getQuizEventDetail = ({ id }: ByID): Promise<QuizEventDetail> => client.get(`/api/v1/quizzes/${id}/detail`);
const getQuizEventByHashId = ({ hashId }: ByHashID): Promise<QuizEvent> => client.get(`/api/v1/quizzes/${hashId}/hash`);
const postQuizEvent = (body: QuizEventCreateBody): Promise<ByID> => client.post(`/api/v1/quizzes`, body);
const putQuizEvent = ({ id, ...body }: QuizEventUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/quizzes/${id}`, body);
const deleteQuizEvent = ({ id }: ByID) => client.delete(`/api/v1/quizzes/${id}`);

const postQuizEventQuestion = ({ id, ...body }: ByID & QuizQuestionUpsertBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/quizzes/${id}/questions`, body);
const putQuizEventQuestion = ({
  eventId,
  questionId,
  ...body
}: QuizEventQuestionUpsertBody & QuizQuestionUpsertBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/quizzes/${eventId}/questions/${questionId}`, body);
const deleteQuizEventQuestion = (body: QuizEventQuestionUpsertBody) =>
  client.delete(`/api/v1/quizzes/${body.eventId}/questions/${body.questionId}`);
const putQuizEventQuestionStart = (body: QuizEventQuestionUpsertBody) =>
  client.put(`/api/v1/quizzes/${body.eventId}/questions/${body.questionId}/start`);
const putQuizEventQuestionStop = (body: QuizEventQuestionUpsertBody) =>
  client.put(`/api/v1/quizzes/${body.eventId}/questions/${body.questionId}/stop`);
const putQuizEventQuestionLock = (body: QuizQuestionLockUpsertBody) =>
  client.put(`/api/v1/quizzes/${body.eventId}/questions/${body.questionId}/lock/${body.lock}`);
const deleteQuizEventQuestionAnswer = (body: QuizQuestionAnswerDeleteBody) =>
  client.put(`/api/v1/quizzes/${body.eventId}/questions/${body.questionId}/answers/${body.answerId}`);
const getQuizEventQuestionStatistic = (body: QuizEventQuestionUpsertBody): Promise<QuizQuestionStatistic> =>
  client.get(`/api/v1/quizzes/${body.eventId}/questions/${body.questionId}/statistic`);
const getQuizEventQuestionPlayStatistic = ({ hashId }: ByHashID): Promise<QuizQuestionStatistic> =>
  client.get(`/api/v1/quizzes/${hashId}/statistic`);

const getQuizEventQuestionPlay = ({ hashId, sessionId }: ByHashID & { sessionId: string }): Promise<QuizQuestionPlay> =>
  client.get(`/api/v1/quizzes/${hashId}/play/${sessionId}`);
const postQuizEventQuestionResult = ({
  eventId,
  questionId,
  ...body
}: { eventId: number; questionId: number } & QuizQuestionResultUpsertBody) =>
  client.post(`/api/v1/quizzes/${eventId}/questions/${questionId}/result`, body);

const quizService = {
  fetchQuizEvents,
  getQuizEventInfo,
  getQuizEventDetail,
  getQuizEventByHashId,
  postQuizEvent,
  putQuizEvent,
  deleteQuizEvent,

  postQuizEventQuestion,
  putQuizEventQuestion,
  deleteQuizEventQuestion,
  putQuizEventQuestionStart,
  putQuizEventQuestionStop,
  putQuizEventQuestionLock,
  deleteQuizEventQuestionAnswer,
  getQuizEventQuestionStatistic,
  postQuizEventQuestionResult,

  getQuizEventQuestionPlay,
  getQuizEventQuestionPlayStatistic,
};

export default quizService;
