import { StudentClassroomList, StudentClassroomView } from 'views/Homework';
import { GlobalQr } from 'views/Home';
import { Profile } from 'views/Profile';
import { RegisteListOwner, RegisterView } from 'views/Register';
import { PaperListOwner, StudentPaperResultView, StudentTesting } from 'views/Test';

const privateRoute = {
  profile: {
    path: '/profile',
    name: 'Thông tin tài khoản',
    component: Profile,
  },
  registered: {
    path: '/registered',
    name: 'Khóa học của tôi',
    component: RegisteListOwner,
  },
  registeredView: {
    path: '/registered/:id',
    url: ({ id }: ByID) => `/registered/${id}`,
    name: '',
    component: RegisterView,
  },
  globalQr: {
    path: '/qr',
    name: 'Join nhóm học viên',
    component: GlobalQr,
  },
  homework: {
    path: '/student-homework',
    name: 'Quản lý bài tập',
    component: StudentClassroomList,
  },
  studentHomeworkView: {
    path: '/student-homework/:id',
    url: ({ id }: ByID) => `/student-homework/${id}`,
    name: '',
    component: StudentClassroomView,
  },
  paper: {
    path: '/student-papers',
    name: 'Bài test của tôi',
    component: PaperListOwner,
  },
  studentPaperResultView: {
    path: '/student-papers/:id/:from',
    url: ({ id, from }: ByID & { from?: string }) => `/student-papers/${id}/${from ?? 'assign'}`,
    name: '',
    component: StudentPaperResultView,
  },
  studentTesting: {
    path: '/testing-papers/:id/:from',
    url: ({ id, from }: ByID & { from?: string }) => `/testing-papers/${id}/${from ?? 'assign'}`,
    name: '',
    component: StudentTesting,
  },
};

export default privateRoute;
