import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { classroomService } from 'services';

type Props = PopupController & {
  classroomId: number;
};

const PopupClassroomNotify = ({ classroomId, onClose }: Props) => {
  const { mutate: sendEmail, isLoading } = useMutation(classroomService.postClassroomEmailNotify, {
    onSuccess: () => {
      enqueueSnackbar('Gửi email thành công');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    sendEmail({
      id: classroomId,
    });
  };
  return (
    <>
      <DialogTitle>Xác nhận gửi email thông tin lớp học</DialogTitle>

      <DialogContent>
        <div className='text-center'>Bạn có chắc chắn gửi email thống báo thông tin lớp học này không?</div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupClassroomNotify;
