import { LiveTvOutlined, ShareOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { publicRoute } from 'routes';

type Props = {
  link: string;
  present?: boolean;
  hashId?: string;
};

const EventQr = ({ link, present, hashId }: Props) => {
  const copyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      enqueueSnackbar('Copy link thành công');
    });
  };
  return (
    <>
      <Grid container spacing={3} direction='column' alignItems='center' justifyContent='center'>
        <Grid item xs={12}>
          <QRCode value={link} className='h-[160px] w-[160px]' />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton variant='outlined' onClick={copyLink} startIcon={<ShareOutlined />}>
            Chia sẻ
          </LoadingButton>
          {present && hashId && (
            <Link to={publicRoute.quizPresent.url({ hashId: hashId! })} target='_blank'>
              <LoadingButton className='ml-2' variant='contained' color='success' startIcon={<LiveTvOutlined />}>
                Trình chiếu
              </LoadingButton>
            </Link>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EventQr;
