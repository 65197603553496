import { Button, Container, Dialog, Grid, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { classroomService } from 'services';
import { formatDate } from 'utils/common';
import { Edit, Email, HomeWork } from '@mui/icons-material';
import { useState } from 'react';
import {
  StudentList,
  DocList,
  PopupGoogleMeetUpdate,
  PopupClassroomNotify,
  PopupClassroomHomework,
} from './components';
import PopupZaloUpdate from './components/PopupZaloUpdate';
import PopupZaloFreeChatUpdate from './components/PopupZaloFreeChatUpdate';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

const ClassroomView = () => {
  const { id } = useParams();
  const [openNoti, setOpenNoti] = useState(false);
  const [openUpdateGoogleMeet, setOpenUpdateGoogleMeet] = useState(false);
  const [openUpdateZalo, setOpenUpdateZalo] = useState(false);
  const [openUpdateZaloFreeChat, setOpenUpdateZaloFreeChat] = useState(false);
  const [openClassroomHomework, setOpenClassroomHomework] = useState(false);

  const { data, isSuccess } = useQuery(['classroomService.getClassroomInfo', id], () =>
    classroomService.getClassroomInfo({ id: +id! }),
  );

  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Typography variant='h4' color='primary' className='mb-6 space-x-3'>
          <span>
            {data?.code} - {data?.name}
          </span>
          {data?.allowEmailNotify && (
            <Tooltip title='Gửi thông tin lớp học cho học viên đã đăng ký nhận thông tin trước đó'>
              <Button
                size='small'
                color='info'
                onClick={() => {
                  setOpenNoti(true);
                }}
              >
                <Email />
              </Button>
            </Tooltip>
          )}
          <Tooltip title='Gán bài tập cho lớp'>
            <Button
              size='small'
              color='primary'
              onClick={() => {
                setOpenClassroomHomework(true);
              }}
            >
              <HomeWork />
            </Button>
          </Tooltip>
        </Typography>

        {isSuccess && (
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <GridInfo label='Khóa học' value={data.course?.name} />
            </Grid>
            {data.course?.method.toUpperCase() !== 'VIDEO' && (
              <Grid item md={3} xs={12}>
                <GridInfo label='Khai giảng' value={formatDate(data.startDate)} />
              </Grid>
            )}
            <Grid item md={3} xs={12}>
              <GridInfo
                label='Thời lượng'
                value={
                  data.course?.timeInLesson
                    ? data.course?.timeInHour + ' giờ / ' + data.course?.timeInLesson + ' buổi'
                    : data.course?.timeInHour + ' giờ'
                }
              />
            </Grid>
            <Grid item md={data.course?.method.toUpperCase() !== 'VIDEO' ? 3 : 6} xs={12}>
              <GridInfo label='Lịch học' value={data.schedule + ' - ' + data.studyTime} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography display='inline' className='mb-6 mt-6 space-x-3'>
                Link Zalo lớp học chính thức:{' '}
              </Typography>
              <Typography display='inline' className='mb-6 mt-6 space-x-3'>
                <span className='font-bold'>{data.zaloLink}</span>
                <Tooltip title='Cập nhật link Zalo lớp học chính thức của lớp học'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpenUpdateZalo(true);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography display='inline' className='mb-6 mt-6 space-x-3'>
                Link Zalo group buôn chuyện:{' '}
              </Typography>
              <Typography display='inline' className='mb-6 mt-6 space-x-3'>
                <span className='font-bold'>{data.zaloLinkFreeChat}</span>
                <Tooltip title='Cập nhật link Zalo group buôn chuyện của lớp học'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpenUpdateZaloFreeChat(true);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography display='inline' className='mb-6 mt-6 space-x-3'>
                Link google meet:{' '}
              </Typography>
              <Typography display='inline' className='mb-6 mt-6 space-x-3'>
                <span className='font-bold'>{data.googleMeetLink}</span>
                <Tooltip title='Cập nhật link google meet của lớp học'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpenUpdateGoogleMeet(true);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item md={3} xs={6}>
              <GridInfo label='Học viên đăng ký' value={data.totalRegistered} />
            </Grid>
            <Grid item md={3} xs={6}>
              <GridInfo label='Tối đa cho phép' value={data.totalSlot} />
            </Grid>
            <Grid item md={3} xs={12}>
              <GridInfo label='Học viên thực tế' value={data.totalStudent} />
            </Grid>
            <Grid item md={3} xs={12}>
              <GridInfo label='Học viên bảo lưu sang lớp khác' value={data.totalReserveOut} />
            </Grid>
            <Grid item md={3} xs={12}>
              <GridInfo label='Học viên bảo lưu sang lớp này' value={data.totalReserveIn} />
            </Grid>
          </Grid>
        )}
      </Container>
      <Container maxWidth='xl' className='py-6'>
        <StudentList classroomId={+id!} />
      </Container>
      {data && (
        <>
          <Container maxWidth='xl' className='py-6'>
            <DocList classroom={data!} editable={true} />
          </Container>
          <Dialog maxWidth='sm' open={openUpdateGoogleMeet}>
            <PopupGoogleMeetUpdate classroom={data!} onClose={() => setOpenUpdateGoogleMeet(false)} />
          </Dialog>
          <Dialog maxWidth='sm' open={openUpdateZalo}>
            <PopupZaloUpdate classroom={data!} onClose={() => setOpenUpdateZalo(false)} />
          </Dialog>
          <Dialog maxWidth='sm' open={openUpdateZaloFreeChat}>
            <PopupZaloFreeChatUpdate classroom={data!} onClose={() => setOpenUpdateZaloFreeChat(false)} />
          </Dialog>
          <Dialog maxWidth='lg' open={openClassroomHomework}>
            <PopupClassroomHomework classroom={data!} onClose={() => setOpenClassroomHomework(false)} />
          </Dialog>
        </>
      )}
      <Dialog maxWidth='sm' open={openNoti}>
        <PopupClassroomNotify classroomId={+id!} onClose={() => setOpenNoti(false)} />
      </Dialog>
    </>
  );
};

export default ClassroomView;
