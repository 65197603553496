import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { TextFieldPassword } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { authService } from 'services';

const FormProfile = () => {
  const { control, handleSubmit, watch, reset } = useForm<ChangePasswordBody>();

  const { mutate: changePassword, isLoading } = useMutation(authService.changePassword, {
    onSuccess: () => {
      enqueueSnackbar('Đổi mật khẩu thành công');
      reset();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      changePassword(values);
    })();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Controller
          name='oldPassword'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu cũ là bắt buộc',
            minLength: {
              value: 6,
              message: 'Mật khẩu cũ tối thiểu 6 ký tự',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextFieldPassword
              {...field}
              fullWidth
              required
              label='Mật khẩu cũ'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='newPassword'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu mới là bắt buộc',
            minLength: {
              value: 6,
              message: 'Mật khẩu tối thiểu 6 ký tự',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextFieldPassword
              {...field}
              fullWidth
              required
              label='Mật khẩu mới'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='confirmPassword'
          defaultValue=''
          control={control}
          rules={{
            required: 'Xác nhận mật khẩu là bắt buộc',
            validate: {
              match: (value) => {
                if (value === watch('newPassword')) {
                  return true;
                }
                return 'Mật khẩu không khớp';
              },
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextFieldPassword
              {...field}
              fullWidth
              required
              label='Xác nhận mật khẩu'
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} className='flex justify-end'>
        <LoadingButton variant='contained' className='px-12' loading={isLoading} onClick={handleClickSubmit}>
          Đổi mật khẩu
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default FormProfile;
