import { Paper } from '@mui/material';
import { formatNumber } from 'utils/common';

type Props = {
  course: Course;
};

const TabCourseFee = ({ course }: Props) => {
  return (
    <Paper className='mx-auto max-w-2xl bg-[orange]/10 p-4'>
      <span className='text-black/60'>Học phí:</span> {formatNumber(course.fee)}đ
    </Paper>
  );
};

export default TabCourseFee;
