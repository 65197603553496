import { client } from './axios';

const fetchCourses = (): Promise<PaginateResponse<Course>> => client.get(`/api/v1/courses`);
const getCourseInfo = ({ id }: ByID): Promise<Course> => client.get(`/api/v1/courses/${id}`);

const courseService = {
  fetchCourses,
  getCourseInfo,
};

export default courseService;
