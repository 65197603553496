import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { classroomService, queryClient } from 'services';

export const DocMethodTypes = [
  { value: 'BEFORE', label: 'Trước khóa học' },
  { value: 'OFFICIAL', label: 'Chính thức' },
];

type Props = PopupController & {
  classroomId: number;
};

const PopupDocAdd = ({ classroomId, onClose }: Props) => {
  const { control, handleSubmit } = useForm<DocumentCreateBody>();

  const { mutate: addDoc, isLoading } = useMutation(classroomService.addClassroomDoc, {
    onSuccess: () => {
      enqueueSnackbar('Thêm mới tài liệu thành công');
      queryClient.invalidateQueries([`classroomService.fetchClassroomDocs${classroomId}`]);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      addDoc({
        ...values,
        id: classroomId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Thêm tài liệu</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='docType'
              defaultValue=''
              control={control}
              rules={{
                required: 'Loại tài liệu là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Loại tài liệu' error={!!error} helperText={error?.message}>
                  {['YouTube', 'GG Drive'].map((item: any, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='name'
              defaultValue=''
              control={control}
              rules={{
                required: 'Tên tài liệu là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Tên tài liệu'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='url'
              defaultValue=''
              control={control}
              rules={{
                required: 'Url là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth required label='URL' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='method'
              defaultValue=''
              control={control}
              rules={{
                required: 'Hình thức là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Hình thức' error={!!error} helperText={error?.message}>
                  {DocMethodTypes.map((item: any, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupDocAdd;
