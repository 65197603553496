import { LoadingButton } from '@mui/lab';
import { Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { authRoute } from 'routes';
import { authService } from 'services';

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<ResetPasswordBody>();

  const { mutate: resetPassword, isLoading } = useMutation(authService.resetPassword, {
    onSuccess: () => {
      enqueueSnackbar('Chúng tôi đã gửi mật khẩu mới vào email của bạn, vui lòng kiểm tra và làm theo hướng dẫn');
      navigate(authRoute.login.url);
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      resetPassword(values);
    })();
  };

  return (
    <Container maxWidth='sm'>
      <Paper className='space-y-6 p-10 mobile:px-4'>
        <Typography variant='h3' color='primary' className='text-center'>
          Reset Mật khẩu
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} className='flex space-x-3'>
            <Controller
              name='email'
              defaultValue=''
              control={control}
              rules={{
                required: 'Email bắt buộc nhập',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth required label='Email' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
        </Grid>

        <div className='flex justify-center'>
          <LoadingButton
            fullWidth
            variant='contained'
            size='large'
            className='md:w-[50%]'
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            Reset Mật khẩu
          </LoadingButton>
        </div>

        <div className='flex justify-center gap-2 mobile:flex-col mobile:items-center'>
          <span>Bạn vẫn nhớ mật khẩu?</span>
          <Link to={authRoute.login.url} className='font-bold text-primary-main hover:text-primary-dark'>
            Đăng nhập
          </Link>
        </div>
      </Paper>
    </Container>
  );
};

export default ResetPasswordForm;
