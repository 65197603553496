import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { classroomService, courseService, paperService } from 'services';

type Props = {
  onChange: (...args: any) => void;
};

const PaperResultSearch = ({ onChange }: Props) => {
  const { control, handleSubmit, watch, reset } = useForm<TestPaperResultSearchParams>();

  const courseId = watch('courseId');
  const classroomId = watch('classroomId');
  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { data: dataClassroom } = useQuery(['classroomService.fetchClassrooms', { courseId: courseId }], () =>
    classroomService.fetchClassrooms({ courseId: courseId, size: -1 }),
  );
  const { data: dataPaper } = useQuery(
    ['paperService.fetchPapers', { courseId: courseId, classroomId: classroomId }],
    () => paperService.fetchPapers({ courseId: courseId, classroomId: classroomId, size: -1 }),
  );

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      courseId: '',
      classroomId: '',
      paperId: '',
      textSearch: '',
      fromDate: null,
      toDate: null,
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <Controller
            name='courseId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Khóa học' error={!!error} helperText={error?.message}>
                {(dataCourse?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='classroomId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Lớp học' error={!!error} helperText={error?.message}>
                {(dataClassroom?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='paperId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Bài test' error={!!error} helperText={error?.message}>
                {(dataPaper?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='textSearch'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label='Học viên(Email/Điện thoại/Họ tên'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='fromDate'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Từ ngày',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='toDate'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Đến ngày',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='approvedStatus'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect
                {...field}
                fullWidth
                label='Trạng thái bài test'
                error={!!error}
                helperText={error?.message}
              >
                <MenuItem value='NO'>Chưa duyệt điểm</MenuItem>
                <MenuItem value='YES'>Đã duyệt điểm</MenuItem>
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <div className='mt-2 flex justify-end space-x-3'>
            <Button onClick={handleClickSearch}>Tìm kiếm</Button>
            <Button variant='outlined' color='inherit' onClick={handleClickClear}>
              <Clear />
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PaperResultSearch;
