import { CloseTwoTone, DoneTwoTone } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, Rating, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { queryClient, surveyService } from 'services';
import { formatDate } from 'utils/common';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

type Props = PopupController & {
  item: Register;
  view: boolean;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      {label && <span>{label}: </span>}
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

const PopupSurvey = ({ item, view, onClose }: Props) => {
  const [status, setStatus] = useState('');
  const [approveNote, setApproveNote] = useState('');
  const { control, handleSubmit, reset } = useForm<UpsertSurveyBody>();

  const { mutate: upsertSurvey, isLoading } = useMutation(surveyService.upsertSurvey, {
    onSuccess: () => {
      enqueueSnackbar('Gửi đánh giá khóa học thành công');
      queryClient.invalidateQueries(['surveyService.fetchMyRegisters']);
      onClose();
    },
  });

  useQuery(['surveyService.getMySurvey'], () => surveyService.getMySurvey({ studentId: item.studentId }), {
    onSuccess: (data) => {
      setStatus(data?.status ?? '');
      setApproveNote(data?.approvedNote ?? '');
      reset({
        note: data?.note,
        rate: data?.rate,
      });
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      upsertSurvey({
        id: item.studentId,
        ...values,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Đánh giá khóa học</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <GridInfo label='Khóa học' value={item.course?.name} />
          </Grid>
          <Grid item md={6} xs={12}>
            <GridInfo label='Hình thức' value={item.course?.method} />
          </Grid>
          <Grid item md={6} xs={12}>
            <GridInfo label='Lớp học' value={item.classroom?.name} />
          </Grid>
          <Grid item md={6} xs={12}>
            <GridInfo
              label='Khai giảng'
              value={item.classroom?.startDate ? formatDate(item.classroom?.startDate) : ''}
            />
          </Grid>
          {status === 'APPROVED' && (
            <>
              <Grid item xs={12} textAlign='center'>
                <DoneTwoTone
                  color='success'
                  sx={{
                    fontSize: 32,
                    fontWeight: 'bold',
                    mx: 0.5,
                  }}
                ></DoneTwoTone>
                <Typography variant='inherit' className='text-success'>
                  Đã phê duyệt
                </Typography>
              </Grid>
            </>
          )}
          {status === 'REJECTED' && (
            <>
              <Grid item xs={12} textAlign='center'>
                <CloseTwoTone
                  color='error'
                  sx={{
                    fontSize: 32,
                    fontWeight: 'bold',
                    mx: 0.5,
                  }}
                ></CloseTwoTone>
                <Typography variant='inherit' className='text-error'>
                  Từ chối
                </Typography>
                <GridInfo label='Lý do' value={approveNote} />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Controller
              name='rate'
              defaultValue={0}
              control={control}
              render={({ field }) => (
                <Rating readOnly={view || status === 'APPROVED'} size='large' {...field} precision={1} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='note'
              defaultValue=''
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  inputProps={{ readOnly: view || status === 'APPROVED' }}
                  minRows={3}
                  fullWidth
                  label='Đánh giá'
                  placeholder='Nội dung chi tiết đánh giá khóa học'
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        {!view && !(status === 'APPROVED') && (
          <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
            Gửi đánh giá
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default PopupSurvey;
