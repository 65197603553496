import { Button, Chip, Container, Dialog, Grid, Tooltip, Typography } from '@mui/material';
import { HomeworkSearch, PopupHomeworkAnswerLog, PopupHomeworkReview } from './components';
import { AvTimerOutlined, Reviews } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { TruncatedTextView } from 'views/Survey/components';
import { formatDateTime } from 'utils/common';
import { Link } from 'react-router-dom';
import { homeworkService } from 'services';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

const HomeworkList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();
  const { data, isFetching } = useQuery(
    ['homeworkService.fetchHomeworkAnswers', dataSearch],
    () => homeworkService.fetchHomeworkAnswers(dataSearch),
    { keepPreviousData: true },
  );
  const {
    list: items = [],
    total = 0,
    totalFinish = 0,
    totalReviewing1 = 0,
    totalReviewingAgain = 0,
    totalReviewingEditing = 0,
  } = data ?? {};

  const [openAnswerLog, setOpenAnswerLog] = useState(false);
  const [openHomeworkReview, setOpenHomeworkReview] = useState(false);
  const [itemChoice, setItemChoice] = useState<HomeworkAnswer>();
  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Bảng tổng hợp chữa bài tập về nhà
      </Typography>
      <HomeworkSearch onChange={onSearchChange} />
      <Grid container className='mt-6' spacing={3}>
        <Grid item md={3} xs={6}>
          <GridInfo label='Bài hoàn thành' value={totalFinish} />
        </Grid>
        <Grid item md={3} xs={6}>
          <GridInfo label='Bài Review lần 1' value={totalReviewing1} />
        </Grid>
        <Grid item md={3} xs={6}>
          <GridInfo label='Bài Review lại' value={totalReviewingAgain} />
        </Grid>
        <Grid item md={3} xs={6}>
          <GridInfo label='Bài chờ sửa' value={totalReviewingEditing} />
        </Grid>
      </Grid>
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 160}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'user.Email',
            headerName: 'Học viên',
            flex: 1,
            minWidth: 300,
            sortable: false,
            renderCell: ({ row }) => (
              <>
                <div>
                  {row.user.fullName}
                  <br />
                  {row.user.email}
                  <br />
                  {row.user.phone}
                  <br />
                  {row.classroom.code}
                  <br />
                  {row.course.name}
                </div>
              </>
            ),
          },
          {
            field: 'caseStudy',
            headerName: 'Case study',
            minWidth: 240,
            sortable: false,
            renderCell: ({ row }) => <TruncatedTextView text={row.caseStudy?.name ?? ''} />,
          },
          {
            field: 'link',
            headerName: 'Link bài làm',
            minWidth: 240,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                <Link to={row.link} target='_blank' className='text-primary-main hover:text-primary-dark'>
                  {row.homework.name}
                </Link>
              </div>
            ),
          },
          {
            field: 'user',
            headerName: 'Thông tin chữa bài',
            minWidth: 240,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.mentorUser?.fullName}
                <br />
                <TruncatedTextView text={row.lastTeacherComment ?? ''} />
              </div>
            ),
          },
          {
            field: 'date',
            headerName: 'Ngày tác động',
            minWidth: 150,
            flex: 1,
            align: 'center',
            sortable: false,
            renderCell: ({ row }) => <div>{row.updatedAt ? formatDateTime(row.updatedAt) : ''}</div>,
          },
          {
            field: 'status',
            headerName: 'Trạng thái',
            minWidth: 160,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.status === 'EDITING' && <Chip label='Chờ sửa bài' color='info' />}
                {row.status === 'FINISHED' && <Chip label='Hoàn thành' color='success' />}
                {row.status === 'REVIEWING' && <Chip label='Chờ review lần 1' color='error' />}
                {row.status === 'REVIEWING_AGAIN' && <Chip label='Chờ review lại' color='primary' />}
              </div>
            ),
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 150,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Tooltip title='Lịch sử tác động'>
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      setItemChoice(row);
                      setOpenAnswerLog(true);
                    }}
                  >
                    <AvTimerOutlined />
                  </Button>
                </Tooltip>
                {(row.status === 'REVIEWING' || row.status === 'REVIEWING_AGAIN') && (
                  <Tooltip title='Nhận xét bài tập về nhà'>
                    <Button
                      size='small'
                      color='secondary'
                      onClick={() => {
                        setItemChoice(row);
                        setOpenHomeworkReview(true);
                      }}
                    >
                      <Reviews />
                    </Button>
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]}
      />
      <Dialog maxWidth='lg' open={openHomeworkReview}>
        <PopupHomeworkReview answer={itemChoice!} onClose={() => setOpenHomeworkReview(false)} />
      </Dialog>
      <Dialog maxWidth='lg' open={openAnswerLog}>
        <PopupHomeworkAnswerLog answer={itemChoice!} onClose={() => setOpenAnswerLog(false)} />
      </Dialog>
    </Container>
  );
};

export default HomeworkList;
