import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldNumber, TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { categoryService, paperService, queryClient } from 'services';

type Props = PopupController & {
  paper: TestPaper;
  assessments: TestPaperAssessmentType[];
};

const PopupPaperAssessment = ({ paper, assessments, onClose }: Props) => {
  const { control, watch, handleSubmit, reset } = useForm<TestPaperAssessmentUpdateBody>({
    defaultValues: {
      assessmentGroupTypeId: paper.assessmentGroupId,
      assessmentTypes: [],
    },
  });
  const groupId = watch('assessmentGroupTypeId');
  const { data: dataGroup } = useQuery(['categoryService.getAllTestAssessmentGroupTypes'], () =>
    categoryService.getAllTestAssessmentGroupTypes(),
  );

  useEffect(() => {
    if (!groupId) return;
    categoryService.getAllTestAssessmentTypes({ groupId: groupId }).then((res) => {
      reset({
        assessmentGroupTypeId: groupId,
        assessmentTypes: [],
      });
      setTimeout(() => {
        reset({
          assessmentGroupTypeId: groupId,
          assessmentTypes: res.list.map(({ id, name }) => ({
            assessmentTypeId: id,
            assessmentTypeName: name,
            fromPoint: assessments.find((e) => e.assessmentTypeId === id)?.fromPoint,
            toPoint: assessments.find((e) => e.assessmentTypeId === id)?.toPoint,
          })),
        });
      }, 500);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const { fields: assessmentTypes } = useFieldArray({
    control,
    name: 'assessmentTypes',
  });

  const { mutate: addPaperPaperAssessment, isLoading } = useMutation(paperService.postPaperAssessment, {
    onSuccess: () => {
      enqueueSnackbar('Cấu hình thông tin đánh giá kết quả cho bài test thành công');
      queryClient.invalidateQueries(['paperService.fetchPapers']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      addPaperPaperAssessment({
        ...values,
        id: paper.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Thông tin đánh giá kết quả</DialogTitle>

      <DialogContent>
        <Typography variant='h5' color='primary' className='mb-10'>
          Tổng điểm bài test: {paper.maxPoint}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='assessmentGroupTypeId'
              defaultValue={paper.assessmentGroupId}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect
                  {...field}
                  fullWidth
                  label='Chọn kiểu kết quả đánh giá'
                  error={!!error}
                  helperText={error?.message}
                >
                  {(dataGroup?.list ?? []).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
        </Grid>
        {assessmentTypes.map((item, index) => {
          return (
            <Grid key={index} container spacing={3} className='mt-1'>
              <Grid item xs={12} md={2}>
                <Typography variant='h6' className='ml-4 mt-4'>
                  {item.assessmentTypeName}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Controller
                  name={`assessmentTypes.${index}.fromPoint`}
                  defaultValue={item.fromPoint}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldNumber {...field} fullWidth label='Điểm từ' error={!!error} helperText={error?.message} />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Controller
                  name={`assessmentTypes.${index}.toPoint`}
                  defaultValue={item.toPoint}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldNumber {...field} fullWidth label='Tới' error={!!error} helperText={error?.message} />
                  )}
                />
              </Grid>
            </Grid>
          );
        })}
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupPaperAssessment;
