import { Check, Delete } from '@mui/icons-material';
import { Button, Chip, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { paperService, queryClient } from 'services';

type Props = {
  paperIds: number[];
  onlyAssign: boolean;
};

const PaperCourseUpdate = ({ paperIds, onlyAssign }: Props) => {
  const { data, isFetching } = useQuery(
    ['paperService.fetchPaperAssignsByCourse'],
    () => paperService.fetchPaperAssigns({ paperIds: paperIds, type: 'COURSE', size: -1 }),
    {
      keepPreviousData: true,
    },
  );
  const { list: items = [] } = data ?? {};

  const { mutate: addPaperAssign } = useMutation(paperService.addPaperAssign, {
    onSuccess: () => {
      enqueueSnackbar('Gán bài test cho khóa học thành công');
      queryClient.invalidateQueries(['paperService.fetchPaperAssignsByCourse']);
    },
  });

  const { mutate: removePaperAssign } = useMutation(paperService.removePaperAssign, {
    onSuccess: () => {
      enqueueSnackbar('Hủy gán bài test cho khóa học thành công');
      queryClient.invalidateQueries(['paperService.fetchPaperAssignsByCourse']);
    },
  });

  const remove = (item: TestPaperAssign) => {
    removePaperAssign({
      id: item.id,
      paperId: item.paperId,
    });
  };

  const add = (courseId: number) => {
    addPaperAssign({
      courseId: courseId,
      paperIds: paperIds,
    });
  };

  return (
    <>
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.courseId}
        getRowHeight={() => 64}
        rows={items}
        paginationMode='client'
        columns={[
          {
            field: 'assigned',
            headerName: 'Đã giao',
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.assigned && (
                  <Tooltip title='Đã gán bài test cho khóa học'>
                    <Chip label={<Check />} color='success' variant='filled' />
                  </Tooltip>
                )}
              </div>
            ),
          },
          {
            field: 'name',
            headerName: 'Tên khóa học',
            flex: 1,
            minWidth: 400,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.course?.code} <br />
                {row.course?.name}
              </div>
            ),
          },
          {
            field: 'totalRegisteredStudent',
            headerName: 'Tổng số học viên',
            flex: 1,
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => <div>{row.course?.totalRegisteredStudent}</div>,
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 130,
            renderCell: ({ row }) => (
              <div className='flex items-center space-x-3'>
                {!onlyAssign && row.assigned && (
                  <Tooltip title='Hủy giao bài test'>
                    <Button
                      size='small'
                      color='error'
                      onClick={() => {
                        remove(row);
                      }}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                )}
                {(onlyAssign || !row.assigned) && (
                  <Tooltip title='Giao bài test'>
                    <Button
                      size='small'
                      color='success'
                      onClick={() => {
                        add(row.courseId);
                      }}
                    >
                      <Check />
                    </Button>
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

export default PaperCourseUpdate;
