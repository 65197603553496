import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { categoryService, classroomService, courseService } from 'services';

type Props = {
  onChange: (...args: any) => void;
};

const CaseStudySearch = ({ onChange }: Props) => {
  const { control, handleSubmit, watch, reset } = useForm<CaseStudySearchParams>();

  const courseId = watch('courseId');
  const { data: dataDomain } = useQuery(['categoryService.getAllStudyDomains'], () =>
    categoryService.getAllStudyDomains(),
  );
  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { data: dataClassroom } = useQuery(['classroomService.fetchClassrooms', { courseId: courseId }], () =>
    classroomService.fetchClassrooms({ courseId: courseId, size: -1 }),
  );

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      courseId: '',
      classroomId: '',
      domainId: '',
      name: '',
      studentTextSearch: '',
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <Controller
            name='courseId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Khóa học' error={!!error} helperText={error?.message}>
                {(dataCourse?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='classroomId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Lớp học' error={!!error} helperText={error?.message}>
                {(dataClassroom?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='domainId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Domain' error={!!error} helperText={error?.message}>
                {(dataDomain?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='name'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label='Tên/Ngữ cảnh case study'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='studentTextSearch'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label='Họ tên/Email/Số điện thoại học viên'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
      <div className='mt-6 flex justify-end space-x-3'>
        <Button onClick={handleClickSearch}>Tìm kiếm</Button>
        <Button variant='outlined' color='inherit' onClick={handleClickClear}>
          <Clear />
        </Button>
      </div>
    </>
  );
};

export default CaseStudySearch;
