import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { queryClient } from 'services';
import { homeworkService } from 'services';

type Props = PopupController & {
  homework: MyHomework;
};

const PopupHomeworkResubmit = ({ homework, onClose }: Props) => {
  const { control, handleSubmit } = useForm<HomeworkResubmitBody>();

  const { mutate: resubmitHomework, isLoading } = useMutation(homeworkService.postHomeworkResubmit, {
    onSuccess: () => {
      enqueueSnackbar('Xác nhận sửa bài tập thành công');
      queryClient.invalidateQueries(['homeworkService.fetchMyHomework']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      resubmitHomework({
        ...values,
        id: homework.answer!.id,
        homeworkId: homework.id,
        classroomId: homework.answer!.classroomId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Xác nhận sửa bài tập</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField fullWidth label='BTVN' inputProps={{ readOnly: true }} defaultValue={homework.name} />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='link'
              defaultValue={homework.answer!.link}
              control={control}
              rules={{
                required: 'Link bài tập là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={2}
                  required
                  label='Link bài tập'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='note'
              defaultValue=''
              control={control}
              render={({ field }) => (
                <TextField {...field} multiline minRows={2} fullWidth label='Phản hồi' placeholder='Nội dung' />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupHomeworkResubmit;
