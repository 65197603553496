import { client } from './axios';

const fetchStudents = (body: StudentSearchParams): Promise<PaginateEmailResponse<Student>> =>
  client.post(`/api/v1/students/list`, body);
const getPublicStudent = (body: StudentSearchParams): Promise<Student> => client.post(`/api/v1/students/public`, body);

const upsertStudentEvaluation = ({ id, ...body }: ByID & StudentEvaluationUpsertBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/students/${id}/evaluate`, body);

const postPublicMyEvaluation = ({ id }: ByID) => client.post(`/api/v1/students/me/${id}/evaluate/public`);
const postPrivateMyEvaluation = ({ id }: ByID) => client.post(`/api/v1/students/me/${id}/evaluate/private`);
const geMyEvaluationInfo = ({ id }: ByID): Promise<Student> => client.get(`/api/v1/students/me/${id}/evaluate`);
const gePublicStudentImage = ({ id, fileHashId }: ByID & { fileHashId: string }): Promise<BlobPart> =>
  client.get(`/api/v1/students/public/${id}/file/${fileHashId}`, { responseType: 'arraybuffer' });

const studentService = {
  fetchStudents,
  getPublicStudent,
  upsertStudentEvaluation,

  postPublicMyEvaluation,
  postPrivateMyEvaluation,
  geMyEvaluationInfo,

  gePublicStudentImage,
};

export default studentService;
