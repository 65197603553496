import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { registerService } from 'services';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { useNavigate } from 'react-router-dom';
import { authRoute } from 'routes';
import { PromotionApplyTypes, formatDate, formatNumber } from 'utils/common';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
  large?: React.ReactNode;
};

const GridInfo = ({ label, value, large }: InfoProps) => {
  return (
    <div>
      <Typography display='inline' variant='body1'>
        {label}:{' '}
      </Typography>
      {!large && (
        <Typography className='ml-2' display='inline' variant='h5'>
          {value ?? ''}
        </Typography>
      )}
      {large && (
        <Typography className='ml-2 text-2xl font-bold text-success' display='inline'>
          {value ?? ''}
        </Typography>
      )}
    </div>
  );
};

type Props = PopupController & {
  item: Classroom;
  onRegister: (data: Register) => void;
};

const PopupRegisterClass = ({ item, onClose, onRegister }: Props) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(profileSelector);

  const { data: promotion } = useQuery(
    ['registerService.getMyPromotion', { classroomId: item.id }],
    () => registerService.getMyPromotion({ classroomId: item.id }),
    { keepPreviousData: true },
  );

  const { mutate: registerCreate, isLoading } = useMutation(registerService.registerCreate, {
    onSuccess: (data) => {
      onClose();
      enqueueSnackbar('Đăng ký khóa học thành công');
      onRegister(data);
    },
    onError() {
      onClose();
      if (!isLoggedIn) {
        navigate(authRoute.login.url);
      }
    },
  });

  const handleClickSubmit = () => {
    registerCreate({
      classroomId: item.id,
    });
  };

  return (
    <>
      <DialogTitle>Đăng ký khóa học</DialogTitle>

      <DialogContent>
        <div className='text-center'>
          Bạn có chắc muốn đăng ký khóa học <b>{item.course?.name}</b> - Hình thức <b>{item.course?.method}</b>
          <br />
          Thời gian khai giảng <b>{formatDate(item.startDate)}</b>{' '}
          {item.address && (
            <>
              tại <b>{item.address}</b>
            </>
          )}{' '}
          không?
        </div>
        {promotion?.promotionId && (
          <Grid container spacing={2} className='mt-4'>
            <Grid item xs={12}>
              <GridInfo label='Học phí' value={formatNumber(promotion?.orgFee) + 'đ'} />
            </Grid>
            <Grid item xs={12}>
              <GridInfo label='Số tiền giảm' value={formatNumber(promotion?.discountAmount) + 'đ'} />
            </Grid>
            <Grid item xs={12}>
              <GridInfo
                label='Lý do giảm'
                value={PromotionApplyTypes.find((item) => item.value === promotion?.applyType)?.label}
              />
            </Grid>
            <Grid item xs={12}>
              <GridInfo label='Còn lại' large value={formatNumber(promotion?.fee) + 'đ'} />
            </Grid>
          </Grid>
        )}
        {!promotion?.promotionId && (
          <Grid container spacing={2} className='mt-4'>
            <Grid item xs={12}>
              <GridInfo label='Học phí' value={formatNumber(promotion?.fee) + 'đ'} />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupRegisterClass;
