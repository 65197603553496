import { Delete, Edit, InfoOutlined } from '@mui/icons-material';
import { Button, Container, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { adminRoute } from 'routes';
import { quizService } from 'services';
import { formatDate } from 'utils/common';
import { EventSearch, PopupEventDelete, PopupEventUpsert } from './components';

const EventList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    ['quizService.fetchQuizEvents', dataSearch],
    () => quizService.fetchQuizEvents(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const [openAddEvent, setOpenAddEvent] = useState(false);
  const [openUpdateEvent, setOpenUpdateEvent] = useState(false);
  const [openDeleteEvent, setOpenDeleteEvent] = useState(false);
  const [itemChoice, setItemChoice] = useState<QuizEvent>();

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách sự kiện
      </Typography>

      <EventSearch onChange={onSearchChange} />
      <div className='mt-6 flex justify-end space-x-3'>
        <Button color='success' onClick={() => setOpenAddEvent(true)}>
          Thêm mới
        </Button>
      </div>
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 64}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'name',
            headerName: 'Tên sự kiện',
            flex: 1,
            minWidth: 300,
            sortable: false,
            renderCell: ({ row }) => <div>{row.name}</div>,
          },
          {
            field: 'createdAt',
            headerName: 'Ngày tạo',
            minWidth: 120,
            sortable: false,
            renderCell: ({ row }) => <div>{formatDate(row.createdAt)}</div>,
          },
          {
            field: 'updatedAt',
            headerName: 'Ngày cập nhật',
            minWidth: 150,
            sortable: false,
            renderCell: ({ row }) => <div>{formatDate(row.updatedAt)}</div>,
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 300,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Link to={adminRoute.eventview.url(row)} title='Thông tin chi tiết sự kiện'>
                  <Button size='small' color='info' variant='outlined'>
                    <InfoOutlined />
                  </Button>
                </Link>
                <Tooltip title='Cập nhật thông tin sự kiện'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpenUpdateEvent(true);
                      setItemChoice(row);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
                <Tooltip title='Xóa sự kiện'>
                  <Button
                    size='small'
                    color='error'
                    onClick={() => {
                      setOpenDeleteEvent(true);
                      setItemChoice(row);
                    }}
                  >
                    <Delete />
                  </Button>
                </Tooltip>
              </div>
            ),
          },
        ]}
      />

      <Dialog open={openAddEvent} maxWidth='md'>
        <PopupEventUpsert onClose={() => setOpenAddEvent(false)} />
      </Dialog>
      {itemChoice && (
        <>
          <Dialog open={openUpdateEvent} maxWidth='md'>
            <PopupEventUpsert event={itemChoice!} onClose={() => setOpenUpdateEvent(false)} />
          </Dialog>
          <Dialog open={openDeleteEvent} maxWidth='md'>
            <PopupEventDelete id={itemChoice!.id} onClose={() => setOpenDeleteEvent(false)} />
          </Dialog>
        </>
      )}
    </Container>
  );
};

export default EventList;
