import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { paperService, queryClient } from 'services';
import { CountDownTimer, TestingQuestionView } from './components';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { formatDateTime } from 'utils/common';
import { privateRoute } from 'routes';
import { enqueueSnackbar } from 'notistack';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

const StudentPaperTesting = () => {
  const navigate = useNavigate();
  const [openSubmit, setOpenSubmit] = useState(false);
  const [openPause, setOpenPause] = useState(false);
  const [questionAnswerIndex, setQuestionAnswerIndex] = useState(0);
  const [testing, setTesting] = useState(true);
  const [openEnd, setOpenEnd] = useState(false);
  const [question, setQuestion] = useState<TestingQuestion | undefined>();
  const [fillQuestionIds, setFillQuestionIds] = useState<number[]>([]);
  const { id, from } = useParams();

  useEffect(() => {
    if (!testing) {
      return;
    }
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = 'Bài test chưa hoàn thành, bạn có chắc chắn muốn đóng trình duyệt?';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: dataTesting, isSuccess } = useQuery(
    [`paperService.getTestingData_${+id!}`],
    () => paperService.getTestingData({ id: +id! }),
    {
      enabled: testing,
      staleTime: Infinity,
      refetchOnMount: false,
    },
  );

  const { questions: items = [], paper } = dataTesting ?? {};

  const onSaveQuestion = (step: number, fill?: boolean) => {
    if (!!question) {
      if ((fill ?? false) && !fillQuestionIds.includes(question.id)) {
        setFillQuestionIds([...fillQuestionIds, question.id]);
      }
      if (!(fill ?? false) && fillQuestionIds.includes(question.id)) {
        setFillQuestionIds(fillQuestionIds.filter((e) => e === question.id));
      }
    }
    if (step !== 0) {
      setQuestionAnswerIndex(questionAnswerIndex + step);
    }
  };

  useEffect(() => {
    setFillQuestionIds(
      (dataTesting?.questions ?? [])
        .filter((e) => (e.answerIds ?? []).length > 0 || !!e.answerContent)
        .map((e) => e.id),
    );
    if (dataTesting && dataTesting.remainSecond <= 0) {
      enqueueSnackbar('Bạn đã hết thời gian làm bài, hệ thống đã tự động nộp bài của bạn');
      navigate(privateRoute.studentPaperResultView.url({ id: dataTesting?.paperResult?.id ?? 0 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTesting]);

  useEffect(() => {
    if (testing && isSuccess && questionAnswerIndex >= 0 && questionAnswerIndex < items.length) {
      const curQuestion = items[questionAnswerIndex];
      if (dataTesting?.paperResult?.id) {
        paperService
          .getMyPaperResultAnswer({
            resultId: dataTesting?.paperResult?.id ?? 0,
            answerId: curQuestion.questionAnswerId ?? 0,
          })
          .then((res) => {
            if (res) {
              setQuestion(res);
            }
          });
      } else {
        setQuestion(curQuestion);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTesting, questionAnswerIndex]);

  const { mutate: submitResult, isLoading: isLoadingSubmit } = useMutation(paperService.postPaperResultSubmit, {
    onSuccess: () => {
      enqueueSnackbar('Nộp bài thành công');
      setTesting(false);
      queryClient.removeQueries([`paperService.getTestingData_${+id!}`]);
      navigate(privateRoute.studentPaperResultView.url({ id: dataTesting?.paperResult?.id!, from: from ?? 'assign' }));
    },
  });

  const { mutate: pauseResult, isLoading: isLoadingPause } = useMutation(paperService.postPaperResultPause, {
    onSuccess: () => {
      enqueueSnackbar('Dừng bài test thành công');
      setTesting(false);
      setOpenPause(false);
    },
  });

  const { mutate: restartResult, isLoading: isLoadingRestart } = useMutation(paperService.postPaperResultRestart, {
    onSuccess: () => {
      enqueueSnackbar('Tiếp tục bài test thành công');
      setTesting(true);
      queryClient.invalidateQueries([`paperService.getTestingData_${+id!}`]);
    },
  });

  const handleClickSubmit = () => {
    submitResult({
      id: dataTesting?.paperResult?.id!,
    });
  };

  const handleClickPause = () => {
    pauseResult({
      id: dataTesting?.paperResult?.id!,
    });
  };

  const handleClickRestart = () => {
    restartResult({
      id: dataTesting?.paperResult?.id!,
    });
  };

  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant='h4' color='primary' className='mb-10 text-center'>
              {paper?.name}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={dataTesting?.classroom ? 6 : 12}>
                <GridInfo label='Học viên' value={dataTesting?.user?.fullName} />
              </Grid>
              {dataTesting?.classroom && (
                <Grid item xs={12} md={6}>
                  <GridInfo
                    label='Lớp học'
                    value={dataTesting?.classroom?.code + ' - ' + dataTesting?.classroom?.name}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <GridInfo
                  label='Thời gian bắt đầu'
                  value={dataTesting?.paperResult?.createdAt ? formatDateTime(dataTesting?.paperResult?.createdAt) : ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GridInfo
                  label='Thời gian kết thúc'
                  value={
                    dataTesting?.paperResult?.expiredDate ? formatDateTime(dataTesting?.paperResult?.expiredDate) : ''
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} className='mt-6 text-center'>
            {isSuccess && testing && dataTesting.remainSecond > 0 && (
              <CountDownTimer
                time={dataTesting.remainSecond ?? 0}
                onComplete={() => {
                  setTesting(false);
                  setOpenEnd(true);
                  setTimeout(() => {
                    handleClickSubmit();
                  }, 5000);
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant='h4' color='primary' className='mb-6 mt-6'>
              <div className='text-center'>NỘI DUNG BÀI LÀM</div>
            </Typography>
            {testing && (dataTesting?.paperResult?.testStatus ?? -1) === 0 && (
              <LoadingButton
                color='success'
                variant='contained'
                className='mt-1 items-start'
                onClick={() => setOpenSubmit(true)}
              >
                Nộp bài
              </LoadingButton>
            )}
            {paper?.allowPause === 1 && testing && (
              <LoadingButton
                loading={isLoadingPause}
                color='warning'
                variant='contained'
                className='ml-2 mt-1 items-end'
                onClick={() => setOpenPause(true)}
              >
                Pause
              </LoadingButton>
            )}
            {paper?.allowPause === 1 && !testing && (
              <LoadingButton
                loading={isLoadingRestart}
                color='success'
                variant='contained'
                className='mt-1'
                onClick={handleClickRestart}
              >
                Restart
              </LoadingButton>
            )}
            {paper && dataTesting?.paperResult && question && (
              <TestingQuestionView
                paperId={paper.id}
                paperResultId={dataTesting.paperResult.id}
                questionIndex={questionAnswerIndex + 1}
                question={question}
                testing={testing}
                isLast={questionAnswerIndex === items.length - 1}
                onSave={onSaveQuestion}
              />
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            <div className='py-1'>
              <Typography variant='h5' color='primary'>
                <div className='text-center'>THÔNG TIN</div>
              </Typography>
            </div>
            <div className='mt-1 py-1'>
              <Grid container spacing={3} className='text-center'>
                <Grid item xs={6}>
                  <GridInfo label='Sô câu' value={dataTesting?.paper?.totalQuestion} />
                </Grid>
                <Grid item xs={6}>
                  <GridInfo label='Đã làm' value={fillQuestionIds.length} />
                </Grid>
                <Grid item xs={6}>
                  <Button size='small' color='success' />
                  <br /> <span>Đã làm</span>
                </Grid>
                <Grid item xs={6}>
                  <Button size='small' color='primary' />
                  <br /> <span>Đang chọn</span>
                </Grid>
              </Grid>
            </div>
            <div className='mt-2 py-1'>
              {items.map((item, index) => (
                <Button
                  key={index}
                  className='m-1'
                  sx={{
                    width: '85px',
                  }}
                  variant={
                    index === questionAnswerIndex || fillQuestionIds.includes(item.id) ? 'contained' : 'outlined'
                  }
                  color={index === questionAnswerIndex ? 'primary' : 'success'}
                  onClick={() => {
                    if (testing) {
                      setQuestionAnswerIndex(index);
                    }
                  }}
                >
                  Câu {index + 1}
                </Button>
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={openPause}>
        <DialogTitle>Xác nhận dừng bài test</DialogTitle>

        <DialogContent>
          <div className='text-center'>Bạn có chắc chắn muốn dừng bài test này không?</div>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            variant='outlined'
            color='inherit'
            onClick={() => {
              setOpenPause(false);
            }}
          >
            Hủy bỏ
          </LoadingButton>
          <LoadingButton variant='contained' loading={isLoadingPause} onClick={handleClickPause}>
            Xác nhận
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openSubmit}>
        <DialogTitle>Xác nhận nộp bài</DialogTitle>

        <DialogContent>
          <div className='text-center'>Bạn có chắc chắn muốn nộp bài test này không?</div>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            variant='outlined'
            color='inherit'
            onClick={() => {
              setOpenSubmit(false);
            }}
          >
            Hủy bỏ
          </LoadingButton>
          <LoadingButton variant='contained' loading={isLoadingSubmit} onClick={handleClickSubmit}>
            Xác nhận
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openEnd} maxWidth='md'>
        <DialogTitle>Thông báo</DialogTitle>

        <DialogContent>
          <div className='text-center'>Bạn đã hết thời gian làm bài, hệ thống sẽ tự động nộp bài của bạn</div>
          <div className='mt-1 text-center'>
            <CountDownTimer time={4} color='#2e8b57' onComplete={() => {}} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StudentPaperTesting;
