import { Button, Dialog, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { homeworkService } from 'services';
import { useState } from 'react';
import PopupCaseStudyRegister from './PopupCaseStudyRegister';
import PopupCaseStudyChange from './PopupCaseStudyChange';

type Props = {
  classroomId: number;
};

const StudentCaseStudy = ({ classroomId }: Props) => {
  const [openCaseStudyRegister, setOpenCaseStudyRegister] = useState(false);
  const [openCaseStudyChange, setOpenCaseStudyChange] = useState(false);
  const { data } = useQuery(
    ['homeworkService.getMyCaseStudy'],
    () => homeworkService.getMyCaseStudy({ id: classroomId }),
    { keepPreviousData: true },
  );
  return (
    <>
      {!data && (
        <>
          <Typography variant='h6' className='mb-6 space-x-3'>
            <span>Bạn chưa đăng ký case study. Vui lòng đăng ký case study để bắt đầu làm BTVN</span>
            <Button
              color='primary'
              onClick={() => {
                setOpenCaseStudyRegister(true);
              }}
            >
              Đăng ký case study
            </Button>
          </Typography>

          <Dialog maxWidth='md' open={openCaseStudyRegister}>
            <PopupCaseStudyRegister classroomId={classroomId} onClose={() => setOpenCaseStudyRegister(false)} />
          </Dialog>
        </>
      )}
      {data && (
        <>
          <Typography variant='h6' className='mb-6 space-x-3'>
            <span>Case study của bạn đã đăng ký: {data.name}</span>
            <Button
              color='secondary'
              onClick={() => {
                setOpenCaseStudyChange(true);
              }}
            >
              Thay đổi case study
            </Button>
          </Typography>
          <Typography variant='h5' className='mb-6 space-x-3'>
            Ngữ cảnh:
          </Typography>
          <TextField multiline fullWidth inputProps={{ readOnly: true }} defaultValue={data.description} />
          <Dialog maxWidth='md' open={openCaseStudyChange}>
            <PopupCaseStudyChange caseStudy={data} onClose={() => setOpenCaseStudyChange(false)} />
          </Dialog>
        </>
      )}
    </>
  );
};

export default StudentCaseStudy;
