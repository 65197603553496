import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { classroomService, courseService } from 'services';

type Props = {
  paper: TestPaper;
  onChange: (...args: any) => void;
};

const PaperQuestionStatisticSearch = ({ paper, onChange }: Props) => {
  const { control, handleSubmit, reset, watch } = useForm<TestPaperQuestionStatisticSearchParams>();
  const courseId = watch('courseId');
  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { data: dataClassroom } = useQuery(['classroomService.fetchClassrooms', { courseId: courseId }], () =>
    classroomService.fetchClassrooms({ courseId: courseId, size: -1 }),
  );

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange({ ...values, paperId: paper.id, type: 'STUDENT' });
    })();
  };

  const handleClickClear = () => {
    reset({
      courseId: '',
      classroomId: '',
      fromDate: null,
      toDate: null,
      textSearch: '',
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Controller
            name='courseId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Khóa học' error={!!error} helperText={error?.message}>
                {(dataCourse?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='classroomId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Lớp học' error={!!error} helperText={error?.message}>
                {(dataClassroom?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='textSearch'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label='Tên/Sô điện thoại/Email'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='fromDate'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Ngày thi từ',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='toDate'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Ngày thi đến',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12} className='mt-2 space-x-3'>
          <Button onClick={handleClickSearch}>Tìm kiếm</Button>{' '}
          <Button variant='outlined' color='inherit' onClick={handleClickClear}>
            <Clear />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PaperQuestionStatisticSearch;
