import { Clear } from '@mui/icons-material';
import { Button, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

type Props = {
  onChange: (...args: any) => void;
};

const CommentSearch = ({ onChange }: Props) => {
  const { control, handleSubmit, reset } = useForm<ListCommentSearchParams>();

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      textSearch: '',
      postText: '',
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <Controller
            name='postText'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Thông tin bài post' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='textSearch'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label='Học viên(Email/Điện thoại/Họ tên'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12} className='mt-2'>
          <Button onClick={handleClickSearch}>Tìm kiếm</Button>
          <Button variant='outlined' color='inherit' onClick={handleClickClear}>
            <Clear />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CommentSearch;
