import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { categoryService, queryClient } from 'services';
import { homeworkService } from 'services';

type Props = PopupController & {
  caseStudy: CaseStudy;
};

const PopupCaseStudyChange = ({ caseStudy, onClose }: Props) => {
  const { control, handleSubmit } = useForm<CaseStudyUpdateBody>();

  const { data: pageProduction } = useQuery(
    ['categoryService.getAllStudyProductions'],
    () => categoryService.getAllStudyProductions(),
    {
      keepPreviousData: true,
    },
  );
  const { list: productions = [] } = pageProduction ?? {};

  const { data: pageDomain } = useQuery(
    ['categoryService.getAllStudyDomains'],
    () => categoryService.getAllStudyDomains(),
    {
      keepPreviousData: true,
    },
  );
  const { list: domains = [] } = pageDomain ?? {};

  const { mutate: updateCaseStudy, isLoading } = useMutation(homeworkService.updateCaseStudy, {
    onSuccess: () => {
      enqueueSnackbar('Thay đổi case study thành công');
      queryClient.invalidateQueries(['homeworkService.getMyCaseStudy']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateCaseStudy({
        ...values,
        id: caseStudy.id,
        classroomId: caseStudy.classroomId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Đăng ký case study</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='name'
              defaultValue={caseStudy.name}
              control={control}
              rules={{
                required: 'Tên case study là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={2}
                  required
                  label='Tên case study'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='domainId'
              defaultValue={caseStudy.domainId}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Domain' error={!!error} helperText={error?.message}>
                  {domains.map((item: any, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='productionId'
              control={control}
              defaultValue={caseStudy.productionId}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Loại SP' error={!!error} helperText={error?.message}>
                  {productions.map((item: any, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Link
              to='https://docs.google.com/document/d/1jSKU6BThV-uAYIDouUSI2ppUzMPF5x81/edit'
              target='_blank'
              className='font-bold hover:text-primary-main'
            >
              Tìm hiểu định nghĩa các loại sản phẩm
            </Link>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              defaultValue={caseStudy.description ?? caseStudy.production?.template}
              control={control}
              render={({ field }) => <TextField {...field} multiline minRows={4} fullWidth label='Ngữ cảnh' />}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Đăng ký
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupCaseStudyChange;
