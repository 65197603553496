import { BadgeOutlined, Check, Clear, Close, PostAdd } from '@mui/icons-material';
import { Button, Chip, Dialog, Grid, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { categoryService, queryClient, registerService, studentService } from 'services';
import PopupStudentEvaluation from './PopupStudentEvaluation';
import { ZaloAddedStatusTypes } from 'utils/common';
import { enqueueSnackbar } from 'notistack';

type Props = {
  classroomId: number;
};

const StudentList = ({ classroomId }: Props) => {
  const [openStudentEvaluation, setOpenStudentEvaluation] = useState(false);
  const [studenSeletected, setStudenSeletected] = useState<Student | null>(null);
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();
  const { control, handleSubmit, reset } = useForm<StudentSearchParams>();
  const [copied, setCopied] = useState(false);

  const { data: pageLevels } = useQuery(
    ['categoryService.getAllEvaluationLevels'],
    () => categoryService.getAllEvaluationLevels(),
    {
      keepPreviousData: true,
    },
  );

  const { data, isFetching } = useQuery(
    ['studentService.fetchStudents', dataSearch],
    () => studentService.fetchStudents({ ...dataSearch, classroomId: classroomId }),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0, emails = '' } = data ?? {};

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onSearchChange({ ...values });
    })();
  };

  const handleClickClear = () => {
    reset({
      phone: '',
      email: '',
      passLevelId: null,
    });
    handleClickSearch();
  };

  const copyEmails = () => {
    navigator.clipboard.writeText(emails).then(() => {
      setCopied(true);
    });
  };

  const { mutate: addZalo } = useMutation(registerService.addZalo, {
    onSuccess: () => {
      enqueueSnackbar('Xác nhận join Zalo thành công');
      queryClient.invalidateQueries(['studentService.fetchStudents']);
    },
  });

  const handleClickSubmitJoinZalo = (registerId: number) => {
    addZalo({
      id: registerId,
    });
  };

  return (
    <>
      <Typography variant='h4' color='primary' className='mb-6'>
        Danh sách học viên
      </Typography>

      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Controller
            name='email'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Email' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='phone'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Số điện thoại' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='passLevelId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Xếp loại' error={!!error} helperText={error?.message}>
                {(pageLevels?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='zaloAdded'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Đã join Zalo' error={!!error} helperText={error?.message}>
                {ZaloAddedStatusTypes.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <div className='flex space-x-3'>
            <Button onClick={handleClickSearch}>Tìm kiếm</Button>
            <Button variant='outlined' color='inherit' onClick={handleClickClear}>
              <Clear />
            </Button>
            <Button color='success' onClick={copyEmails} startIcon={copied ? <Check /> : null}>
              Copy Email
            </Button>
          </div>
        </Grid>
      </Grid>

      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 64}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'user.fullName',
            headerName: 'Họ tên',
            flex: 1,
            minWidth: 220,
            sortable: false,
            renderCell: ({ row }) => <div>{row.user.fullName}</div>,
          },
          {
            field: 'user.Email',
            headerName: 'Email - SĐT',
            flex: 1,
            minWidth: 330,
            sortable: false,
            renderCell: ({ row }) => (
              <>
                <div>
                  {row.user.email}
                  <br />
                  {row.user.phone}
                </div>
              </>
            ),
          },
          {
            field: 'classroom.code',
            headerName: 'Lớp học',
            flex: 1,
            minWidth: 220,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                {row.classroom.code} <br />
                {row.course.name}
              </div>
            ),
          },
          {
            field: 'zaloAdded',
            headerName: 'Join Zalo',
            sortable: false,
            minWidth: 100,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                {row.zaloAdded === 1 && (
                  <Tooltip title='Đã join Zalo'>
                    <Chip label={<Check />} color='success' variant='filled' />
                  </Tooltip>
                )}
                {(row.zaloAdded === null || row.zaloAdded === 0) && (
                  <Tooltip title='Chưa join zalo'>
                    <Chip label={<Close />} />
                  </Tooltip>
                )}
              </div>
            ),
          },
          {
            field: 'passLevel.name',
            headerName: 'Xếp loại',
            flex: 1,
            minWidth: 120,
            sortable: false,
            renderCell: ({ row }) => <div>{row.passLevel?.name}</div>,
          },
          {
            field: 'isPublic',
            headerName: 'Public',
            sortable: false,
            minWidth: 100,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                {row.isPublic === 1 && (
                  <Tooltip title='Cho phép tra cứu trên trang web của trung tâm'>
                    <Chip label={<Check />} color='success' variant='filled' />
                  </Tooltip>
                )}
                {(row.isPublic === null || row.isPublic === 0) && (
                  <Tooltip title='Không public, chỉ admin hoặc học viên đó mới xem được'>
                    <Chip label={<Close />} />
                  </Tooltip>
                )}
              </div>
            ),
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 160,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Tooltip title='Đánh giá học viên'>
                  <Button
                    size='small'
                    color='success'
                    onClick={() => {
                      setStudenSeletected(row);
                      setOpenStudentEvaluation(true);
                    }}
                  >
                    <BadgeOutlined />
                  </Button>
                </Tooltip>
                {(row.zaloAdded === null || row.zaloAdded === 0) && (
                  <Tooltip title='Xác nhận join nhóm zalo'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        handleClickSubmitJoinZalo(row.registerId);
                      }}
                    >
                      <PostAdd />
                    </Button>
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]}
      />
      <Dialog maxWidth='lg' open={openStudentEvaluation}>
        {studenSeletected && (
          <PopupStudentEvaluation onClose={() => setOpenStudentEvaluation(false)} student={studenSeletected!} />
        )}
      </Dialog>
    </>
  );
};

export default StudentList;
