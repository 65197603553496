import { Paper } from '@mui/material';

type Props = {
  course: Course;
};

const TabCourseTeacher = ({ course }: Props) => {
  return (
    <Paper className='mx-auto max-w-2xl bg-[orange]/10 p-4'>
      <div className='flex space-x-10'>
        <span className='text-black/60'>Giảng viên:</span>
        {course.lecturerList.length === 0 && (
          <div className='space-y-2'>
            <li>Mrs Ánh - Admin Page</li>
            <li>Mrs Ngọc - Phó phòng thanh toán Viettel Digital</li>
          </div>
        )}
        {course.lecturerList.length > 0 && (
          <div className='space-y-2'>
            {course.lecturerList.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </div>
        )}
      </div>
    </Paper>
  );
};

export default TabCourseTeacher;
