import { Button, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

type Props = {
  paperIds: number[];
  onChange: (...args: any) => void;
};

const PaperClassroomSearch = ({ paperIds, onChange }: Props) => {
  const { control, handleSubmit } = useForm<TestPaperAssignSearchParams>();

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange({ ...values, paperIds: paperIds, type: 'CLASSROOM' });
    })();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Controller
            name='name'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Tên lớp học' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12} className='mt-2'>
          <Button onClick={handleClickSearch}>Tìm kiếm</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PaperClassroomSearch;
