import { client } from './axios';

const fetchHomeworks = (body: HomeworkSearchParams): Promise<PaginateResponse<Homework>> =>
  client.post(`/api/v1/homeworks/list`, body);
const fetchClassroomHomeworks = ({ classroomId }: { classroomId: number }): Promise<PaginateResponse<Homework>> =>
  client.get(`/api/v1/homeworks/classroom?classroomId=${classroomId}`);
const getHomeworkInfo = ({ id }: ByID): Promise<Homework> => client.get(`/api/v1/homeworks/${id}`);
const addHomework = (body: HomeworkCreateBody): Promise<ByID> => client.post(`/api/v1/homeworks`, body);
const updateHomework = ({ id, ...body }: HomeworkUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/homeworks/${id}`, body);
const deleteHomework = ({ id }: ByID): Promise<SuccessResponse> => client.delete(`/api/v1/homeworks/${id}`);

const postHomeworkClassroomAssign = (body: HomeworkClassroomAssignBody) =>
  client.post(`/api/v1/homeworks/assign-classroom`, body);

const fetchHomeworkAnswers = (body: HomeworkAnswerSearchParams): Promise<ListHomeworkAnswer> =>
  client.post(`/api/v1/homeworks/answers/list`, body);
const getHomeworkAnswerInfo = ({ id }: ByID): Promise<HomeworkAnswer> => client.get(`/api/v1/homeworks/answers/${id}`);
const getHomeworkAnswerCaseStudyInfo = ({ id }: ByID): Promise<CaseStudy> =>
  client.get(`/api/v1/homeworks/answers/${id}/study`);
const fetchHomeworkAnswerLogs = ({ id }: ByID): Promise<PaginateResponse<HomeworkAnswerLog>> =>
  client.get(`/api/v1/homeworks/answers/${id}/logs`);
const postHomeworkSubmit = (body: HomeworkSubmitBody) => client.post(`/api/v1/homeworks/answers/submit`, body);
const postHomeworkResubmit = ({ id, ...body }: HomeworkResubmitBody) =>
  client.post(`/api/v1/homeworks/answers/${id}/resubmit`, body);
const postHomeworkReview = ({ id, ...body }: HomeworkReviewBody) =>
  client.post(`/api/v1/homeworks/answers/${id}/review`, body);

const fetchCaseStudies = (body: CaseStudySearchParams): Promise<PaginateResponse<CaseStudy>> =>
  client.post(`/api/v1/studies/list`, body);
const addCaseStudy = (body: CaseStudyCreateBody): Promise<ByID> => client.post(`/api/v1/studies`, body);
const updateCaseStudy = ({ id, ...body }: CaseStudyUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/studies/${id}`, body);
const getCaseStudyInfo = ({ id }: ByID): Promise<CaseStudy> => client.get(`/api/v1/studies/${id}`);
const getMyCaseStudy = ({ id }: ByID): Promise<CaseStudy> => client.get(`/api/v1/studies/me/${id}/current`);
const deleteCaseStudy = ({ id }: ByID): Promise<SuccessResponse> => client.delete(`/api/v1/studies/${id}`);

const fetchHomeworkClassrooms = (): Promise<PaginateResponse<HomeworkClassroom>> =>
  client.get(`/api/v1/homeworks/me/classrooms`);
const fetchMyHomework = ({ id }: ByID): Promise<StudentHomework> => client.get(`/api/v1/homeworks/me/${id}`);

const homeworkService = {
  fetchHomeworks,
  fetchClassroomHomeworks,
  getHomeworkInfo,
  addHomework,
  updateHomework,
  deleteHomework,

  postHomeworkClassroomAssign,

  fetchHomeworkAnswers,
  getHomeworkAnswerInfo,
  getHomeworkAnswerCaseStudyInfo,
  fetchHomeworkAnswerLogs,
  postHomeworkSubmit,
  postHomeworkResubmit,
  postHomeworkReview,

  fetchCaseStudies,
  addCaseStudy,
  updateCaseStudy,
  getCaseStudyInfo,
  getMyCaseStudy,
  deleteCaseStudy,

  fetchHomeworkClassrooms,
  fetchMyHomework,
};

export default homeworkService;
