import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { classroomService } from 'services';
import { CardClassroom } from '.';

type Props = {
  course: Course;
};

const ClassroomList = ({ course }: Props) => {
  const { dataSearch } = useSearch({ courseId: course.id, size: -1 });

  const { data, isFetching } = useQuery(
    ['classroomService.fetchClassroomsPublic', dataSearch],
    () => classroomService.fetchClassroomsPublic(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [] } = data ?? {};

  return (
    <>
      <div className='flex flex-col space-y-6'>
        {items.map((classroom) => (
          <CardClassroom key={classroom.id} course={course} item={classroom} />
        ))}
      </div>
      {!isFetching && items.length === 0 && (
        <div className='text-center font-bold'>HIỆN TẠI KHOÁ HỌC NÀY CHƯA CÓ LỚP HỌC</div>
      )}
    </>
  );
};

export default ClassroomList;
