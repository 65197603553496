import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { formatDateTime } from 'utils/common';
import { Avatar, Box, Button, CardActions } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { commentService, queryClient } from 'services';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { store } from 'reducers/store';
import MyEditor from './CKEditorComponent';

interface ViewCommentProps {
  comment: any;
  postId?: string;
}
interface CommentDetail {
  id: number;
  parentId?: number;
  userId?: number;
  postId?: string;
  createdAt?: ISODateString;
  userName: string;
  commentText?: string;
  replies?: CommentDetail[];
}
interface ActiveReplyState {
  parentId: number | null;
  content: string;
}

export default function Comment(props: Partial<ViewCommentProps>) {
  const mapReplyComments = (comments: CommentDetail[]) => {
    if (!comments.length) return [];
    const res: { [key: number]: CommentDetail } = {};
    for (const comment of comments) {
      if (!comment.parentId) {
        res[comment.id] = { ...comment, replies: [] };
      } else {
        res[comment.parentId].replies?.push(comment);
      }
    }

    return Object.values(res);
  };
  const { isLoggedIn, id: userId, listRoles }: ProfileType = store.getState().profile;
  const { control, handleSubmit, reset } = useForm<CommentCreateBody>();
  const comments = mapReplyComments(props.comment);
  const [activeReply, setActiveReply] = useState<ActiveReplyState>({ parentId: null, content: '' });
  const [replyText, setReplyText] = useState('');

  const { mutate: addComment, isLoading: isLoadingAdd } = useMutation(commentService.addComment, {
    onSuccess: () => {
      enqueueSnackbar('Comment thành công');
      queryClient.invalidateQueries(['commentService.fetchCommentsByPostUUID']);
      reset({ commentText: '' });
    },
  });

  const handleClickSubmitAdd = () => {
    handleSubmit((values) => {
      addComment({
        ...values,
        userId: isLoggedIn ? userId! : 1210,
        postId: props.postId?.toString() ?? '',
      });
    })();
  };

  const { mutate: deleteComment, isLoading: isLoadingDelete } = useMutation(commentService.deleteComment, {
    onSuccess: () => {
      enqueueSnackbar('Xoá comment thành công');
      queryClient.invalidateQueries([`commentService.fetchCommentsByPostUUID`]);
    },
  });

  const handleDelete = (comment: any) => {
    deleteComment({ id: comment.id });
  };

  const handleReplyClick = (commentId: number, content = '') => {
    setActiveReply({ parentId: commentId, content });
    setReplyText(content);
  };

  const handleReplySubmit = (event: any) => {
    event.preventDefault();
    const trimmedReplyText = replyText.trim();
    if (activeReply.parentId !== null && trimmedReplyText.length > 0) {
      const commentData = {
        commentText: trimmedReplyText,
        userId: isLoggedIn ? userId! || 0 : 1210,
        postId: props.postId?.toString() ?? '',
        parentId: activeReply.parentId,
      };
      addComment(commentData, {
        onSuccess: () => {
          setActiveReply({ parentId: null, content: '' });
          setReplyText('');
        },
      });
    }
  };
  return (
    <Grid container spacing={3} justifyContent='center'>
      {comments.length > 0 ? (
        comments.map((comment: any, index: number) => (
          <Grid key={index} item xs={12}>
            <Card key={index} sx={{ mb: 2 }}>
              <CardContent>
                <Box display='flex' alignItems='center' mb={2}>
                  <Avatar src={comment.userAvatar} alt={comment.userName} sx={{ mr: 1 }} />
                  <Typography variant='subtitle2' component='span' sx={{ fontWeight: 'bold', cursor: 'pointer' }}>
                    {comment.userName} - {comment?.createdAt && formatDateTime(comment.createdAt)}
                  </Typography>
                </Box>
                <Typography
                  variant='body1'
                  component='div'
                  dangerouslySetInnerHTML={{ __html: comment?.commentText || '' }}
                />
              </CardContent>
              <CardActions>
                {listRoles?.includes('ADMIN') && (
                  <Button
                    size='small'
                    color='secondary'
                    onClick={() => handleDelete(comment)}
                    disabled={isLoadingDelete}
                  >
                    Delete
                  </Button>
                )}
                <Button size='small' onClick={() => handleReplyClick(comment.id)}>
                  Reply
                </Button>
              </CardActions>
              {activeReply.parentId === comment.id && (
                <CardContent>
                  <form onSubmit={handleReplySubmit}>
                    <MyEditor data={replyText ?? ''} onChange={setReplyText} onlyContent={true} />
                    <LoadingButton
                      type='submit'
                      loading={isLoadingAdd}
                      variant='contained'
                      color='primary'
                      className='mt-2'
                    >
                      Post Reply
                    </LoadingButton>
                  </form>
                </CardContent>
              )}
              {comment.replies && comment.replies.length > 0 && (
                <Box mt={2}>
                  <Typography variant='subtitle1' sx={{ fontWeight: 'bold', mb: 1, ml: 2 }}>
                    Replies:
                  </Typography>
                  {(comment.replies ?? []).map((reply: any, replyIndex: number) => (
                    <Card key={replyIndex} sx={{ mb: 2, ml: 2 }}>
                      <CardContent>
                        <Box display='flex' alignItems='center' mb={2}>
                          <Avatar src={reply.userAvatar} alt={reply.userName} sx={{ mr: 1 }} />
                          <Typography
                            variant='subtitle2'
                            component='span'
                            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                          >
                            {reply.userName} - {reply?.createdAt && formatDateTime(reply.createdAt)}
                          </Typography>
                        </Box>
                        <Typography
                          variant='body1'
                          component='div'
                          dangerouslySetInnerHTML={{ __html: reply?.commentText || '' }}
                        />
                      </CardContent>
                      <CardActions>
                        {listRoles?.includes('ADMIN') && (
                          <Button
                            size='small'
                            color='secondary'
                            onClick={() => handleDelete(reply)}
                            disabled={isLoadingDelete}
                          >
                            Delete
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  ))}
                </Box>
              )}
            </Card>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography variant='body1'>No comments yet.</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Controller
          name='commentText'
          control={control}
          rules={{
            required: 'Cannot post comment',
            validate: (value) => {
              return value && !!value.trim();
            },
          }}
          render={({ field }) => <MyEditor data={field.value ?? ''} onlyContent={true} onChange={field.onChange} />}
        />
        <LoadingButton
          className='mt-2'
          loading={isLoadingAdd}
          onClick={handleClickSubmitAdd}
          variant='contained'
          color='primary'
        >
          Post Comment
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
