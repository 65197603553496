import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { paperService } from 'services';
import QuestionStatisticView from './QuestionStatisticView';
import CardUserQuestionAnswer from './CardUserQuestionAnswer';

type Props = PopupController & {
  paperId: number;
  question: TestQuestionStatistic;
  questionIndex: number;
  param: TestPaperQuestionStatisticSearchParams;
};
const PopupQuestionAnswerList = ({ paperId, question, questionIndex, param, onClose }: Props) => {
  const { data } = useQuery(
    [`paperService.fetchPaperQuestionResultsStatistic_${paperId}_${question.id}`],
    () =>
      paperService.fetchPaperQuestionResultsStatistic({
        ...param,
        paperId: paperId,
        questionId: question.id,
        size: -1,
      }),
    { keepPreviousData: true },
  );
  const { list: items = [] } = data ?? {};

  return (
    <>
      <DialogTitle>Chi tiết thống kê theo câu hỏi</DialogTitle>

      <DialogContent>
        <QuestionStatisticView question={question} questionIndex={questionIndex} />
        <div className='mt-6 flex flex-col space-y-6'>
          {items?.map((item, index) => (
            <CardUserQuestionAnswer key={index} paperResult={item} questionIndex={questionIndex} />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupQuestionAnswerList;
