import { Edit, InfoOutlined } from '@mui/icons-material';
import { Button, Container, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { adminRoute } from 'routes';
import { postService } from 'services';
import { PostStatusTypes } from 'utils/common';
import { formatDateTime } from 'utils/common';
import { PostSearch, PopupPostUpsert } from './components';

const PostList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  useEffect(() => {}, [dataSearch]);

  const { data, isFetching } = useQuery(
    ['postService.fetchPosts', dataSearch],
    () => postService.fetchPosts(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const { data: dataTopic, isFetching: dataTopicFetching } = useQuery(
    ['postService.fetchTopics'],
    () => postService.fetchTopics(dataSearch),
    { keepPreviousData: true },
  );

  const getTopicTitle = (topicId: number) => {
    if (dataTopicFetching || !dataTopic) return null;
    const topic = dataTopic.list.find((topic) => topic.id === topicId);
    return topic ? topic.title : null;
  };

  const sharingTypeDataRequest = useQuery(
    ['postService.fetchSharingTypes'],
    () => postService.fetchSharingTypes(dataSearch),
    { keepPreviousData: true },
  );
  const dataSharingType = sharingTypeDataRequest.data;

  const dataSharingTypeFetching = sharingTypeDataRequest.isFetching;
  const getSharingType = (SharingTypeId: number) => {
    if (dataSharingTypeFetching || !dataSharingType) return null;
    const sharingtype = dataSharingType.list.find((sharingType) => sharingType.id === SharingTypeId);
    return sharingtype ? sharingtype.type : null;
  };

  const [openUpdatePost, setOpenUpdatePost] = useState(false);
  const [itemChoice, setItemChoice] = useState<Post>();

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách bài viết
      </Typography>

      <PostSearch onChange={onSearchChange} />
      <div className='mt-6 flex justify-end space-x-3'>
        <Link to={adminRoute.PostAdd.path}>
          <Button color='success'>Thêm mới</Button>
        </Link>
      </div>
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 64}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'sharingtype',
            headerName: 'Loại chia sẻ',
            flex: 1,
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => <div className='font-bold'>{getSharingType(row.sharingTypeId)}</div>,
          },
          {
            field: 'topic',
            headerName: 'Chủ đề',
            flex: 1,
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => <div className='font-bold'>{getTopicTitle(row.topicId)}</div>,
          },
          {
            field: 'author',
            headerName: 'Người viết',
            flex: 1,
            minWidth: 150,
            sortable: false,
            renderCell: ({ row }) => <div>{row.author}</div>,
          },
          {
            field: 'title',
            headerName: 'Tên bài viết',
            flex: 1,
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => <div className='font-bold'>{row.title}</div>,
          },
          {
            field: 'createdAt',
            headerName: 'Ngày viết',
            minWidth: 200,
            sortable: false,
            renderCell: ({ row }) => <div>{formatDateTime(row.createdAt)}</div>,
          },
          {
            field: 'status',
            headerName: 'Trạng thái',
            minWidth: 160,
            sortable: false,
            renderCell: ({ row }) => <div>{PostStatusTypes.find((item) => item.value === row.status)?.label}</div>,
          },
          // {
          //   field: 'avatarUrl',
          //   headerName: 'Ảnh bìa',
          //   minWidth: 160,
          //   sortable: false,
          //   renderCell: ({ row }) => <img src={row.avatarUrl} />,
          // },
          // {
          //   field: 'shortDescription',
          //   headerName: 'Tóm tắt bài viết',
          //   flex: 1,
          //   minWidth: 300,
          //   sortable: false,
          //   renderCell: ({ row }) => (
          //     <textarea
          //       className='font-bold'
          //       style={{ width: '100%', height: '100%', border: 'none', resize: 'none' }}
          //       value={row.shortDescription}
          //       readOnly
          //     />
          //   ),
          // },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 150,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Link to={adminRoute.PostView.url(row)}>
                  <Button size='small' color='info' variant='outlined'>
                    <InfoOutlined />
                  </Button>
                </Link>
                <Tooltip title='Cập nhật bài viết'>
                  <Link to={adminRoute.PostEdit.url(row)}>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenUpdatePost(true);
                        setItemChoice(row);
                      }}
                    >
                      <Edit />
                    </Button>
                  </Link>
                </Tooltip>
                {/* <Tooltip title='Cập nhật bài viết'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpenUpdatePost(true);
                      setItemChoice(row);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip> */}
              </div>
            ),
          },
        ]}
      />

      <Dialog open={openUpdatePost} maxWidth='md'>
        <PopupPostUpsert
          dataTopic={dataTopic?.list}
          dataSharingType={dataSharingType?.list}
          post={itemChoice!}
          onClose={() => setOpenUpdatePost(false)}
        />
      </Dialog>
    </Container>
  );
};

export default PostList;
