import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { postService } from 'services';
import { PostStatusTypes } from 'utils/common';

type Props = {
  onChange: (...args: any) => void;
};

const PostSearch = ({ onChange }: Props) => {
  const { control, handleSubmit, reset } = useForm<PostSearchParams>();

  const { data: dataSharingType } = useQuery(['postService.fetchSharingTypes'], () =>
    postService.fetchSharingTypes({}),
  );
  const { data: dataTopic } = useQuery(['postService.fetchTopics'], () => postService.fetchTopics({}));

  const handleClickSearch = () => {
    handleSubmit((values) => {
      if (!values.status) values.status = null;
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      topicId: '',
      sharingTypeId: '',
      status: '',
      createdAt: null,
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Controller
            name='topicId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Chủ đề' error={!!error} helperText={error?.message}>
                {(dataTopic?.list ?? []).map((item, index: any) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='sharingTypeId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Loại chia sẻ' error={!!error} helperText={error?.message}>
                {(dataSharingType?.list ?? []).map((item, index: any) => (
                  <MenuItem key={index} value={item.id}>
                    {item.type}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='status'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Trạng thái' error={!!error} helperText={error?.message}>
                {PostStatusTypes.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name='createdAt'
            defaultValue={null}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                format='dd/MM/yyyy'
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: 'Tạo lúc',
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <div className='mt-6 flex justify-end space-x-3'>
        <Button onClick={handleClickSearch}>Tìm kiếm</Button>
        <Button variant='outlined' color='inherit' onClick={handleClickClear}>
          <Clear />
        </Button>
      </div>
    </>
  );
};

export default PostSearch;
