import { Box, Button, Container, Dialog, Tab, Tabs, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { courseService } from 'services';
import {
  ClassroomList,
  PopupRegisterEmail,
  TabCourseContent,
  TabCourseFee,
  TabCourseTarget,
  TabCourseTeacher,
} from './components';
import { useWindowSize } from 'hooks';

const CourseView = () => {
  const { id: courseId } = useParams();
  const { isMobile } = useWindowSize();

  const [activeTab, setActiveTab] = useState(0);
  const [openRegisterEmail, setOpenRegisterEmail] = useState(false);

  const { data } = useQuery(['courseService.getCourseInfo', courseId], () =>
    courseService.getCourseInfo({ id: +courseId! }),
  );

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h3' color='primary' className='mb-6 text-center'>
        {data?.name}
      </Typography>
      <Box
        sx={{
          flexGrow: 10,
          maxWidth: '100%',
          display: 'flex',
          bgcolor: 'background.paper',
        }}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          variant='scrollable'
          scrollButtons='auto'
          orientation={isMobile ? 'vertical' : 'horizontal'}
        >
          <Tab label='Lớp tuyển sinh' />
          <Tab label='Học phí' />
          <Tab label='Đối tượng phù hợp' />
          <Tab label='Nội dung khoá học' />
          <Tab label='Giảng viên' />
        </Tabs>
      </Box>
      <div className='py-6'>
        {data && (
          <>
            {activeTab === 0 && <ClassroomList course={data} />}
            {activeTab === 1 && <TabCourseFee course={data} />}
            {activeTab === 2 && <TabCourseTarget course={data} />}
            {activeTab === 3 && <TabCourseContent course={data} />}
            {activeTab === 4 && <TabCourseTeacher course={data} />}
          </>
        )}
      </div>

      <div className='mt-10 text-center'>
        <Button size='large' color='secondary' onClick={() => setOpenRegisterEmail(true)}>
          Đăng ký nhận thông báo khóa học
        </Button>
      </div>

      <Dialog open={openRegisterEmail} maxWidth='md'>
        <PopupRegisterEmail onClose={() => setOpenRegisterEmail(false)} />
      </Dialog>
    </Container>
  );
};

export default CourseView;
