import { LoadingButton } from '@mui/lab';
import { Autocomplete, Checkbox, DialogContent, FormControl, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { courseService, paperService, postService, queryClient } from 'services';
import { useSearch } from 'hooks';
import { PostStatusTypes } from 'utils/common';
import { MyEditor } from './components';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const PostEdit = () => {
  const [courseIds, setCourseIds] = useState<number[]>([]);
  const [paperIds, setPaperIds] = useState<number[]>([]);

  const { dataSearch } = useSearch();
  const { uuid } = useParams();
  let { control, handleSubmit } = useForm<PostCreateBody>();

  const { data } = useQuery(
    ['postService.fetchAdminPostsByUUID', uuid],
    () => postService.fetchAdminPostsByUUID({ uuid: uuid! }),
    {
      onSuccess: (e) => {
        setCourseIds(e.allowCourseIds);
        setPaperIds((e.papers ?? []).map((e) => e.id));
      },
    },
  );

  const post: Post | null = data ?? null;

  const { data: dataTopic } = useQuery(
    ['postService.fetchTopics', dataSearch],
    () => postService.fetchTopics(dataSearch),
    { keepPreviousData: true },
  );

  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());

  const { data: dataPaper } = useQuery(['paperService.fetchAllPapers'], () =>
    paperService.fetchAllPapers({ isActive: 1, size: -1 }),
  );

  const selectedCourses = useMemo(
    () => (dataCourse?.list ?? []).filter((v) => courseIds.includes(v.id)),
    [courseIds, dataCourse?.list],
  );

  const selectedPapers = useMemo(
    () => (dataPaper?.list ?? []).filter((v) => paperIds.includes(v.id)),
    [paperIds, dataPaper?.list],
  );

  const sharingTypeDataRequest = useQuery(
    ['postService.fetchSharingTypes', dataSearch],
    () => postService.fetchSharingTypes(dataSearch),
    { keepPreviousData: true },
  );
  const dataSharingType = sharingTypeDataRequest.data;

  const { mutate: updatePost, isLoading: isLoadingUpdate } = useMutation(postService.updatePost, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật bài viết thành công');
      queryClient.invalidateQueries(['postService.fetchPosts']);
    },
  });

  const handleClickUpdate = () => {
    handleSubmit((values) => {
      updatePost({
        ...values,
        CourseIds: courseIds ?? [],
        PaperIds: paperIds,
        id: post!.id,
      });
    })();
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              sx={{
                '.MuiInputAdornment-positionEnd': {
                  display: 'none',
                  marginRight: '19px',
                },
              }}
            >
              <Autocomplete
                multiple
                id='couseId'
                value={selectedCourses}
                options={dataCourse?.list ?? []}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                )}
                onChange={(_event: any, newValue: Course[]) => {
                  setCourseIds(newValue.map((e) => e.id));
                }}
                renderInput={(params) => <TextField {...params} label='Khóa học' placeholder='Chọn khóa học' />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              sx={{
                '.MuiInputAdornment-positionEnd': {
                  display: 'none',
                  marginRight: '19px',
                },
              }}
            >
              <Autocomplete
                multiple
                id='paperId'
                value={selectedPapers}
                options={dataPaper?.list ?? []}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                )}
                onChange={(_event: any, newValue: TestPaper[]) => {
                  setPaperIds(newValue.map((e) => e.id));
                }}
                renderInput={(params) => <TextField {...params} label='Bài test' placeholder='Chọn bài test' />}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='SharingTypeId'
              control={control}
              rules={{
                required: 'Loại chia sẻ là bắt buộc',
              }}
              defaultValue={post.sharingTypeId}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect
                  {...field}
                  required
                  fullWidth
                  label='Loại chia sẻ'
                  error={!!error}
                  helperText={error?.message}
                >
                  {(dataSharingType?.list ?? []).map((item: SharingType, index: number) => (
                    <MenuItem key={index} value={item.id}>
                      {item.type}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='TopicId'
              control={control}
              rules={{
                required: 'Chủ đề là bắt buộc',
              }}
              defaultValue={post.topicId}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect
                  {...field}
                  required
                  fullWidth
                  label='Chọn chủ đề'
                  error={!!error}
                  helperText={error?.message}
                >
                  {(dataTopic?.list ?? []).map((item: Topic, index: number) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='Status'
              control={control}
              defaultValue='ACTIVE'
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Trạng thái' error={!!error} helperText={error?.message}>
                  {PostStatusTypes.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='Author'
              control={control}
              defaultValue={post.author}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Tác giả' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='Title'
              control={control}
              defaultValue={post.title}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Tên bài viết' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='AvatarUrl'
              control={control}
              defaultValue={post.avatarUrl}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Ảnh bìa' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='ShortDescription'
              control={control}
              defaultValue={post.shortDescription}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Mô tả ngắn'
                  multiline
                  rows={4}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='PostContent'
              control={control}
              defaultValue={post.postContent}
              render={({ field }) => <MyEditor data={field.value} onChange={field.onChange} />}
            />
          </Grid>
          <Grid item sx={{ position: 'fixed', bottom: '1.5rem', right: '1.5rem' }}>
            <div className='flex justify-end space-x-3'>
              <LoadingButton variant='contained' loading={isLoadingUpdate} onClick={handleClickUpdate}>
                Cập nhật
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default PostEdit;
