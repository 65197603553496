import { Clear } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Container, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { courseService, studentService } from 'services';
import { StudentStatusTypes, formatDateTime } from 'utils/common';

type InfoProps = {
  label?: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      {label && <span>{label}: </span>}
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

const StudentRanking = () => {
  const [isSearch, setIsSearch] = useState(false);
  const { dataSearch, onSearchChange } = useSearch();
  const { control, handleSubmit, reset } = useForm<StudentSearchParams>();

  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());

  const { data: student, isFetching } = useQuery(
    ['studentService.getPublicStudent', dataSearch],
    () => studentService.getPublicStudent({ ...dataSearch }),
    {
      keepPreviousData: true,
    },
  );

  const register = student?.register;

  const fileHashId = student?.certificateFileHash;

  const { data: imageData } = useQuery(
    ['studentService.gePublicStudentImage', student?.id, fileHashId],
    () => studentService.gePublicStudentImage({ id: student?.id!, fileHashId: fileHashId! }),
    {
      keepPreviousData: true,
      enabled: fileHashId != null && fileHashId !== '' && !!student?.id,
    },
  );

  const handleClickSearch = () => {
    setIsSearch(true);
    handleSubmit((values) => {
      onSearchChange({ ...values });
    })();
  };

  const handleClickClear = () => {
    reset({
      phoneOrEmail: '',
      courseId: null,
    });
    handleClickSearch();
  };

  return (
    <>
      <Container className='py-6'>
        <Typography variant='h4' color='primary' className='mb-10'>
          Tra cứu đánh giá học viên
        </Typography>

        <Grid container spacing={4}>
          <Grid item md={3} xs={12}>
            <Controller
              name='phoneOrEmail'
              defaultValue=''
              control={control}
              rules={{
                required: 'Số điện thoại/Email là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Số điện thoại/Email'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='courseId'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Khóa học' error={!!error} helperText={error?.message}>
                  {(dataCourse?.list ?? []).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <div className='mt-4 flex justify-end space-x-3'>
              <LoadingButton variant='contained' loading={isFetching} onClick={handleClickSearch}>
                Tìm kiếm
              </LoadingButton>
              <Button variant='outlined' color='inherit' onClick={handleClickClear}>
                <Clear />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
      {isSearch && !student && <div className='text-center font-bold'>KHÔNG TÌM THẤY HỌC VIÊN</div>}
      {student && (
        <Container maxWidth='xl' className='py-6'>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <GridInfo label='Khóa học đăng ký' />
            </Grid>
            <Grid item md={8} xs={12}>
              <GridInfo value={student?.course?.name + ' - ' + student?.classroom?.name} />
            </Grid>
            <Grid item xs={4}>
              <GridInfo label='Ngày đăng ký' />
            </Grid>
            <Grid item xs={8}>
              <GridInfo value={register?.registerAt ? formatDateTime(register?.registerAt) : ''} />
            </Grid>
            {student?.isPublic === 1 && (
              <>
                <Grid item xs={4}>
                  <GridInfo label='Kết quả' />
                </Grid>
                <Grid item xs={8}>
                  <GridInfo value={student?.passLevel?.name} />
                </Grid>
                <Grid item md={4} xs={12}>
                  <GridInfo label='Chi tiết đánh giá' />
                </Grid>
                <Grid item md={8} xs={12}>
                  <GridInfo value={student?.detail} />
                </Grid>
                <Grid item xs={4}>
                  <GridInfo label='Link bài test' value='' />
                </Grid>
                <Grid item xs={8}>
                  <div>
                    {student?.testLink && (
                      <Link
                        to={student?.testLink}
                        target='_blank'
                        onClick={() => {}}
                        className='text-primary-main hover:text-primary-dark'
                      >
                        {student?.testLink}
                      </Link>
                    )}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <GridInfo label='Link chứng chỉ' value='' />
                </Grid>
                <Grid item xs={8}>
                  {fileHashId && (
                    <div>
                      <img
                        height={300}
                        width={300}
                        src={`${window.URL.createObjectURL(new Blob([imageData!]))}`}
                        alt='Ảnh chứng chỉ'
                        loading='lazy'
                      />
                    </div>
                  )}
                </Grid>
              </>
            )}
            {student?.isPublic === 0 && (
              <>
                <Grid item xs={4}>
                  <GridInfo label='Trạng thái học' />
                </Grid>
                <Grid item xs={8}>
                  <span className='font-bold'>
                    {StudentStatusTypes.find((item) => item.value === student?.status)?.label}
                  </span>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      )}
    </>
  );
};

export default StudentRanking;
