import { UploadFileType } from 'models/File';
import { client } from './axios';

const uploadFile = (body: FormData): Promise<UploadFileType> => client.post(`/api/v1/files/upload`, body);
const downloadFile = (key: string): Promise<BlobPart> =>
  client.get(`/api/v1/files/download/${key}`, { responseType: 'arraybuffer' });
const downloadTemplateFile = (fileName: string): Promise<BlobPart> =>
  client.get(`/api/v1/files/templates?fileName=${fileName}`, { responseType: 'arraybuffer' });

export default {
  uploadFile,
  downloadTemplateFile,
  downloadFile,
};
