import { Box, DialogActions, DialogContent, DialogTitle, Grid, Tab, Tabs } from '@mui/material';
import { formatDateTime } from 'utils/common';
import { LoadingButton } from '@mui/lab';
import PaperResultHisIpList from './PaperResultHisIpList';
import PaperResultHisFullList from './PaperResultHisFullList';
import { useState } from 'react';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

type Props = PopupController & {
  paperResult: TestPaperResult;
};

const PopupPaperResultHis = ({ paperResult, onClose }: Props) => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <DialogTitle>Lịch sử làm bài test</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={paperResult.classroom ? 6 : 12} className='mt-1'>
            <GridInfo label='Học viên' value={paperResult.user?.fullName} />
          </Grid>
          {paperResult.classroom && (
            <Grid item xs={12} md={6}>
              <GridInfo label='Lớp học' value={paperResult.classroom?.code + ' - ' + paperResult.classroom?.name} />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <GridInfo
              label='Thời gian bắt đầu'
              value={paperResult.createdAt ? formatDateTime(paperResult.createdAt) : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GridInfo
              label='Thời gian kết thúc'
              value={paperResult.savedAt ? formatDateTime(paperResult.savedAt) : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                flexGrow: 10,
                maxWidth: '100%',
                display: 'flex',
                bgcolor: 'background.paper',
              }}
            >
              <Tabs
                value={activeTab}
                onChange={(event, value) => setActiveTab(value)}
                variant='scrollable'
                scrollButtons='auto'
              >
                <Tab label='TẤT CẢ' />
                <Tab label='DANH SÁCH IP' />
              </Tabs>
            </Box>
            <div className='py-6'>
              {activeTab === 0 && <PaperResultHisFullList paperResult={paperResult} />}
              {activeTab === 1 && <PaperResultHisIpList paperResult={paperResult} />}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupPaperResultHis;
