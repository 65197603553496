import { AppProvider } from 'containers';
import { AuthLayout, PublicLayout } from 'layouts';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/auth/*' element={<AuthLayout />} />
          <Route path='/*' element={<PublicLayout />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
