import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { quizService, queryClient } from 'services';

type Props = PopupController & {
  id: number;
};

const PopupEventDelete = ({ id, onClose }: Props) => {
  const { mutate: deleteQuizEvent, isLoading } = useMutation(quizService.deleteQuizEvent, {
    onSuccess: () => {
      enqueueSnackbar('Xóa sự kiện thành công');
      queryClient.invalidateQueries(['quizService.fetchQuizEvents']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    deleteQuizEvent({
      id: id,
    });
  };
  return (
    <>
      <DialogTitle>Xác nhận xóa sự kiện</DialogTitle>

      <DialogContent>
        <div className='text-center'>Bạn có chắc chắn muốn xóa sự kiện này không?</div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupEventDelete;
