import { store } from 'reducers/store';
import { API_URL } from '../../../../src/env';

class MyUploadAdapter {
  private loader: any;

  constructor(loader: any) {
    this.loader = loader;
  }

  // Starts the upload process.
  upload(): Promise<any> {
    const { accessToken }: ProfileType = store.getState().profile;
    return this.loader.file.then(
      (file: File) =>
        new Promise((resolve, reject) => {
          const data = new FormData();
          data.append('file', file);

          // Your API endpoint for the backend
          fetch(`${API_URL}/api/v1/posts-admin/upload-image`, {
            method: 'POST',
            body: data,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then((result) => {
              resolve({
                default: result.url, // The URL of the uploaded image
              });
            })
            .catch((error) => {
              console.error('Upload error:', error);
              reject('Upload failed');
            });
        }),
    );
  }

  // Aborts the upload process.
  abort(): void {
    // Implement if necessary. For example, cancel the request.
  }
}

export default MyUploadAdapter;
