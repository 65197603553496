import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { classroomService, courseService, queryClient, registerService } from 'services';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PopupDocShare = ({ onClose }: PopupController) => {
  const { control, handleSubmit, watch } = useForm<ShareDocBody>();
  const courseId = watch('courseId');
  const classroomId = watch('classroomId');
  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { data: dataClassroom } = useQuery(['classroomService.fetchClassrooms', { courseId: courseId }], () =>
    classroomService.fetchClassrooms({ courseId: courseId, size: -1 }),
  );
  const { data: dataDoc } = useQuery(['classroomService.fetchClassroomDocs', { id: classroomId }], () =>
    classroomService.fetchClassroomDocs({ id: classroomId ? classroomId! : -1 }),
  );

  const [docIds, setDocIds] = useState<number[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof docIds>) => {
    const value = event.target.value;
    setDocIds(typeof value === 'string' ? [] : value);
  };

  const { mutate: shareVideo, isLoading } = useMutation(registerService.shareRegisterDocs, {
    onSuccess: () => {
      enqueueSnackbar('Chia sẻ video thành công');
      queryClient.invalidateQueries(['registerService.fetchRegisters']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      shareVideo({ ...values, docIds: docIds });
    })();
  };

  return (
    <>
      <DialogTitle>Chia sẻ Video</DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name='courseId'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Khóa học' error={!!error} helperText={error?.message}>
                  {(dataCourse?.list ?? []).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='classroomId'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Lớp học' error={!!error} helperText={error?.message}>
                  {(dataClassroom?.list ?? []).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              sx={{
                '.MuiInputAdornment-positionEnd': {
                  display: 'none',
                  marginRight: '19px',
                },
              }}
            >
              <InputLabel>Danh sách video</InputLabel>
              <Select
                multiple
                value={docIds}
                onChange={handleChange}
                input={<OutlinedInput label='Danh sách video' />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {(dataDoc?.list ?? [])
                      .filter((item) => selected.includes(item.doc.id))
                      .map((value, idx) => (
                        <Chip key={idx} label={value.doc.name} />
                      ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {(dataDoc?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.doc.id}>
                    {item.doc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='emails'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Email'
                  multiline
                  minRows={2}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupDocShare;
