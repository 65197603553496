import { Container, Dialog, Divider, Grid, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { queryClient, quizService } from 'services';
import {
  BarChartOutlined,
  Delete,
  Edit,
  InfoOutlined,
  Lock,
  LockOpen,
  PlayCircle,
  StopCircle,
} from '@mui/icons-material';
import { useState } from 'react';
import {
  PopupQuestionUpdate,
  PopupQuestionLock,
  PopupQuestionStop,
  PopupQuestionUnLock,
  PopupQuestionDelete,
  PopupQuestionAdd,
  QuestionStatisView,
  PopupQuestionPreview,
} from './components';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { Howl } from 'howler';

const EventView = () => {
  const { id } = useParams();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openStop, setOpenStop] = useState(false);
  const [openLock, setOpenLock] = useState(false);
  const [openUnLock, setOpenUnLock] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [itemChoice, setItemChoice] = useState<QuizQuestionDetail>();

  const { data, isSuccess } = useQuery(
    ['quizService.getQuizEventDetail'],
    () => quizService.getQuizEventDetail({ id: +id! }),
    {
      keepPreviousData: true,
    },
  );
  const { questions: items = [] } = data ?? {};

  const { data: questionStatistic } = useQuery(
    [`quizService.getQuizEventQuestionPlayStatistic_${data?.hashId!}`],
    () => quizService.getQuizEventQuestionPlayStatistic({ hashId: data?.hashId! }),
    {
      keepPreviousData: true,
      enabled: !!data?.hashId,
    },
  );

  const { mutate: startQuestion } = useMutation(quizService.putQuizEventQuestionStart, {
    onSuccess: () => {
      enqueueSnackbar('Phát câu hỏi thành công');
      queryClient.invalidateQueries(['quizService.getQuizEventDetail']);
      queryClient.invalidateQueries([`quizService.getQuizEventQuestionPlayStatistic_${data?.hashId}`]);
    },
  });

  const handleClickPlay = (question: QuizQuestionDetail) => {
    startQuestion({
      eventId: question.quizEventId,
      questionId: question.id,
    });
  };

  const startSound = new Howl({
    src: ['/PlayGame.mp3'],
  });

  const answerSound = new Howl({
    src: ['/DapAn.mp3'],
  });

  const statisticSound = new Howl({
    src: ['/ThongKe.mp3'],
  });

  const stopAllSounds = () => {
    startSound.stop();
    answerSound.stop();
    statisticSound.stop();
  };

  const playStart = () => {
    stopAllSounds();
    startSound.play();
  };

  const playAnswer = () => {
    stopAllSounds();
    answerSound.play();
  };

  const playStatistic = () => {
    stopAllSounds();
    statisticSound.play();
  };

  return (
    <Container maxWidth='xl' className='py-6'>
      <Grid container spacing={3}>
        {isSuccess && (
          <Grid item xs={12} md={questionStatistic ? 4 : 12}>
            <Container maxWidth='xl' className='py-6'>
              <Typography variant='h4' color='primary' className='mb-6 space-x-3'>
                <span>{data?.name}</span>
                <LoadingButton
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    setOpenAdd(true);
                  }}
                >
                  Thêm câu hỏi
                </LoadingButton>
              </Typography>
              <List>
                {items.map((question, index) => (
                  <div key={index}>
                    <ListItem key={index}>
                      <Grid container spacing={3} key={index}>
                        <Grid item xs={12}>
                          <ListItemText primary={question.content} />
                        </Grid>
                        <Grid item xs={12} className='px-2'>
                          <IconButton
                            edge='end'
                            aria-label='Xem nội dung câu hỏi'
                            title='Xem nội dung câu hỏi'
                            color='info'
                            onClick={() => {
                              setItemChoice(question);
                              setOpenView(true);
                            }}
                          >
                            <InfoOutlined />
                          </IconButton>
                          <IconButton
                            edge='end'
                            aria-label='Sửa'
                            title='Sửa'
                            color='primary'
                            onClick={() => {
                              setItemChoice(question);
                              setOpenEdit(true);
                            }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            edge='end'
                            aria-label='Xóa'
                            title='Xóa'
                            color='error'
                            onClick={() => {
                              setItemChoice(question);
                              setOpenDelete(true);
                            }}
                          >
                            <Delete />
                          </IconButton>
                          {question.isLocked !== 1 && (
                            <IconButton
                              edge='end'
                              aria-label='Khóa'
                              title='Khóa'
                              color='info'
                              onClick={() => {
                                setItemChoice(question);
                                setOpenLock(true);
                              }}
                            >
                              <Lock />
                            </IconButton>
                          )}
                          {question.isLocked === 1 && (
                            <IconButton
                              edge='end'
                              aria-label='Mở khóa'
                              title='Mở khóa'
                              color='info'
                              onClick={() => {
                                setItemChoice(question);
                                setOpenUnLock(true);
                              }}
                            >
                              <LockOpen />
                            </IconButton>
                          )}
                          {question.status !== 'playing' && (
                            <IconButton
                              edge='end'
                              aria-label='Phát'
                              title='Phát'
                              color='success'
                              onClick={() => {
                                playStart();
                                handleClickPlay(question);
                              }}
                            >
                              <PlayCircle />
                            </IconButton>
                          )}
                          {question.status === 'playing' && (
                            <IconButton
                              edge='end'
                              aria-label='Dừng'
                              title='Dừng'
                              color='warning'
                              onClick={() => {
                                setItemChoice(question);
                                setOpenStop(true);
                              }}
                            >
                              <StopCircle />
                            </IconButton>
                          )}
                          <IconButton
                            edge='end'
                            aria-label='Thống kê'
                            title='Thống kê'
                            color='info'
                            onClick={() => {
                              queryClient.invalidateQueries([
                                `quizService.getQuizEventQuestionPlayStatistic_${data?.hashId!}`,
                              ]);
                            }}
                          >
                            <BarChartOutlined />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider light />
                  </div>
                ))}
              </List>
            </Container>
          </Grid>
        )}
        {questionStatistic && (
          <Grid item xs={12} md={8}>
            <QuestionStatisView
              question={questionStatistic!}
              eventHashId={data?.hashId!}
              present={true}
              onShowAnswer={playAnswer}
              onShowStatistic={playStatistic}
            />
          </Grid>
        )}
      </Grid>
      <Dialog maxWidth='lg' open={openAdd}>
        <PopupQuestionAdd eventId={+id!} onClose={() => setOpenAdd(false)} />
      </Dialog>
      {itemChoice && (
        <>
          <Dialog maxWidth='lg' open={openView}>
            <PopupQuestionPreview question={itemChoice} onClose={() => setOpenView(false)} />
          </Dialog>
          <Dialog maxWidth='lg' open={openEdit}>
            <PopupQuestionUpdate
              question={itemChoice}
              onClose={() => {
                setOpenEdit(false);
                queryClient.invalidateQueries([`quizService.getQuizEventQuestionPlayStatistic_${data?.hashId!}`]);
              }}
            />
          </Dialog>
          <Dialog maxWidth='sm' open={openDelete}>
            <PopupQuestionDelete
              questionId={itemChoice.id}
              eventId={itemChoice.quizEventId}
              onClose={() => {
                setOpenDelete(false);
                queryClient.invalidateQueries([`quizService.getQuizEventQuestionPlayStatistic_${data?.hashId!}`]);
              }}
            />
          </Dialog>
          <Dialog maxWidth='sm' open={openStop}>
            <PopupQuestionStop
              question={itemChoice}
              onClose={() => {
                setOpenStop(false);
                queryClient.invalidateQueries([`quizService.getQuizEventQuestionPlayStatistic_${data?.hashId!}`]);
              }}
            />
          </Dialog>
          <Dialog maxWidth='sm' open={openLock}>
            <PopupQuestionLock question={itemChoice} onClose={() => setOpenLock(false)} />
          </Dialog>
          <Dialog maxWidth='sm' open={openUnLock}>
            <PopupQuestionUnLock question={itemChoice} onClose={() => setOpenUnLock(false)} />
          </Dialog>
        </>
      )}
    </Container>
  );
};

export default EventView;
