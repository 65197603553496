import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FeaturedPost from './components/FeaturePost';
import { postService } from 'services';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { Link, useParams } from 'react-router-dom';
import { publicRoute } from 'routes';
import { Typography } from '@mui/material';
import Sidebar from './components/Sidebar';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#0052cc',
    },
    secondary: {
      main: '#edf2ff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h5: {
      fontWeight: 600,
    },
  },
});

interface Post {
  postContent?: string;
  createdAt?: ISODateString;
  shortDescription?: string;
  avatarUrl?: string;
  title?: string;
  author?: string;
  sharingTypeId?: number;
  topicId?: number;
  totalView?: number;
}

interface TopicPosts {
  list: Post[];
}

interface ListData {
  [key: string]: TopicPosts;
}

export default function PostPublicListByTopic() {
  const { dataSearch } = useSearch();
  const [listData, setListData] = useState<ListData>({});
  const { id } = useParams();
  const { id: topicIdString } = useParams<{ id: string | undefined }>();
  const currentTopicId = topicIdString ? parseInt(topicIdString, 10) : undefined;
  const topicId = topicIdString !== undefined ? parseInt(topicIdString, 10) : null;

  useEffect(() => {}, [dataSearch]);
  const { data: dataTopic } = useQuery(['postService.fetchTopics'], () => postService.fetchTopics(dataSearch), {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (dataTopic && dataTopic.list.length) fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTopic, id]);

  const sortPosts = (posts: Post[]): Post[] => {
    return posts
      .filter((post) => post.createdAt !== undefined)
      .sort((a, b) => {
        const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
        const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);

        return dateA.getTime() - dateB.getTime();
      });
  };

  const fetchAllData = async () => {
    if (!dataTopic || !dataTopic.list.length || topicId === null) return;

    const topic = dataTopic.list.find((t) => t.id === topicId);
    if (!topic) return;

    const response = await postService.fetchPostsPublic({
      topicId: topic.id,
      size: 100,
    });
    const sortedPosts = sortPosts(response.list);

    setListData({
      [topic.id]: {
        list: sortedPosts,
      },
    });
  };

  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId]);

  const transformPostsForSidebar = () => {
    const posts: Post[] = [];
    Object.entries(listData).forEach(([topicId, topicData]) => {
      const topicPosts = topicData.list.map((post: Post) => ({ ...post, topicId: parseInt(topicId) }));
      posts.push(...topicPosts);
    });
    return posts;
  };

  const sidebarPosts = transformPostsForSidebar();

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth={false}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={3} style={{ paddingRight: '0' }}>
            <Sidebar topics={dataTopic?.list || []} posts={sidebarPosts} currentTopicId={currentTopicId} />
          </Grid>
          <Grid item xs={12} md={9} style={{ paddingLeft: '0' }}>
            <main>
              {dataTopic &&
                dataTopic.list &&
                dataTopic.list.map((topic) => {
                  const hasPosts =
                    listData && listData[topic.id] && listData[topic.id].list && listData[topic.id].list.length > 0;
                  if (hasPosts) {
                    return (
                      <React.Fragment key={topic.id + 'topic'}>
                        <React.Fragment>
                          <Typography
                            variant='h5'
                            component='h3'
                            style={{
                              fontWeight: 'bold',
                              marginTop: '2rem',
                              marginBottom: '1rem',
                              backgroundColor: '#f0f0f0',
                              padding: '10px',
                              borderRadius: '5px',
                              color: 'black',
                            }}
                          >
                            {topic.title}
                          </Typography>
                          <Grid container spacing={5}>
                            {listData &&
                              listData[topic.id] &&
                              listData[topic.id].list.map((post: any) => (
                                <Grid item xs={12} sm={6} md={4} key={'post' + topic.id + '_' + post.id}>
                                  {post.isPrivate ? (
                                    <FeaturedPost post={post} />
                                  ) : (
                                    <Link
                                      to={publicRoute.postPublicView.url(post)}
                                      style={{ width: '100%' }}
                                      key={post.id + 'link'}
                                    >
                                      <FeaturedPost post={post} />
                                    </Link>
                                  )}
                                </Grid>
                              ))}
                          </Grid>
                        </React.Fragment>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}
            </main>
          </Grid>
        </Grid>
      </Container>
      {/* <Footer title='Footer' description='Something here to give the footer a purpose!' /> */}
    </ThemeProvider>
  );
}
