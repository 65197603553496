import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ContentQuestionView from './ContentQuestionView';

type Props = PopupController & {
  question: TestQuestion;
};

const PopupSingleQuestionView = ({ question, onClose }: Props) => {
  return (
    <>
      <DialogTitle>Nội dung câu hỏi</DialogTitle>
      <DialogContent>
        <ContentQuestionView question={question} />
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupSingleQuestionView;
