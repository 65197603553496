import { Container, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { firestoreService, queryClient, quizService } from 'services';
import { QuestionPlayView } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { initSession, quizSelector } from 'reducers/quizSlice';
import { useEffect } from 'react';
import { onSnapshot } from 'firebase/firestore';

const StudentEventView = () => {
  const dispatch = useDispatch();
  const { hashId } = useParams();

  const { data: qEvent } = useQuery(
    ['quizService.getQuizEventByHashId'],
    () => quizService.getQuizEventByHashId({ hashId: hashId! }),
    {
      keepPreviousData: true,
    },
  );
  useEffect(() => {
    if (qEvent) {
      dispatch(initSession());
    }
  }, [dispatch, qEvent]);

  const session = useSelector(quizSelector);

  const { data: question, isSuccess } = useQuery(
    [`quizService.getQuizEventQuestionPlay_${hashId}`, { hashId: hashId!, sessionId: session?.quizSessionId }],
    () => quizService.getQuizEventQuestionPlay({ hashId: hashId!, sessionId: session?.quizSessionId! }),
    {
      enabled: !!session.quizSessionId,
    },
  );

  useEffect(() => {
    const doc = firestoreService.getDoc(process.env.REACT_APP_FIREBASE_QUIZ_ROOT!, `play_${hashId}`);
    onSnapshot(
      doc,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          queryClient.invalidateQueries([`quizService.getQuizEventQuestionPlay_${hashId}`]);
        }
      },
      (error) => {
        console.error('Error listening to document:', error);
      },
    );
  }, [hashId]);

  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Typography variant='h4' color='primary' className='mb-6 space-x-3'>
          <span>{qEvent?.name}</span>
        </Typography>
        {isSuccess && question && <QuestionPlayView question={question} sessionId={session.quizSessionId!} />}
        {isSuccess && !question && (
          <Typography variant='h6' color='error' align='center'>
            {qEvent?.status === 'new' ? (
              <span>Sự kiện chưa bắt đầu, vui lòng đợi trong giây lát</span>
            ) : (
              <span>Sự kiện đã kết thúc</span>
            )}
          </Typography>
        )}
      </Container>
    </>
  );
};

export default StudentEventView;
