import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { formatDate, formatNumber } from 'utils/common';
import {
  Button,
  CardActions,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
} from '@mui/material';
import CustomBreadcrumbs from './Breadcrumb';
import { useMutation, useQuery } from '@tanstack/react-query';
import { paperService, postService, queryClient } from 'services';
import { useSearch } from 'hooks';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { API_URL } from 'env';
import { useNavigate } from 'react-router-dom';
import { privateRoute } from 'routes';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

interface ViewPostProps {
  post: {
    id?: number;
    uuid?: string;
    postContent?: string;
    createdAt?: ISODateString;
    shortDescription?: string;
    avatarUrl?: string;
    title?: string;
    author?: string;
    sharingTypeId?: number;
    topicId?: number;
    totalView?: number;
    papers?: TestPaper[];
  };
}

export default function ViewPost(props: Partial<ViewPostProps>) {
  const navigate = useNavigate();
  const { post } = props;

  const { dataSearch } = useSearch();
  const { data: dataTopic, isFetching: dataTopicFetching } = useQuery(
    ['postService.fetchTopics', dataSearch],
    () => postService.fetchTopics(dataSearch),
    { keepPreviousData: true },
  );

  const getTopicTitle = (topicId: number) => {
    if (dataTopicFetching || !dataTopic) return null;
    const topic = dataTopic.list.find((topic) => topic.id === topicId);
    return topic ? topic.title : null;
  };

  const sharingTypeDataRequest = useQuery(
    ['postService.fetchSharingTypes', dataSearch],
    () => postService.fetchSharingTypes(dataSearch),
    { keepPreviousData: true },
  );
  const dataSharingType = sharingTypeDataRequest.data;
  const dataSharingTypeFetching = sharingTypeDataRequest.isFetching;
  const getSharingType = (SharingTypeId: number) => {
    if (dataSharingTypeFetching || !dataSharingType) return null;
    const sharingtype = dataSharingType.list.find((sharingType) => sharingType.id === SharingTypeId);
    return sharingtype ? sharingtype.type : null;
  };
  const topicId = post!.topicId as number;
  const sharingTypeId = post!.sharingTypeId as number;

  const breadcrumbItems = [
    { name: 'Home', path: '/' },
    { name: 'Chia sẻ kiến thức', path: '/' },
    { name: getSharingType(sharingTypeId), path: `/sharing-types/${sharingTypeId}/posts` },
    { name: getTopicTitle(topicId) },
  ];

  const [paperId, setPaperId] = useState(0);
  const [paperAssignId, setPaperAssignId] = useState(0);
  const [resultTestingId, setResultTestingId] = useState(0);
  const [openDeleteTesting, setOpenDeleteTesting] = useState(false);
  const [openDeleteWait, setOpenDeleteWait] = useState(false);

  const { mutate: initResult, isLoading: isLoadingInit } = useMutation(paperService.postInitTesting, {
    onSuccess: (res: CheckTestingStudent) => {
      setPaperAssignId(res.paperAssignId);
      if (res.testingResultId) {
        setResultTestingId(res.testingResultId!);
        setOpenDeleteTesting(true);
      } else if (res.waitApprovedResultId) {
        setResultTestingId(res.waitApprovedResultId!);
        setOpenDeleteWait(true);
      } else {
        queryClient.removeQueries([`paperService.getTestingData_${res.paperAssignId}`]);
        navigate(privateRoute.studentTesting.url({ id: res.paperAssignId, from: post?.uuid ?? '' }));
      }
    },
  });

  const { mutate: deleteResult, isLoading: isLoadingDelete } = useMutation(paperService.deleteMyPaperResult, {
    onSuccess: () => {
      setOpenDeleteTesting(false);
      setOpenDeleteWait(false);
      initResult({ paperId: paperId, paperAssignId: paperAssignId });
    },
  });

  const handleClickTesting = (paperId: number) => {
    setPaperId(paperId);
    initResult({ paperId: paperId, paperAssignId: 0 });
  };

  const handleClickDelete = () => {
    deleteResult({
      id: resultTestingId,
    });
  };

  return (
    <>
      <Container>
        <style>
          {`
          .card-content table {
            border-collapse: collapse;
          }

          .card-content th, .card-content td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
            word-wrap: break-word; 
            overflow-wrap: break-word;
          }
          .card-content ul {
            list-style-type: disc;
            margin-left: 20px;
          }
          .card-content ol {
            list-style-type: decimal; 
            margin-left: 20px;
          }
          .card-content li {
            margin-bottom: 10px;
          }
          .card-content h1 {
            font-size: 2em; /* Adjust as needed */
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .card-content h2 {
            font-size: 1.75em; /* Adjust as needed */
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .card-content h3 {
            font-size: 1.5em; /* Adjust as needed */
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }
          
          .card-content p {
            font-size: 1em; /* Adjust as needed */
            margin-top: 0.5em;
            margin-bottom: 0.5em;
          }
          .card-content img {
            max-width: 100%;
            height: auto;
          }
          .card-content a {
            color: blue; 
          }
        `}
        </style>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={12} className='mt-6'>
            <CustomBreadcrumbs breadcrumbs={breadcrumbItems} />
          </Grid>
          <Grid item xs={12} className='mt-0'>
            <Card
              elevation={4}
              sx={{
                borderRadius: '15px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                my: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* {post?.avatarUrl && <CardMedia component='img' height='260' image={post?.avatarUrl} alt={post?.title} />} */}
              <CardContent className='card-content' sx={{ flexGrow: 1 }}>
                <Typography
                  gutterBottom
                  variant='h4'
                  component='h2'
                  className='mt-0'
                  sx={{ fontWeight: 'bold', textAlign: 'left' }}
                >
                  {post?.title}
                </Typography>
                <Typography variant='subtitle2' color='textPrimary' sx={{ textAlign: 'left', opacity: 0.8 }}>
                  {post?.author} - {post?.createdAt && formatDate(post.createdAt)}
                </Typography>
                {post?.shortDescription && (
                  <Typography variant='subtitle2' color='textSecondary'>
                    {post?.shortDescription}
                  </Typography>
                )}
                <Divider sx={{ my: 2 }} />
                {post?.totalView !== undefined && (
                  <Typography variant='subtitle2' color='textSecondary' sx={{ textAlign: 'left', marginTop: '1rem' }}>
                    <VisibilityIcon fontSize='small' /> Lượt xem: {post.totalView}
                  </Typography>
                )}
                <Typography
                  variant='body1'
                  component='div'
                  dangerouslySetInnerHTML={{ __html: post?.postContent || '' }}
                />
                {post?.papers && post.papers.length > 0 && (
                  <>
                    <Divider sx={{ my: 2 }} />
                    <Typography gutterBottom variant='h4' component='h2' sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                      Danh sách bài test
                    </Typography>
                    <Grid container spacing={3}>
                      {post.papers!.map((paper, index) => (
                        <Grid key={index} item md={6} xs={12}>
                          <Paper
                            variant='outlined'
                            className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
                          >
                            <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
                              <CardMedia
                                image={`${API_URL}/api/v1/files/public/testing.jpeg`}
                                className='h-[162px] w-[216px] rounded-lg border bg-contain hover:shadow-md mobile:w-full'
                              >
                                <div></div>
                              </CardMedia>
                              <div className='space-y-2'>
                                <Typography
                                  gutterBottom
                                  variant='h5'
                                  component='h6'
                                  sx={{ fontWeight: 'bold', textAlign: 'left' }}
                                >
                                  {paper.name}
                                </Typography>
                                {paper.minute && (
                                  <div>
                                    <span className='text-black/60'>Thời gian làm bài: </span>
                                    {formatNumber(paper.minute)} phút
                                  </div>
                                )}
                                <div>
                                  <span className='text-black/60'>Số lượng câu hỏi: </span>
                                  {formatNumber(paper.totalQuestion)} câu
                                </div>
                                <div>
                                  <span className='text-black/60'>Số lần test tối đa: </span>
                                  {formatNumber(paper.maxTime)} lần
                                </div>
                                <div className='mt-6 flex space-x-2'>
                                  <LoadingButton
                                    className='whitespace-nowrap'
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    loading={isLoadingInit}
                                    onClick={() => handleClickTesting(paper.id)}
                                  >
                                    Làm bài
                                  </LoadingButton>
                                </div>
                              </div>
                            </div>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </CardContent>
              <CardActions>
                <Button size='small' color='primary'>
                  Read More
                </Button>
                <Button size='small' color='primary'>
                  Like
                </Button>
                <Button size='small' color='primary'>
                  Share
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={openDeleteTesting || openDeleteWait}>
        <DialogTitle>Xác nhận làm lại bài test</DialogTitle>

        <DialogContent>
          {openDeleteTesting ? (
            <div className='text-center'>
              Bạn đang thực hiện làm bài test trước đó. Hệ thống sẽ tự động hủy bài làm này để thực hiện bài test mới.
              Vui lòng nhấn xác nhận để tiếp tục làm bài
            </div>
          ) : (
            <div className='text-center'>
              Bài làm gần nhất của bạn đang chờ duyệt điểm. Nếu bạn muốn làm lại, vui lòng xác nhận đồng ý Hủy bài cũ
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton
            variant='outlined'
            color='inherit'
            onClick={() => {
              setOpenDeleteTesting(false);
              setOpenDeleteWait(false);
            }}
          >
            Hủy bỏ
          </LoadingButton>
          <LoadingButton variant='contained' loading={isLoadingDelete} onClick={handleClickDelete}>
            Xác nhận
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
