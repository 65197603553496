import { Container, Divider, Grid, Typography } from '@mui/material';
import { firestoreService, queryClient, quizService } from 'services';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QuestionStatisView } from './components';
import { onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import { Howl } from 'howler';

const QuestionPresent = () => {
  const { hashId } = useParams();

  const { data: qEvent } = useQuery(
    ['quizService.getQuizEventByHashId'],
    () => quizService.getQuizEventByHashId({ hashId: hashId! }),
    {
      keepPreviousData: true,
    },
  );

  const { data: question, isSuccess } = useQuery(
    [`quizService.getQuizEventQuestionPlayStatistic_${hashId}`],
    () => quizService.getQuizEventQuestionPlayStatistic({ hashId: hashId! }),
    {
      keepPreviousData: true,
    },
  );

  const answerSound = new Howl({
    src: ['/DapAn.mp3'],
  });

  const statisticSound = new Howl({
    src: ['/ThongKe.mp3'],
  });

  const stopAllSounds = () => {
    answerSound.stop();
    statisticSound.stop();
  };

  const playAnswer = () => {
    stopAllSounds();
    answerSound.play();
  };

  const playStatistic = () => {
    stopAllSounds();
    statisticSound.play();
  };

  useEffect(() => {
    const doc = firestoreService.getDoc(process.env.REACT_APP_FIREBASE_QUIZ_ROOT ?? 'quizzes', `answer_${hashId}`);
    onSnapshot(
      doc,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          queryClient.invalidateQueries([`quizService.getQuizEventQuestionPlayStatistic_${hashId}`]);
        }
      },
      (error) => {
        console.error('Error listening to document:', error);
      },
    );
  }, [hashId]);

  useEffect(() => {
    const doc = firestoreService.getDoc(process.env.REACT_APP_FIREBASE_QUIZ_ROOT ?? 'quizzes', `play_${hashId}`);
    onSnapshot(
      doc,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          queryClient.invalidateQueries([`quizService.getQuizEventQuestionPlayStatistic_${hashId}`]);
        }
      },
      (error) => {
        console.error('Error listening to document:', error);
      },
    );
  }, [hashId]);

  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h4' color='primary' className='mb-6 space-x-3'>
              <span>{qEvent?.name}</span>
            </Typography>
            <Divider light className='mb-3' />
            {isSuccess && question && (
              <QuestionStatisView
                question={question}
                eventHashId={hashId!}
                onShowAnswer={playAnswer}
                onShowStatistic={playStatistic}
              />
            )}
            {isSuccess && !question && (
              <Typography variant='h6' color='error' align='center'>
                {qEvent?.status === 'new' ? (
                  <span>Sự kiện chưa bắt đầu, vui lòng đợi trong giây lát</span>
                ) : (
                  <span>Sự kiện đã kết thúc</span>
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default QuestionPresent;
