import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { paperService } from 'services';
import { useSearch } from 'hooks';

type Props = {
  paperResult: TestPaperResult;
};

const PaperResultHisIpList = ({ paperResult }: Props) => {
  const { dataSearch, onPaginationChange } = useSearch();
  const { data, isFetching } = useQuery(
    [`paperService.fetchPaperResultIpTracks${paperResult.id}`, dataSearch],
    () =>
      paperService.fetchPaperResultTracks({
        ...dataSearch,
        distinctIp: true,
        id: paperResult.id,
      }),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  return (
    <DataGrid
      loading={isFetching}
      getRowId={(row) => row.id}
      getRowHeight={() => 64}
      rows={items}
      rowCount={total}
      onPaginationModelChange={onPaginationChange}
      columns={[
        {
          field: 'ip',
          headerName: 'IP làm bài',
          flex: 1,
          minWidth: 200,
          sortable: false,
          renderCell: ({ row }) => (
            <>
              <div>{row.ip}</div>
            </>
          ),
        },
      ]}
    />
  );
};

export default PaperResultHisIpList;
