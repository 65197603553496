import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { testQuestionService, queryClient } from 'services';

type Props = PopupController & {
  questionId: number;
};

const PopupQuestionDelete = ({ questionId, onClose }: Props) => {
  const { mutate: deleteQuizEventQuestion, isLoading } = useMutation(testQuestionService.deleteTestQuestion, {
    onSuccess: () => {
      enqueueSnackbar('Xóa câu hỏi thành công');
      queryClient.invalidateQueries(['testQuestionService.fetchTestQuestions']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    deleteQuizEventQuestion({
      id: questionId,
    });
  };
  return (
    <>
      <DialogTitle>Xác nhận xóa câu hỏi</DialogTitle>

      <DialogContent>
        <div className='text-center'>Bạn có chắc chắn muốn xóa câu hỏi này không?</div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupQuestionDelete;
