import { Button, CardMedia, Chip, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { ClassroomStatusTypes } from 'utils/common';

type Props = {
  item: HomeworkClassroom;
};

const CardHomeworkClassroom = ({ item: classroom }: Props) => {
  return (
    <Paper
      variant='outlined'
      className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
    >
      <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
        <Link to={privateRoute.studentHomeworkView.url({ id: classroom.id })}>
          <CardMedia
            image={classroom.course?.imageUrl}
            className='h-[180px] w-[240px] rounded-lg border bg-contain hover:shadow-md mobile:w-full'
          >
            <div></div>
          </CardMedia>
        </Link>
        <div className='space-y-2'>
          <Link to={privateRoute.studentHomeworkView.url({ id: classroom.id })}>
            <Typography variant='h4' className='hover:text-primary-main'>
              {classroom.name} - {classroom.course?.name}
            </Typography>
          </Link>
          <div>
            <span className='text-black/60'>Tổng BTVN:</span> {classroom.totalHomework}
          </div>
          <div>
            <span className='text-black/60'>Đã làm:</span> {classroom.totalDidHomework}
          </div>
          <div>
            <span className='text-black/60'>Chờ sửa:</span> {classroom.totalEditingHomework}
          </div>
          <div>
            <span className='text-black/60'>Chờ review:</span> {classroom.totalReviewingHomework}
          </div>
        </div>
      </div>
      <div className='flex flex-col items-end justify-between'>
        <div className='space-y-2'>
          <div className='flex justify-end'>
            <Chip
              label={ClassroomStatusTypes.find((item) => item.value === classroom.status)?.label}
              color={classroom.status === 'STOP' ? 'error' : 'secondary'}
            />
          </div>
        </div>
        <div className='mt-6 flex space-x-2'>
          <Link to={privateRoute.studentHomeworkView.url({ id: classroom.id })}>
            <Button color='success' onClick={() => {}} className='px-12'>
              Chi tiết
            </Button>
          </Link>
        </div>
      </div>
    </Paper>
  );
};

export default CardHomeworkClassroom;
