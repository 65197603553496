import { Clear, CopyAllRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { registerService } from 'services';
import { formatDate } from 'utils/common';
import { Link } from 'react-router-dom';
import { TruncatedTextView } from 'views/Survey/components';

const PopupDocShareHis = ({ onClose }: PopupController) => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();
  const { control, handleSubmit, reset } = useForm<ShareDocSearchParams>();
  const { data, isFetching } = useQuery(
    ['registerService.fetchSharedDocs', dataSearch],
    () => registerService.fetchSharedDocs(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onSearchChange({ ...values, toCreatedAt: values.fromCreatedAt });
    })();
  };

  const handleClickClear = () => {
    reset({
      fromCreatedAt: '',
      toCreatedAt: '',
      email: '',
    });
    handleClickSearch();
  };

  const copy = (emails: string) => {
    navigator.clipboard.writeText(emails).then(() => {});
  };

  return (
    <>
      <DialogTitle>Lịch sử chia sẻ Video</DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Controller
              name='fromCreatedAt'
              defaultValue={null}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  format='dd/MM/yyyy'
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      label: 'Ngày chia sẻ',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Controller
              name='email'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Email' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <div className='mt-4 flex justify-end space-x-3'>
              <Button onClick={handleClickSearch}>Tìm kiếm</Button>
              <Button variant='outlined' color='inherit' onClick={handleClickClear}>
                <Clear />
              </Button>
            </div>
          </Grid>
        </Grid>

        <DataGrid
          loading={isFetching}
          getRowId={(row) => row.id}
          getRowHeight={() => 64}
          rows={items}
          rowCount={total}
          onPaginationModelChange={onPaginationChange}
          columns={[
            {
              field: 'createdAt',
              headerName: 'Ngày chia sẻ',
              minWidth: 140,
              sortable: false,
              renderCell: ({ row }) => <div>{formatDate(row.createdAt)}</div>,
            },
            {
              field: 'shareUser.fullName',
              headerName: 'Người chia sẻ',
              flex: 1,
              minWidth: 200,
              sortable: false,
              renderCell: ({ row }) => <div>{row.shareUser.fullName}</div>,
            },
            {
              field: 'emails',
              headerName: 'Danh sách email',
              flex: 1,
              minWidth: 280,
              sortable: false,
              renderCell: ({ row }) => <TruncatedTextView text={row.emails} />,
            },
            {
              field: 'doc.url',
              headerName: 'Tài liệu',
              flex: 1,
              minWidth: 700,
              sortable: false,
              renderCell: ({ row }) => (
                <div>
                  <Link to={row.doc.url} target='_blank' className='text-primary-main hover:text-primary-dark'>
                    {row.doc.url}
                  </Link>
                </div>
              ),
            },
            {
              field: 'classroom.code',
              headerName: 'Lớp học',
              flex: 1,
              minWidth: 220,
              sortable: false,
              renderCell: ({ row }) => (
                <div>
                  {row.classroom.code}
                  <br />
                  {row.course.name}
                </div>
              ),
            },
            {
              field: 'actions',
              headerName: 'Hành động',
              sortable: false,
              minWidth: 120,
              renderCell: ({ row }) => (
                <div className='flex space-x-3'>
                  <Tooltip title='Copy'>
                    <Button size='small' color='success' onClick={() => copy(row.emails)}>
                      <CopyAllRounded />
                    </Button>
                  </Tooltip>
                </div>
              ),
            },
          ]}
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupDocShareHis;
