import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { classroomService, queryClient } from 'services';

type Props = PopupController & {
  classroom: Classroom;
};

const PopupZaloUpdate = ({ classroom, onClose }: Props) => {
  const { control, handleSubmit } = useForm<LinkUpdateBody>();

  const { mutate: updateLink, isLoading } = useMutation(classroomService.updateClassroomZalo, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật link Zalo lớp học chính thức thành công');
      queryClient.invalidateQueries(['classroomService.getClassroomInfo']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateLink({
        ...values,
        id: classroom.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Cập nhật link Zalo lớp học chính thức của lớp học</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='link'
              defaultValue={classroom.zaloLink}
              control={control}
              rules={{
                required: 'Link zalo là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Link Zalo lớp học chính thức'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupZaloUpdate;
