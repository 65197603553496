import { useQuery } from '@tanstack/react-query';
import { courseService } from 'services';
import { CardCourse } from '.';

const CourseList = () => {
  const { data } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { list: items } = data ?? {};

  return (
    <div className='flex flex-col space-y-6'>
      {items?.map((course) => (
        <CardCourse key={course.id} item={course} />
      ))}
    </div>
  );
};

export default CourseList;
