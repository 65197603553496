import { client } from './axios';

const fetchSurveys = (body: SurveySearchParams): Promise<PaginateResponse<Survey>> =>
  client.post(`/api/v1/surveys/list`, body);

const upsertSurvey = ({ id, ...body }: ByID & UpsertSurveyBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/surveys/me/${id}`, body);

const approveSurvey = ({ id, ...body }: ByID & ApproveSurveyBody): Promise<SurveyPromotion> =>
  client.put(`/api/v1/surveys/${id}/approve`, body);
const rejectSurvey = ({ id, ...body }: ByID & ApproveSurveyBody) => client.put(`/api/v1/surveys/${id}/reject`, body);
const getSurvey = ({ id }: ByID): Promise<Survey> => client.get(`/api/v1/surveys/me/${id}`);
const getMySurvey = ({ studentId }: { studentId: number }): Promise<Survey> =>
  client.get(`/api/v1/surveys/me/${studentId}`);

const surveyService = {
  fetchSurveys,
  approveSurvey,
  rejectSurvey,

  upsertSurvey,
  getSurvey,
  getMySurvey,
};

export default surveyService;
