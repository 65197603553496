import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { quizService, queryClient } from 'services';

type Props = PopupController & {
  event?: QuizEvent;
};

const PopupEventUpsert = ({ event, onClose }: Props) => {
  const { control, handleSubmit } = useForm<QuizEventCreateBody>();

  const { mutate: addEvent, isLoading: isLoadingAdd } = useMutation(quizService.postQuizEvent, {
    onSuccess: () => {
      enqueueSnackbar('Thêm mới sự kiện thành công');
      queryClient.invalidateQueries(['quizService.fetchQuizEvents']);
      onClose();
    },
  });

  const { mutate: updateEvent, isLoading: isLoadingUpdate } = useMutation(quizService.putQuizEvent, {
    onSuccess: () => {
      enqueueSnackbar('Câp nhật sự kiện thành công');
      queryClient.invalidateQueries(['quizService.fetchQuizEvents']);
      onClose();
    },
  });

  const handleClickSubmitAdd = () => {
    handleSubmit((values) => {
      addEvent({
        ...values,
      });
    })();
  };

  const handleClickSubmitUpdate = () => {
    handleSubmit((values) => {
      updateEvent({
        ...values,
        id: event!.id,
      });
    })();
  };

  return (
    <>
      {!event && <DialogTitle>Thêm mới sự kiện</DialogTitle>}
      {event && <DialogTitle>Cập nhật sự kiện</DialogTitle>}

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='name'
              defaultValue={event?.name}
              control={control}
              rules={{
                required: 'Tên sự kiện là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Tên sự kiện'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        {!event && (
          <LoadingButton variant='contained' loading={isLoadingAdd} onClick={handleClickSubmitAdd}>
            Thêm mới
          </LoadingButton>
        )}
        {event && (
          <LoadingButton variant='contained' loading={isLoadingUpdate} onClick={handleClickSubmitUpdate}>
            Cập nhật
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default PopupEventUpsert;
