import { Button, Grid, MenuItem, Pagination, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { courseService, paperService } from 'services';
import CardPaper from './CardPaper';
import { useEffect, useState } from 'react';
import { useSearch } from 'hooks';
import { Clear } from '@mui/icons-material';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';

type Props = {
  testStatus?: number;
};

const CardPaperList = ({ testStatus }: Props) => {
  const [page, setPage] = useState(1);
  const { dataSearch, onSearchChange } = useSearch({ size: 5 });
  const { control, handleSubmit, reset } = useForm<TestMyPaperSearchParams>();
  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { data, isFetching } = useQuery(
    ['paperService.fetchMyPapers', dataSearch],
    () => paperService.fetchMyPapers({ ...dataSearch, testStatus: testStatus }),
    { keepPreviousData: true },
  );

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onSearchChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      courseId: '',
      paperName: '',
    });
    handleClickSearch();
  };

  const { list: items = [], total = 0 } = data ?? {};
  useEffect(() => {
    onSearchChange({ ...dataSearch, testStatus: testStatus, page: page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <div className='flex flex-col space-y-6'>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Controller
              name='courseId'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Khóa học' error={!!error} helperText={error?.message}>
                  {(dataCourse?.list ?? []).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Controller
              name='paperName'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Tên bài test' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item className='mt-2' md={4} xs={12}>
            <Button onClick={handleClickSearch}>Tìm kiếm</Button>
            <Button variant='outlined' color='inherit' onClick={handleClickClear}>
              <Clear />
            </Button>
          </Grid>
        </Grid>
        {items.map((paper, index) => (
          <CardPaper key={index} paper={paper} />
        ))}
        {!isFetching && items.length > 0 && (
          <Pagination
            variant='outlined'
            shape='rounded'
            count={(total ?? 0) % 5 === 0 ? (total ?? 0) / 5 : Math.floor((total ?? 0) / 5) + 1}
            page={page}
            onChange={(_event: React.ChangeEvent<unknown>, value: number) => {
              setPage(value);
            }}
          />
        )}
      </div>
      {!isFetching && items.length === 0 && (
        <div className='mt-6 text-center font-bold'>HIỆN TẠI BẠN CHƯA CÓ BÀI TEST NÀO</div>
      )}
    </>
  );
};

export default CardPaperList;
