import { Grid, Paper, Typography } from '@mui/material';

type Props = {
  course: Course;
};

const TabCourseContent = ({ course }: Props) => {
  return (
    <div>
      <Grid container spacing={3} className='justify-around'>
        {course.details.map((item, index) => (
          <Grid item key={index} md={4} xs={12}>
            <Paper className='h-full rounded-xl p-4 pl-8'>
              <Typography variant='h4' align='center'>
                {item.title}
              </Typography>
              <div>
                {item.lines.map((text, index) => (
                  <li key={index}>{text}</li>
                ))}
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TabCourseContent;
