import { Edit, ResetTvOutlined } from '@mui/icons-material';
import { Button, Container, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { authService } from 'services';
import { PopupResetPassword, PopupUserUpdate, UserSearch } from './components';

const UserList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    ['authService.fetchUsers', dataSearch],
    () => authService.fetchUsers(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const [openEdit, setOpenEdit] = useState(false);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [itemChoice, setItemChoice] = useState<User>();

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách người dùng
      </Typography>

      <UserSearch onChange={onSearchChange} />
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 96}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'fullName',
            headerName: 'Họ tên',
            flex: 1,
            minWidth: 240,
            sortable: false,
            renderCell: ({ row }) => <div>{row.fullName}</div>,
          },
          {
            field: 'phone',
            headerName: 'Điện thoại',
            flex: 1,
            minWidth: 150,
            sortable: false,
            renderCell: ({ row }) => <div>{row.phone}</div>,
          },
          {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            minWidth: 300,
            sortable: false,
            renderCell: ({ row }) => <div>{row.email}</div>,
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 150,
            align: 'center',
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Tooltip title='Cập nhật thông tin học viên'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setItemChoice(row);
                      setOpenEdit(true);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
                <Tooltip title='Reset mật khẩu'>
                  <Button
                    size='small'
                    color='success'
                    onClick={() => {
                      setItemChoice(row);
                      setOpenResetPass(true);
                    }}
                  >
                    <ResetTvOutlined />
                  </Button>
                </Tooltip>
              </div>
            ),
          },
        ]}
      />
      {itemChoice && (
        <Dialog maxWidth='sm' open={openResetPass}>
          <PopupResetPassword id={itemChoice?.id!} onClose={() => setOpenResetPass(false)} />
        </Dialog>
      )}
      {itemChoice && (
        <Dialog open={openEdit}>
          <PopupUserUpdate user={itemChoice} onClose={() => setOpenEdit(false)} />
        </Dialog>
      )}
    </Container>
  );
};

export default UserList;
