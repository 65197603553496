import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

type Props = PopupController & {
  homework: Homework;
};

const PopupHomeworkInfo = ({ homework, onClose }: Props) => {
  return (
    <>
      <DialogTitle>Thông tin bài tập về nhà</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <GridInfo label='Tên BTVN' value={homework.name} />
          </Grid>
          {homework.template && (
            <Grid item xs={12}>
              <div>
                <span>Mẫu: </span>
                <Link to={homework.template} target='_blank' className='font-bold hover:text-primary-main'>
                  {homework.template}
                </Link>
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <div>
              <span>Nội dung: </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='inherit' sx={{ whiteSpace: 'pre-wrap' }}>
              {homework.content ?? ''}
            </Typography>
          </Grid>
          {homework.showAnswer && homework.answerLink && (
            <Grid item xs={12}>
              <div>
                <span>Link đáp án: </span>
                <Link to={homework.answerLink} target='_blank' className='font-bold hover:text-primary-main'>
                  {homework.answerLink}
                </Link>
              </div>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupHomeworkInfo;
