import { ArrowDropDown, AutoStoriesOutlined, SchoolOutlined } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Menu, styled } from '@mui/material';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { publicRoute } from 'routes';
import ListIcon from '@mui/icons-material/List';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { postService } from 'services';

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 16,
  padding: '4px 16px',
  '&.MuiListItemButton-root.Mui-selected': {
    backgroundColor: theme.palette.mode === 'light' ? '#bbdefb' : '#37474f',
  },
  '&.MuiListItemButton-root:hover': {
    backgroundColor: theme.palette.mode === 'light' ? '#90caf9' : '#455a64',
  },
  '.MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 8,
  },
  '.MuiListItemText-primary': {
    fontWeight: 700,
  },
}));

type SubMenuType = {
  name?: string | JSX.Element;
  path: string;
};

type MenuItemProps = {
  icon?: JSX.Element;
  name?: string | JSX.Element;
  path: string;
  items?: SubMenuType[];
};

const MenuItem = ({ icon, name, path, items }: MenuItemProps) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const isHome = path === publicRoute.home.path;
  const isContain = location.pathname.startsWith(path);
  const isSelected = isHome ? location.pathname === path : isContain;

  return (
    <>
      {items ? (
        <StyledListItem selected={isContain} onClick={() => setOpen(!open)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{name}</ListItemText>
          <ArrowDropDown />
        </StyledListItem>
      ) : (
        <Link to={path}>
          <StyledListItem selected={isSelected}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </StyledListItem>
        </Link>
      )}

      {items && (
        <Collapse in={open}>
          <List className='ml-6 py-0' sx={{ '.MuiListItemButton-root': { padding: '4px 16px' } }}>
            {items?.map((sub, index) => (
              <MenuItem key={index} {...sub} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const AuthMenu = () => {
  const dataSearch = {};
  const sharingTypeDataRequest = useQuery(
    ['postService.fetchSharingTypes', dataSearch],
    () => postService.fetchSharingTypes(dataSearch),
    { keepPreviousData: true },
  );

  const dataSharingType = sharingTypeDataRequest.data;

  return (
    <List className='flex flex-row items-center space-x-3'>
      <MenuItem {...publicRoute.courseList} icon={<AutoStoriesOutlined />} />
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <React.Fragment>
            <StyledListItem {...bindTrigger(popupState)}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary='Chia sẻ kiến thức' />
            </StyledListItem>
            <Menu {...bindMenu(popupState)}>
              {(dataSharingType?.list ?? []).map((item: SharingType, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    popupState.close();
                  }}
                >
                  <MenuItem path={publicRoute.PostPublicListBySharingType.url(item)} name={item.type} />
                </div>
              ))}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <MenuItem {...publicRoute.studentRanking} icon={<SchoolOutlined />} />
    </List>
  );
};

export default AuthMenu;
