import { client } from './axios';
import { USER_ROLE } from 'env';

const login = (body: LoginBody): Promise<LoginResponse> =>
  client.post(`/api/v1/auth/login`, { ...body, role: USER_ROLE });
const resetPassword = (body: ResetPasswordBody): Promise<ResetPasswordResponse> =>
  client.post(`/api/v1/auth/forgot`, body);

const register = (body: RegisterBody): Promise<RegisterResponse> => client.post(`/api/v1/users/register`, body);
const getProfile = (): Promise<ProfileType> => client.get(`/api/v1/users/me`);
const updateProfile = (body: UpdateProfileBody): Promise<ProfileType> => client.put(`/api/v1/users/me`, body);
const changePassword = (body: ChangePasswordBody): Promise<ProfileType> =>
  client.put(`/api/v1/users/change-password`, body);
const fetchUsers = (param: UserSearchParams): Promise<PaginateResponse<User>> =>
  client.post(`/api/v1/users/list`, param);
const resetRandomPassword = ({ id }: ByID): Promise<ResetRandomPasswordResponse> =>
  client.put(`/api/v1/users/${id}/reset-password`);
const updateUser = ({ id, ...body }: ByID & UpdateProfileBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/users/${id}`, body);

const authService = {
  login,
  resetPassword,

  register,
  getProfile,
  updateProfile,
  changePassword,

  fetchUsers,
  resetRandomPassword,

  updateUser,
};

export default authService;
