import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { queryClient, paperService } from 'services';

type Props = PopupController & {
  paper: TestPaper;
};

const PopupPaperStop = ({ paper, onClose }: Props) => {
  const { mutate: stopPaper, isLoading } = useMutation(paperService.putPaperDeactive, {
    onSuccess: () => {
      enqueueSnackbar('Tạm dừng bài test thành công');
      queryClient.invalidateQueries(['paperService.fetchPapers']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    stopPaper({
      id: paper.id,
    });
  };
  return (
    <>
      <DialogTitle>Xác nhận kích hoạt bài test</DialogTitle>

      <DialogContent>
        <div className='text-center'>Bạn có chắc chắn muốn tạm dừng bài test này không?</div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupPaperStop;
