import { Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { registerService } from 'services';
import CardRegister from './components/CardRegister';

const RegisterListOwner = () => {
  const { data, isFetching } = useQuery(
    ['registerService.fetchMyRegisters'],
    () => registerService.fetchMyRegisters({ size: -1 }),
    { keepPreviousData: true },
  );
  const { list: items = [] } = data ?? {};

  return (
    <Container maxWidth='md' className='py-6'>
      <div className='flex flex-col space-y-6'>
        {items.map((register) => (
          <CardRegister key={register.id} register={register} />
        ))}
      </div>
      {!isFetching && items.length === 0 && (
        <div className='text-center font-bold'>HIỆN TẠI BẠN CHƯA ĐĂNG KÝ KHÓA HỌC NÀO</div>
      )}
    </Container>
  );
};

export default RegisterListOwner;
