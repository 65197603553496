import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { formatDate } from 'utils/common';
import { CardHeader, Chip, Tooltip } from '@mui/material';

interface FeaturedPostProps {
  post: {
    createdAt?: ISODateString;
    shortDescription?: string;
    avatarUrl?: string;
    title?: string;
    author?: string;
    isPrivate?: boolean;
  };
}

export default function FeaturedPost(props: FeaturedPostProps) {
  const { post } = props;

  return (
    <Card
      sx={{
        maxHeight: '100%',
        height: '100%',
        overflow: 'hidden',
        boxShadow: 3,
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          boxShadow: 6,
        },
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        marginBottom: 3,
        backgroundColor: 'background.default',
        position: 'relative',
      }}
    >
      <CardMedia
        component='img'
        sx={{
          width: '100%',
          height: { md: 260 },
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
          display: { xs: 'none', sm: 'block' },
        }}
        image={
          post.avatarUrl ||
          'https://storage.googleapis.com/m2mba/bb5e1d3e-c71d-4331-8260-16fe05974e3f.png?fbclid=IwAR1fEY2jIaKMYBUmu3Q0cCJsSIlGNtBpsUOToAGd0S0rSUqs0wdgy2wx-dg'
        }
        alt={post.title}
      />
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}>
        <CardHeader
          title={post.title}
          subheader={formatDate(post.createdAt ? post.createdAt : '')}
          titleTypographyProps={{ variant: 'h4', component: 'div' }}
          sx={{ flexShrink: 0 }}
        />
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            padding: 3,
          }}
        >
          <Typography variant='body1' color='text.secondary' component='p' paragraph>
            {post.shortDescription}
          </Typography>
        </CardContent>
        <div
          style={{
            position: 'absolute',
            bottom: '1em',
            left: '1em',
            padding: '8px',
            color: 'purple',
          }}
        >
          {post.isPrivate ? (
            <Typography variant='subtitle1' component='p' sx={{ fontWeight: 'medium' }}>
              Tác giả: {post.author}{' '}
              <Tooltip title='Bài viết không được public' className='text-right'>
                <Chip label='PRIVATE' color='info' variant='outlined' />
              </Tooltip>
            </Typography>
          ) : (
            <Typography variant='subtitle1' component='p' sx={{ fontWeight: 'medium' }}>
              Tác giả: {post.author}
            </Typography>
          )}
        </div>
      </div>
    </Card>
  );
}
