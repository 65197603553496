import { Box, Container, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import PostList from './PostList';
import CommentList from './CommentList';

const AdminList = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Container maxWidth='xl' className='py-6'>
      <Box
        sx={{
          flexGrow: 10,
          maxWidth: '100%',
          display: 'flex',
          bgcolor: 'background.paper',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          variant='scrollable'
          scrollButtons='auto'
        >
          <Tab label='DANH SÁCH BÀI VIẾT' />
          <Tab label='DANH SÁCH COMMENT' />
        </Tabs>
      </Box>
      <div className='py-6'>
        {activeTab === 0 && <PostList />}
        {activeTab === 1 && <CommentList />}
      </div>
    </Container>
  );
};

export default AdminList;
