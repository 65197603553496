import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PaperResultMark from './PaperResultMark';

type Props = PopupController & {
  paperResult: TestPaperResult;
  questionIndex: number;
  answer: TestPaperResultAnswer;
};

const PopupPaperResultMark = ({ paperResult, questionIndex, answer, onClose }: Props) => {
  return (
    <>
      <DialogTitle>
        Chấm điểm tự luận câu {questionIndex} bài test {paperResult.paper?.name} của học viên{' '}
        {paperResult.user?.fullName}
      </DialogTitle>

      <DialogContent>
        <PaperResultMark answer={answer} onSaveMark={(_: number) => onClose()} />
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupPaperResultMark;
