import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { courseService, queryClient, registerService } from 'services';
import { regexValidateEmail } from 'utils/validator';
import CardCourse from './CardCourse';
import { LoadingButton } from '@mui/lab';

type Props = PopupController & {};

const PopupRegisterEmail = ({ onClose }: Props) => {
  const { email } = useSelector(profileSelector);
  const { control, handleSubmit } = useForm<RegisterEmailBody>();

  const { data } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { list: items } = data ?? {};

  const { mutate: registerEmail } = useMutation(registerService.registerEmail, {
    onSuccess: () => {
      enqueueSnackbar('Đăng ký nhận thông báo khóa học thành công');
      queryClient.invalidateQueries(['classroomService.fetchClassroomsPublic']);
    },
  });

  const handleClickSubmit = (courseId: number) => {
    handleSubmit((values) => {
      registerEmail({ ...values, courseId: courseId });
    })();
  };

  return (
    <>
      <DialogTitle>Đăng ký nhận thông báo khóa học</DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name='email'
              defaultValue={email ?? ''}
              control={control}
              rules={{
                pattern: {
                  value: regexValidateEmail(),
                  message: 'Email không hợp lệ',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} required fullWidth label='Email' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
        </Grid>
        <div className='mt-6 flex flex-col space-y-6'>
          {items?.map((course) => (
            <CardCourse
              key={course.id}
              item={course}
              onClick={() => {
                handleClickSubmit(course.id);
              }}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Đóng
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupRegisterEmail;
