import { LoginScreen, RegisterScreen, ResetPasswordForm } from 'views/Auth';

const authRoute = {
  login: {
    path: '/login',
    url: '/auth/login',
    component: LoginScreen,
  },
  register: {
    path: '/register',
    url: '/auth/register',
    component: RegisterScreen,
  },
  resetPassword: {
    path: '/reset-password',
    url: '/auth/reset-password',
    component: ResetPasswordForm,
  },
};

export default authRoute;
