import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { QRCode } from 'components';

type Props = PopupController & {
  item: Register;
};

const PopupRegisterZalo = ({ item, onClose }: Props) => {
  return (
    <>
      <DialogTitle>Tham gia nhóm Zalo</DialogTitle>

      {item.currentClassroom && (
        <DialogContent className='flex flex-col items-center space-y-3'>
          <QRCode value={item.currentClassroom.zaloLink} className='h-[160px] w-[160px]' />
          <div>
            Lớp {item.currentClassroom.code} - {item.currentClassroom.name}
          </div>
        </DialogContent>
      )}
      {!item.currentClassroom && (
        <DialogContent className='flex flex-col items-center space-y-3'>
          <div>Bạn chưa chọn lớp để học nên chưa có thông tin link zalo của lớp</div>
        </DialogContent>
      )}

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Đóng
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupRegisterZalo;
