import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { homeworkService } from 'services';
import CardHomeworkAnswerLog from './CardHomeworkAnswerLog';
import PopupHomeworkInfo from './PopupHomeworkInfo';
import { useState } from 'react';
import { InfoTwoTone } from '@mui/icons-material';
import { Link } from 'react-router-dom';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

type Props = PopupController & {
  answer: HomeworkAnswer;
};

const PopupHomeworkAnswerLog = ({ answer, onClose }: Props) => {
  const [openViewInfo, setOpenViewInfo] = useState(false);
  const { data: caseStudy } = useQuery(
    ['homeworkService.getHomeworkAnswerCaseStudyInfo'],
    () => homeworkService.getHomeworkAnswerCaseStudyInfo({ id: answer.id }),
    { keepPreviousData: true },
  );
  const { data } = useQuery(
    ['homeworkService.fetchHomeworkAnswerLogs'],
    () => homeworkService.fetchHomeworkAnswerLogs({ id: answer.id }),
    { keepPreviousData: true },
  );
  const { list: items = [] } = data ?? {};

  return (
    <>
      <DialogTitle>Lịch sử bài tập về nhà</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h5' color='primary'>
              1. Thông tin học viên
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Họ tên' value={answer.user?.fullName} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Số điện thoại' value={answer.user?.phone} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Email' value={answer.user?.email} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Khóa học' value={answer.course?.name} />
          </Grid>
          <Grid item md={4} xs={12}>
            <GridInfo label='Lớp học' value={answer.classroom?.name} />
          </Grid>
          <Grid item md={4} xs={12}>
            <div>
              <span>Hình thức: </span>
              <Chip
                label={answer.course?.method}
                color={(answer.course?.method ?? '').toUpperCase() === 'ONLINE' ? 'primary' : 'secondary'}
              />
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <div>
              <span>Trạng thái: </span>
              {answer.status === 'EDITING' && <Chip label='Chờ sửa bài' color='info' />}
              {answer.status === 'FINISHED' && <Chip label='Hoàn thành' color='success' />}
              {answer.status === 'REVIEWING' && <Chip label='Chờ review lần 1' color='error' />}
              {answer.status === 'REVIEWING_AGAIN' && <Chip label='Chờ review lại' color='primary' />}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className='mt-2'>
          <Grid item xs={12}>
            <Typography variant='h5' color='primary'>
              2. Nội dung BTVN
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' alignItems='center'>
              <GridInfo label='Tên BTVN' value={answer.homework?.name} />
              <IconButton
                color='info'
                title='Xem thông tin bài tập về nhà'
                className='mb-1'
                onClick={() => {
                  setOpenViewInfo(true);
                }}
              >
                <InfoTwoTone />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div>
              <span>Link bài tập: </span>
              <Link
                to={answer.homework?.template}
                target='_blank'
                className='text-primary-main hover:text-primary-dark'
              >
                {answer.homework?.template}
              </Link>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className='mt-2'>
          <Grid item xs={12}>
            <Typography variant='h5' color='primary'>
              3. Bài làm học viên
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <GridInfo label='Tên case study' value={caseStudy?.name} />
          </Grid>
          <Grid item xs={12}>
            <div>
              <span>Link bài làm: </span>
              <Link to={answer.link} target='_blank' className='text-primary-main hover:text-primary-dark'>
                {answer.link}
              </Link>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className='mt-2'>
          <Grid item xs={12}>
            <Typography variant='h5' color='primary'>
              4. Lịch sử tác động
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className='flex flex-col space-y-6'>
              {items?.map((log) => (
                <CardHomeworkAnswerLog key={log.id} item={log} />
              ))}
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
      </DialogActions>
      <Dialog maxWidth='lg' open={openViewInfo}>
        <PopupHomeworkInfo homework={answer.homework} onClose={() => setOpenViewInfo(false)} />
      </Dialog>
    </>
  );
};

export default PopupHomeworkAnswerLog;
