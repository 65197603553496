import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { ListItemButton } from '@mui/material';
import { publicRoute } from 'routes';

interface Topic {
  id: number;
  title: string;
}

interface Post {
  id?: number;
  postContent?: string;
  createdAt?: ISODateString;
  shortDescription?: string;
  avatarUrl?: string;
  title?: string;
  author?: string;
  sharingTypeId?: number;
  topicId?: number;
  totalView?: number;
}

interface SidebarProps {
  topics: Topic[];
  posts: Post[];
  currentTopicId?: number;
}

const Sidebar: React.FC<SidebarProps> = ({ topics, posts, currentTopicId }) => {
  return (
    <div style={{ width: '300px', padding: '10px' }}>
      <Typography variant='h6' gutterBottom style={{ color: 'black', fontSize: '1.5rem', fontWeight: 'bold' }}>
        Danh sách bài viết
      </Typography>
      <Divider />
      <List>
        {topics
          .filter((topic) => !currentTopicId || topic.id === currentTopicId)
          .map((topic) => (
            <React.Fragment key={topic.id}>
              <ListItem>
                <ListItemText
                  primary={topic.title}
                  primaryTypographyProps={{
                    style: {
                      fontSize: '1.25rem',
                      fontWeight: 'bold',
                    },
                  }}
                />
              </ListItem>
              {posts
                .filter((post) => post.topicId === topic.id)
                .map((post: any) => (
                  <ListItemButton component={Link} to={publicRoute.postPublicView.url(post)} key={post.id}>
                    <ListItemText secondary={post.title} />
                  </ListItemButton>
                ))}
              <Divider />
            </React.Fragment>
          ))}
      </List>
    </div>
  );
};

export default Sidebar;
