import { Container, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CardHomeworkClassroom } from './components';
import { homeworkService } from 'services';

const StudentClassroomList = () => {
  const { data, isFetching } = useQuery(
    ['homeworkService.fetchHomeworkClassrooms'],
    () => homeworkService.fetchHomeworkClassrooms(),
    { keepPreviousData: true },
  );
  const { list: items = [] } = data ?? {};

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách lớp học của bạn
      </Typography>

      <div className='flex flex-col space-y-6'>
        {items?.map((classroom) => (
          <CardHomeworkClassroom key={classroom.id} item={classroom} />
        ))}
      </div>
      {!isFetching && items.length === 0 && (
        <div className='text-center font-bold'>HIỆN TẠI CHƯA CÓ THÔNG TIN BÀI TẬP VỀ NHÀ</div>
      )}
    </Container>
  );
};

export default StudentClassroomList;
