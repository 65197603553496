import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { paperService } from 'services';
import { LoadingButton } from '@mui/lab';
import ViewEditorContent from './ViewEditorContent';
import { useEffect, useState } from 'react';
import { MyEditor } from 'views/Post/components';

type Props = {
  paperId: number;
  paperResultId: number;
  questionIndex: number;
  question: TestingQuestion;
  testing: boolean;
  isLast: boolean;
  onSave: (step: number, fill?: boolean) => void;
};

const TestingQuestionView = ({ paperId, paperResultId, questionIndex, question, testing, isLast, onSave }: Props) => {
  const [answerId, setAnswerId] = useState<number | null>();
  const [answers, setAnswers] = useState<TestQuestionAnswerUpsertBody[]>([]);
  const [answerContent, setAnswerContent] = useState('');

  useEffect(() => {
    setAnswers(
      (question.answers ?? []).map(({ id, content, isSelected }) => ({
        id,
        content,
        isSelected,
      })),
    );
    if (question.questionType === 'ESSAY') {
      setAnswerContent(question.answerContent ?? '');
    } else {
      setAnswerId(question.answerIds && question.answerIds.length > 0 ? question.answerIds[0] : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  useEffect(() => {
    onSave(0, !!answerContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerContent]);

  const { mutate: saveQuestionResult, variables: payload } = useMutation(paperService.postPaperResultAnswer, {
    onSuccess: () => {
      onSave(
        0,
        (payload?.answerIds ?? []).length > 0 || (question.questionType === 'ESSAY' && !!payload?.answerContent),
      );
    },
  });

  const handleChangeAnswerContent = () => {
    const payload = {
      answerContent: question.questionType === 'ESSAY' ? answerContent : null,
      answerIds:
        question.questionType === 'ESSAY'
          ? []
          : question.isMultipleChoice
          ? answers
              .filter((e) => e.isSelected)
              .map(({ id }) => {
                return id ?? 0;
              })
          : (answerId ?? 0) > 0
          ? [answerId ?? 0]
          : [],
      paperId: paperId,
      paperResultId: paperResultId,
      id: question.questionAnswerId,
      questionId: question.id,
    };
    saveQuestionResult(payload);
  };

  const handleClickMoveQuestion = (step: number) => {
    const answerIds =
      question.questionType === 'ESSAY'
        ? []
        : question.isMultipleChoice
        ? answers
            .filter((e) => e.isSelected)
            .map(({ id }) => {
              return id ?? 0;
            })
        : (answerId ?? 0) > 0
        ? [answerId ?? 0]
        : [];
    onSave(step, (answerIds ?? []).length > 0 || (question.questionType === 'ESSAY' && !!answerContent));
  };

  return (
    <>
      <Grid container spacing={3} className='mt-1'>
        {question.group && (
          <Grid item xs={12} className='space-y-1'>
            <Typography variant='h4'>
              Hãy đọc nội dung sau để trả lời câu hỏi {question.group.fromQuestion} đến câu hỏi{' '}
              {question.group.toQuestion}
            </Typography>
            <ViewEditorContent data={question.group.name} />
            <Divider light className='mb-2 mt-2' />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant='h4'>
            <span>Câu hỏi {questionIndex}</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ViewEditorContent data={question.content} />
        </Grid>
      </Grid>
      <Divider light className='mb-4 mt-4' />
      {question.questionType === 'CHOICE' && !question.isMultipleChoice && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup>
              {question.answers.map((answer, index) => (
                <Box border={0.5} borderColor='rgba(0, 0, 0, 0.1)' borderRadius={1} className='mt-2 p-2' key={index}>
                  <FormControlLabel
                    value={answer.id}
                    label={<ViewEditorContent data={answer.content} />}
                    control={
                      <Radio
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAnswerId(answer.id);
                            const payload = {
                              answerIds: [answer.id],
                              paperId: paperId,
                              paperResultId: paperResultId,
                              id: question.questionAnswerId,
                              questionId: question.id,
                            };
                            saveQuestionResult(payload);
                          }
                        }}
                        checked={answerId === answer.id}
                        color='primary'
                      />
                    }
                  />
                </Box>
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      )}
      {question.questionType === 'CHOICE' &&
        question.isMultipleChoice &&
        question.answers.map((answer, index) => (
          <Grid container key={index} spacing={3}>
            <Grid item xs={12}>
              <Box border={0.5} borderColor='rgba(0, 0, 0, 0.1)' borderRadius={1} className='mt-2 p-2'>
                <FormControlLabel
                  label={<ViewEditorContent data={answer.content} />}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        const updatedData = answers.map((x) => {
                          if (x.id === answer.id) {
                            return {
                              ...x,
                              isSelected: e.target.checked,
                            };
                          }
                          return x;
                        });
                        setAnswers(updatedData);
                        const payload = {
                          answerIds: updatedData
                            .filter((e) => e.isSelected)
                            .map(({ id }) => {
                              return id ?? 0;
                            }),
                          paperId: paperId,
                          paperResultId: paperResultId,
                          id: question.questionAnswerId,
                          questionId: question.id,
                        };
                        saveQuestionResult(payload);
                      }}
                      color='primary'
                      checked={!!answers.find((e) => e.id === answer.id && e.isSelected)}
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
        ))}
      {question.questionType === 'ESSAY' && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {question.hasSemicolon && (question.essayAnswerType === 'IN' || question.essayAnswerType === 'EQUAL') ? (
              <Typography variant='h6'>Nội dung trả lời (Vui lòng nhập dấu ; giữa các đáp án)</Typography>
            ) : (
              <Typography variant='h6'>Nội dung trả lời</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {question.essayAnswerType === 'IN' ||
            question.essayAnswerType === 'EQUAL' ||
            question.essayAnswerType === 'CONTAIN' ? (
              <TextField
                fullWidth
                value={answerContent}
                onChange={(event) => {
                  setAnswerContent(event.target.value);
                }}
                onBlur={handleChangeAnswerContent}
              />
            ) : (
              <MyEditor
                data={answerContent}
                onlyContent
                onBlur={handleChangeAnswerContent}
                onChange={setAnswerContent}
              />
            )}
          </Grid>
        </Grid>
      )}
      {testing && (
        <Grid item xs={12} className='mt-3'>
          <div className='flex justify-start space-x-2'>
            {questionIndex > 1 && (
              <LoadingButton
                color='info'
                variant='outlined'
                onClick={() => {
                  handleClickMoveQuestion(-1);
                }}
              >
                Quay lại
              </LoadingButton>
            )}
            {!isLast && (
              <LoadingButton
                variant='contained'
                onClick={() => {
                  handleClickMoveQuestion(1);
                }}
              >
                Tiếp tục
              </LoadingButton>
            )}
          </div>
        </Grid>
      )}
    </>
  );
};

export default TestingQuestionView;
