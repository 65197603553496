import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

type Props = {
  time: number;
  color?: string;
  onComplete: () => void;
};

const CountDownTimer = ({ time, color, onComplete }: Props) => {
  const [totalTime, setTotalTime] = useState(time);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    totalTime >= 0 &&
      setTimeout(() => {
        const h = Math.floor((totalTime % (60 * 60 * 24)) / (60 * 60));
        setHours(h);

        const m = Math.floor((totalTime % (60 * 60)) / 60);
        setMinutes(m);

        const s = Math.floor(totalTime % 60);
        setSeconds(s);
        setTotalTime(totalTime - 1);

        if (h <= 0 && m <= 0 && s <= 0) {
          onComplete();
        }
      }, 1000);
  }, [onComplete, totalTime]);

  return (
    <>
      {seconds < 60 && minutes === 0 && hours === 0 ? (
        <Typography variant='h3' color={color ?? 'error'}>
          {hours}:{minutes}:{seconds}
        </Typography>
      ) : (
        <Typography variant='h3' color={color ?? '#2e8b57'}>
          {hours}:{minutes}:{seconds}
        </Typography>
      )}
    </>
  );
};

export default CountDownTimer;
