import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { courseService, postService, queryClient } from 'services';
import { MyEditor } from '.';
import { PostStatusTypes } from 'utils/common';

export const ClassroomMethodTypes = [
  { value: 'BEFORE', label: 'Trước khóa học' },
  { value: 'OFFICIAL', label: 'Chính thức' },
];

type Props = PopupController & {
  dataTopic?: Topic[];
  dataSharingType?: SharingType[];
  post?: Post;
};

const PopupPostUpsert = ({ post, dataTopic, dataSharingType, onClose }: Props) => {
  const { control, handleSubmit } = useForm<PostCreateBody>();
  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { mutate: addPost, isLoading: isLoadingAdd } = useMutation(postService.addPost, {
    onSuccess: () => {
      enqueueSnackbar('Thêm mới lớp học thành công');
      queryClient.invalidateQueries(['classroomService.fetchClassrooms']);
      onClose();
    },
  });

  const { mutate: updatePost, isLoading: isLoadingUpdate } = useMutation(postService.updatePost, {
    onSuccess: () => {
      enqueueSnackbar('Câp nhật lớp học thành công');
      queryClient.invalidateQueries(['classroomService.fetchClassrooms']);
      onClose();
    },
  });

  const handleClickSubmitAdd = () => {
    handleSubmit((values) => {
      addPost({
        ...values,
      });
    })();
  };

  const handleClickSubmitUpdate = () => {
    handleSubmit((values) => {
      updatePost({
        ...values,
        id: post!.id,
      });
    })();
  };

  return (
    <>
      {!post && <DialogTitle>Thêm mới bài viết</DialogTitle>}
      {post && <DialogTitle>Cập nhật bài viết</DialogTitle>}

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Controller
              name='SharingTypeId'
              defaultValue={post?.sharingTypeId}
              control={control}
              rules={{
                required: 'Loại chia sẻ là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect
                  {...field}
                  required
                  fullWidth
                  label='Loại chia sẻ'
                  error={!!error}
                  helperText={error?.message}
                >
                  {(dataSharingType ?? []).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.type}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='TopicId'
              defaultValue={post?.topicId}
              control={control}
              rules={{
                required: 'Chủ đề là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect
                  {...field}
                  required
                  fullWidth
                  label='Chọn chủ đề'
                  error={!!error}
                  helperText={error?.message}
                >
                  {(dataTopic ?? []).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='Status'
              defaultValue={post?.status}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect {...field} fullWidth label='Trạng thái' error={!!error} helperText={error?.message}>
                  {PostStatusTypes.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='Author'
              defaultValue={post?.author}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Tác giả' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name='Title'
              defaultValue={post?.title}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Tên bài viết' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='ShortDescription'
              defaultValue={post?.shortDescription}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Mô tả ngắn' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='PostContent'
              defaultValue={post?.postContent}
              control={control}
              render={({ field, fieldState: { error } }) => <MyEditor data={field.value} onChange={field.onChange} />}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        {post && (
          <LoadingButton variant='contained' loading={isLoadingUpdate} onClick={handleClickSubmitUpdate}>
            Cập nhật
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default PopupPostUpsert;
