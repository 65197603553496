import { Clear } from '@mui/icons-material';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { classroomService, courseService, homeworkService } from 'services';
import { HomeworkAnswerStatusTypes } from 'utils/common';

type Props = {
  onChange: (...args: any) => void;
};

const HomeworkSearch = ({ onChange }: Props) => {
  const { control, handleSubmit, watch, reset } = useForm<HomeworkAnswerSearchParams>();

  const courseId = watch('courseId');
  const classroomId = watch('classroomId');
  const { data: dataCourse } = useQuery(['courseService.fetchCourses'], () => courseService.fetchCourses());
  const { data: dataClassroom } = useQuery(['classroomService.fetchClassrooms', { courseId: courseId }], () =>
    classroomService.fetchClassrooms({ courseId: courseId, size: -1 }),
  );
  const { data: dataHomework } = useQuery(
    ['homeworkService.fetchHomeworks', { courseId: courseId, classroomId: classroomId }],
    () => homeworkService.fetchHomeworks({ courseId: courseId, classroomId: classroomId }),
  );

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      courseId: '',
      classroomId: '',
      homeworkId: '',
      textSearch: '',
      status: '',
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <Controller
            name='courseId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Khóa học' error={!!error} helperText={error?.message}>
                {(dataCourse?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='classroomId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Lớp học' error={!!error} helperText={error?.message}>
                {(dataClassroom?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='homeworkId'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='BTVN' error={!!error} helperText={error?.message}>
                {(dataHomework?.list ?? []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='status'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldSelect {...field} fullWidth label='Trạng thái bài' error={!!error} helperText={error?.message}>
                {HomeworkAnswerStatusTypes.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextFieldSelect>
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            name='textSearch'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label='Họ tên/Email/Số điện thoại học viên'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
      <div className='mt-6 flex justify-end space-x-3'>
        <Button onClick={handleClickSearch}>Tìm kiếm</Button>
        <Button variant='outlined' color='inherit' onClick={handleClickClear}>
          <Clear />
        </Button>
      </div>
    </>
  );
};

export default HomeworkSearch;
