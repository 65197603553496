import { ClassroomList, ClassroomView } from 'views/Classroom';
import { RegisterList } from 'views/Register';
import { SurveyList } from 'views/Survey';
import { Mail } from 'views/Mail';
import { AdminList, PostView, PostAdd, PostEdit } from 'views/Post';
import { EventList, EventView } from 'views/Quiz';
import { UserList } from 'views/User';
import CaseStudyList from 'views/CaseStudy/CaseStudyList';
import { HomeworkList } from 'views/Homework';
import { PaperList, PaperResultList, PaperResultView, PaperStatisticView, PaperView, QuestionList } from 'views/Test';

const adminRoute = {
  registerList: {
    path: '/admin/registers',
    name: 'Danh sách đăng ký',
    component: RegisterList,
  },
  classroomList: {
    path: '/admin/classrooms',
    name: 'Quản lý lớp học',
    component: ClassroomList,
  },
  PostList: {
    path: '/admin/posts',
    name: 'Quản lý bài viết',
    component: AdminList,
  },
  PostAdd: {
    path: '/admin/posts-add',
    name: 'Thêm mới bài viết',
    component: PostAdd,
  },
  PostView: {
    path: '/admin/posts/:uuid',
    url: ({ uuid }: ByUUID) => `/admin/posts/${uuid}`,
    name: '',
    component: PostView,
  },
  PostEdit: {
    path: '/admin/posts-edit/:uuid',
    url: ({ uuid }: ByUUID) => `/admin/posts-edit/${uuid}`,
    name: 'Chỉnh sửa bài viết',
    component: PostEdit,
  },
  surveyList: {
    path: '/admin/surveys',
    name: 'Danh sách đánh giá',
    component: SurveyList,
  },
  mail: {
    path: '/admin/mails',
    name: 'Gửi email',
    component: Mail,
  },
  classroomView: {
    path: '/admin/classrooms/:id',
    url: ({ id }: ByID) => `/admin/classrooms/${id}`,
    name: '',
    component: ClassroomView,
  },
  eventList: {
    path: '/admin/events',
    name: 'Quản lý sự kiện',
    component: EventList,
  },
  eventview: {
    path: '/admin/events/:id',
    url: ({ id }: ByID) => `/admin/events/${id}`,
    name: '',
    component: EventView,
  },
  userList: {
    path: '/admin/users',
    name: 'Quản lý người dùng',
    component: UserList,
  },
  caseStudyList: {
    path: '/admin/case-studies',
    name: 'Quản lý case study',
    component: CaseStudyList,
  },
  homeworkList: {
    path: '/admin/homework',
    name: 'Quản lý bài tập',
    component: HomeworkList,
  },
  paperList: {
    path: '/admin/papers',
    name: 'Quản lý bài test',
    component: PaperList,
  },
  testQuestionList: {
    path: '/admin/test-questions',
    name: 'Quản lý câu hỏi',
    component: QuestionList,
  },
  paperResultList: {
    path: '/admin/paper-results',
    name: 'Quản lý kết quả',
    component: PaperResultList,
  },
  paperView: {
    path: '/admin/papers/:id',
    url: ({ id }: ByID) => `/admin/papers/${id}`,
    name: '',
    component: PaperView,
  },
  paperStatisticView: {
    path: '/admin/paper-statistic/:id',
    url: ({ id }: ByID) => `/admin/paper-statistic/${id}`,
    name: '',
    component: PaperStatisticView,
  },
  paperResultView: {
    path: '/admin/paper-results/:id/:questionIndex',
    url: ({ id, questionIndex }: ByID & { questionIndex?: number }) =>
      `/admin/paper-results/${id}/${questionIndex ?? 0}`,
    name: '',
    component: PaperResultView,
  },
};

export default adminRoute;
