import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import { categoryService, queryClient, testQuestionService } from 'services';
import { MyEditor } from 'views/Post/components';

type Props = PopupController & {
  group?: TestQuestionGroup;
  onAdd?: (group?: TestQuestionGroup, parentTopicId?: number, topicId?: number) => void;
};

const PopupQuestionGroupUpsert = ({ group, onAdd, onClose }: Props) => {
  const { control, handleSubmit, watch } = useForm<TestQuestionGroupCreateBody>();
  const isGroup = watch('isGroup');
  const parentTopicId = watch('parentTopicId');
  const topicId = watch('topicId');
  const { data: dataParentTopic } = useQuery(['categoryService.getAllTestQuestionParentTopics'], () =>
    categoryService.getAllTestQuestionTopics({ parentId: '' }),
  );

  const { data: dataTopic } = useQuery(
    ['categoryService.getAllTestQuestionTopics', { parentId: parentTopicId }],
    () => categoryService.getAllTestQuestionTopics({ parentId: parentTopicId }),
    {
      enabled: !!parentTopicId,
    },
  );
  const { mutate: addQuestionGroup, isLoading: isLoadingAdd } = useMutation(testQuestionService.postTestQuestionGroup, {
    onSuccess: (data: TestQuestionGroup) => {
      onClose();
      if (onAdd) {
        onAdd!(data, parentTopicId ?? undefined, topicId ?? undefined);
      }
    },
  });

  const { mutate: updateQuestionGroup, isLoading: isLoadingUpdate } = useMutation(
    testQuestionService.putTestQuestionGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['testQuestionService.fetchTestQuestions']);
        onClose();
      },
    },
  );

  const handleClickSubmitAdd = () => {
    if (!isGroup) {
      onClose();
      if (onAdd) {
        onAdd!(undefined, parentTopicId ?? undefined, topicId ?? undefined);
      }
      return;
    }
    handleSubmit((values) => {
      addQuestionGroup({
        ...values,
      });
    })();
  };

  const handleClickSubmitUpdate = () => {
    handleSubmit((values) => {
      updateQuestionGroup({
        id: group!.id,
        ...values,
      });
    })();
  };

  return (
    <>
      {!group && <DialogTitle>Thêm mới câu hỏi</DialogTitle>}
      {group && <DialogTitle>Cập nhật nội dung nhóm câu hỏi</DialogTitle>}

      <DialogContent>
        <Container maxWidth='xl' className='mt-1 py-1'>
          <Grid container spacing={3}>
            {!group && (
              <Grid item xs={12}>
                <Controller
                  name='isGroup'
                  defaultValue={false}
                  control={control}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <FormControlLabel
                      key={1}
                      label='Câu hỏi nhóm'
                      control={<Checkbox {...field} checked={field.value ?? false} color='primary' />}
                    />
                  )}
                />
              </Grid>
            )}
            {!group && (
              <Grid item md={6} xs={12}>
                <Controller
                  name='parentTopicId'
                  defaultValue={0}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldSelect
                      {...field}
                      fullWidth
                      label='Chủ đề cha'
                      error={!!error}
                      helperText={error?.message}
                    >
                      {(dataParentTopic?.list ?? []).map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextFieldSelect>
                  )}
                />
              </Grid>
            )}
            {!group && (
              <Grid item md={6} xs={12}>
                <Controller
                  name='topicId'
                  defaultValue={0}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldSelect
                      {...field}
                      fullWidth
                      label='Chủ đề con'
                      error={!!error}
                      helperText={error?.message}
                    >
                      {(dataTopic?.list ?? []).map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextFieldSelect>
                  )}
                />
              </Grid>
            )}
            {(group || isGroup) && (
              <Grid item xs={12}>
                <Typography variant='h6' color='info' className='mb-2 space-x-3'>
                  <span>Ngữ cảnh</span>
                </Typography>
                <Controller
                  name='name'
                  defaultValue={group?.name}
                  control={control}
                  rules={{
                    required: 'Nội dung nhóm câu hỏi là bắt buộc',
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <MyEditor onlyContent={true} data={field.value ?? ''} onChange={field.onChange} />
                  )}
                />
              </Grid>
            )}
            {!group && (
              <Grid item xs={12} className='text-center'>
                <LoadingButton variant='contained' loading={isLoadingAdd} onClick={handleClickSubmitAdd}>
                  Tiếp tục
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Container>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        {group && (
          <LoadingButton variant='contained' loading={isLoadingUpdate} onClick={handleClickSubmitUpdate}>
            Cập nhật
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default PopupQuestionGroupUpsert;
