import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { TextFieldNumber } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { paperService, queryClient } from 'services';

type Props = PopupController & {
  paperId: number;
  question: TestQuestion;
};

const PopupPaperQuestionPointUpdate = ({ paperId, question, onClose }: Props) => {
  const { control, handleSubmit, reset } = useForm<TestPaperQuestionPointUpdateBody>();

  useEffect(() => {
    reset({
      point: question?.point,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const { mutate: updatePaperQuestionPoint, isLoading } = useMutation(paperService.putPaperQuestionPoint, {
    onSuccess: () => {
      enqueueSnackbar('Câp nhật điểm cho câu hỏi thành công');
      queryClient.invalidateQueries(['paperService.fetchPaperQuestions']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updatePaperQuestionPoint({
        ...values,
        id: paperId,
        questionId: question.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Cập nhật điểm câu hỏi của bài test</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name='point'
              control={control}
              rules={{
                required: 'Điểm của câu hỏi là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldNumber
                  {...field}
                  fullWidth
                  required
                  label='Điểm câu hỏi'
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{ decimalScale: 2 }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupPaperQuestionPointUpdate;
