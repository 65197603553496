import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { queryClient, paperService } from 'services';

type Props = PopupController & {
  paperResult: TestPaperResult;
};

const PopupPaperResultApprove = ({ paperResult, onClose }: Props) => {
  const { mutate: approvePaperResult, isLoading } = useMutation(paperService.putPaperResultApproval, {
    onSuccess: () => {
      enqueueSnackbar('Phê duyệt điểm bài test thành công');
      queryClient.invalidateQueries(['paperService.fetchPaperResults']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    approvePaperResult({
      id: paperResult.id,
    });
  };
  return (
    <>
      <DialogTitle>Xác nhận phê duyệt điểm bài test</DialogTitle>

      <DialogContent>
        <div className='text-center'>Bạn có chắc chắn muốn phê duyệt điểm bài test này không?</div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupPaperResultApprove;
