import { Clear } from '@mui/icons-material';
import { Button, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

type Props = {
  onChange: (...args: any) => void;
};

const UserSearch = ({ onChange }: Props) => {
  const { control, handleSubmit, reset } = useForm<UserSearchParams>();
  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      textSearch: '',
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Controller
            name='textSearch'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label='Họ tên, số điện thoại, email'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <div className='flex justify-end space-x-3'>
            <Button onClick={handleClickSearch}>Tìm kiếm</Button>
            <Button variant='outlined' color='inherit' onClick={handleClickClear}>
              <Clear />
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default UserSearch;
